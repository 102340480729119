import {
  AdBoardAttachmentSectionProgressDto,
  AdBoardSurveySectionProgressDto,
  AdBoardVideoSectionProgressDto,
  SectionDto,
} from '@keyops-hcp/dtos';
import { t } from 'i18next';

import { axiosCall } from '../utils/api';
import { AD_BOARDS_ENDPOINT, SECTIONS_ENDPOINT } from '../utils/api-routes';

export const getAdBoardSections = async (id: string): Promise<SectionDto[]> => {
  try {
    const results = await axiosCall(
      false,
      'get',
      AD_BOARDS_ENDPOINT + `/${id}` + SECTIONS_ENDPOINT,
      undefined,
      undefined
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      // TODO: add error code for adboard sections
      results?.data?.message || t('apiErrorMessages.no_ad_board_sections_found')
    );
  } catch (error) {
    console.error('Error fetching data getOneAdBoard:', error);
    throw new Error(
      error?.response?.data?.message ||
        t('apiErrorMessages.no_ad_board_sections_found')
    );
  }
};

export const updateSectionProgress = async (
  sectionId: string,
  participantId: string,
  progressData?:
    | AdBoardVideoSectionProgressDto
    | AdBoardAttachmentSectionProgressDto
    | AdBoardSurveySectionProgressDto
): Promise<SectionDto[]> => {
  try {
    const results = await axiosCall(
      false,
      'put',
      `${SECTIONS_ENDPOINT}/${sectionId}/progress/${participantId}`,
      undefined,
      undefined,
      { progressData }
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(results?.data?.message);
  } catch (error) {
    console.error(
      'updateSectionProgress > Error updating section progress data: ',
      error
    );
    throw new Error(error?.response?.data?.message);
  }
};
