import React from 'react';
import { FiCheckCircle, FiCircle } from 'react-icons/fi';
import { useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { AdBoardSectionType } from '@keyops-hcp/constants';

import { KeyopsSubTitle2 } from '../Headers';
import { getCurrentSection } from '../../utils/adboard-sections.utils';
import { HCPAdBoardContextType } from '../../hooks';
import { useAdBoardContext } from '../../context';
import { SectionDto } from '@keyops-hcp/dtos';

interface ActivityListItemProps {
  text: string;
  sectionId: string;
  sectionType: AdBoardSectionType;
  finished: boolean;
  accessible: boolean;
  discussionId?: string; // Only needed for discussions section
  setCurrentSectionId: (id: string) => void;
  setDisplayedDiscussionId?: React.Dispatch<React.SetStateAction<string>>; //Only needed for discussions section
  setDiscussionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ActivityListItem = ({
  text,
  sectionId,
  sectionType,
  finished,
  setCurrentSectionId,
  accessible,
  discussionId,
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
}: ActivityListItemProps): JSX.Element => {
  const { palette } = useTheme();
  const { sectionId: urlSectionId } = useParams();

  let selected = false;
  if (urlSectionId && urlSectionId === sectionId) selected = true;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexGrow={1}
      gap={1}
      pl={5}
      pr={2}
      py={1}
      sx={{
        cursor: accessible ? 'pointer' : 'default',
        backgroundColor: selected ? palette.keyops.gray[50] : 'transparent',
        '&:hover': {
          backgroundColor: accessible
            ? palette.keyops.gray[100]
            : 'transparent',
        },
        opacity: accessible ? 1 : 0.5, // Grays out the entire Box
      }}
      onClick={() => {
        if (accessible) {
          setCurrentSectionId(sectionId);
        }
        // Handling of the discussion drawer
        // if DiscussionSections, selecting a discussion will open the drawer to the discussion's msgs
        // for all other types, the drawer is closed and the selected discussion reset to undefined
        if (
          accessible &&
          sectionType === 'discussion' &&
          setDiscussionDrawerOpen &&
          setDisplayedDiscussionId
        ) {
          setDiscussionDrawerOpen(true);
          setDisplayedDiscussionId(discussionId);
        } else {
          setDiscussionDrawerOpen(false);
          setDisplayedDiscussionId(undefined);
        }
      }}
    >
      {finished ? (
        <FiCheckCircle
          data-testid='check-circle-icon'
          fontSize={16}
          color={palette.keyops.teal}
          style={{ minWidth: 18 }}
        />
      ) : (
        <FiCircle
          data-testid='circle-icon'
          fontSize={14}
          style={{ minWidth: 18 }}
        />
      )}
      <KeyopsSubTitle2
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          color: accessible ? 'inherit' : palette.keyops.gray[500], // Grays out the text
        }}
      >
        {text}
      </KeyopsSubTitle2>
    </Box>
  );
};
