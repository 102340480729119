import React, { useCallback, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';
import { AdBoardAttachmentSectionProgressDto } from '@keyops-hcp/dtos';

import { AttachmentPreview } from '../AttachmentPreview';
import { Attachment } from '../Attachment';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { KeyopsSubTitle1 } from '../Headers';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context/AdBoardContext';
import { HCPAdBoardContextType } from '../../hooks';

export const AttachmentList = ({
  attachmentIds,
  setIsFullScreenData,
}: {
  attachmentIds: string[];
  setIsFullScreenData: (isFullScreen: boolean) => void;
}) => {
  const { currentSectionProgress, updateProgress = () => {} } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};
  const sectionProgress =
    currentSectionProgress as AdBoardAttachmentSectionProgressDto;

  const [showAttachmentId, setShowAttachmentId] = useState<string | undefined>(
    undefined
  );
  // Memoized handler for back button
  const handleBackClick = useCallback(() => {
    setIsFullScreenData(false);
    setShowAttachmentId(undefined);
  }, []);

  // Memoized handler for preview click
  const handlePreviewClick = useCallback(
    (attachmentId: string) => {
      const existingFinishedAttachments =
        sectionProgress?.finishedAttachments || [];

      // Check if the attachmentId already exists in finishedAttachments
      if (
        !existingFinishedAttachments.includes(attachmentId) &&
        updateProgress
      ) {
        updateProgress({
          finishedAttachments: [...existingFinishedAttachments, attachmentId],
        });
      }
      setIsFullScreenData(true);
      setShowAttachmentId(attachmentId);
    },
    [sectionProgress?.finishedAttachments, updateProgress]
  );

  if (showAttachmentId) {
    return (
      <>
        <Button
          aria-label='back_to_documents'
          startIcon={<FiChevronLeft />}
          onClick={handleBackClick}
          sx={{
            textTransform: 'none',
          }}
        >
          {i18next.t('attachment.back_to_documents')}
        </Button>
        <Attachment attachmentId={showAttachmentId} />
      </>
    );
  }
  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('attachment.infoText')}
      </AdBoardSectionTaskInfo>
      <KeyopsSubTitle1>
        {i18next.t('attachment.total_documents', {
          count: attachmentIds.length,
        })}
      </KeyopsSubTitle1>
      {attachmentIds.map((attachmentId) => {
        const isFinished =
          !!sectionProgress?.finishedAttachments?.includes(attachmentId);

        return (
          <AttachmentPreview
            key={`attachment-preview-${attachmentId}`}
            attachmentId={attachmentId}
            finished={isFinished}
            onPreviewClick={() => handlePreviewClick(attachmentId)}
          />
        );
      })}
    </Stack>
  );
};
