import ShareIcon from "@mui/icons-material/Share";

import ReferralsList from "./components/List";
import ReferralsCreate from "./components/Create";
import ReferralsEdit from "./components/Edit";
import ReferralShow from "./components/Show";

const resource = {
  list: ReferralsList,
  create: ReferralsCreate,
  edit: ReferralsEdit,
  show: ReferralShow,
  icon: ShareIcon,
};

export default resource;
