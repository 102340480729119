//import { AdBoardInviteDto } from "@keyops-hcp/dtos";
import { Box, Link, Stack, Typography } from '@mui/material';
import {
  ChipField,
  DateField,
  FunctionField,
  Loading,
  NumberField,
  TextField,
  useEditContext,
  WrapperField,
} from 'react-admin';
import React from 'react';
import { Datagrid, ListContextProvider, useList } from 'react-admin';
import { BulkSendInviteButton } from './participants/BulkSendInviteButton';
import { useInvitesByAdBoardId } from '../../../custom-hooks/useInvitesByAdBoardId';
import { useNavigate } from 'react-router-dom';
import { CreateAdBoardInviteDialog } from './participants/CreateAdBoardInviteDialog';
import { EditAdBoardInviteDialog } from './participants/EditAdBoardInviteDialog';
import { DeleteAdBoardInviteDialog } from './participants/DeleteAdBoardInviteDialog';
import { ContractBox } from './participants/ContractBox';
import { DownloadSignedContract } from './participants/DownloadSignedContract';
import { CopyPropertyButton } from '../../../components/CopyPropertyButton';
import { AdBoardInviteDto } from '@keyops-hcp/dtos';

export const ParticipantTab = () => {
  const navigate = useNavigate();
  const { record } = useEditContext();
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useInvitesByAdBoardId(record.id);
  data.sort((a, b) => a.invitee.firstName.localeCompare(b.invitee.firstName));
  const listContext = useList({ data });

  if (isLoading) return <Loading />;
  if (isError) navigate('/AdBoards');

  const bulkActions = (
    <>
      <BulkSendInviteButton />
    </>
  );

  const getUserName = (invite: AdBoardInviteDto) => {
    return `${invite.invitee.firstName} ${invite.invitee.lastName}`;
  };

  const getUserNameDisplay = (invite: AdBoardInviteDto) => {
    const userName = getUserName(invite);
    if (invite.type === 'in-network') {
      return (
        <Link href={`#/Users/${invite.invitee.userId}/Show`}>{userName}</Link>
      );
    }
    return userName;
  };

  return (
    <Stack spacing={2}>
      <ContractBox adBoardId={record.id} />
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <Box sx={{ p: 0 }}>
          <Typography fontWeight={600}>Participants</Typography>
          <Typography>
            Select one or more participants to send invitations to.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 1,
            m: 1,
          }}
        >
          <CreateAdBoardInviteDialog onSave={refetch} />
        </Box>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={bulkActions}>
            <WrapperField source='participant' sortBy='invitee.firstName'>
              <Stack>
                <Stack direction='row'>
                  <FunctionField
                    source='invitee'
                    render={(record) => {
                      return getUserNameDisplay(record);
                    }}
                  />
                </Stack>
                <Stack
                  direction='row'
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <TextField source='invitee.email' fontSize={12} />
                  <CopyPropertyButton property='invitee.email' iconSize={12} />
                </Stack>
              </Stack>
            </WrapperField>
            <NumberField
              options={{
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
              }}
              source='honorarium'
            />
            <ChipField
              source='state'
              label='Invite Status'
              textAlign='center'
            />
            <DateField
              source='sentAt'
              label='Date invited'
              sortable={false}
              options={{
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }}
            />
            <WrapperField label='Actions' sortable={false} textAlign='center'>
              <Stack direction='row' justifyContent='flex-end'>
                <CopyPropertyButton property='id' />
                <DownloadSignedContract />
                <EditAdBoardInviteDialog onSave={refetch} />
                <DeleteAdBoardInviteDialog onSave={refetch} />
              </Stack>
            </WrapperField>
          </Datagrid>
        </ListContextProvider>
      </Box>
    </Stack>
  );
};
