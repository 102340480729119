import { useMutation } from '@keyops-hcp/ui-components';
import { SectionDto, VideoAdBoardSectionDto, VideoDto } from '@keyops-hcp/dtos';
import { useUpdateSectionContent } from './useUpdateSectionContent';
import { NotificationType } from 'react-admin';
import { updateOneVideo } from '../api/videos';

export const useUpdateVideoSection = (
  section: VideoAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    }
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    VideoDto,
    unknown,
    { title?: string; description?: string }
  >(
    async ({ title, description }): Promise<VideoDto> => {
      // First update the Video
      setProcessing(true);

      const updatedVideo = await updateOneVideo(section.content.videoId, {
        title,
        description,
      });

      return updatedVideo;
    },
    {
      onSuccess: (updatedVideo) => {
        if (updatedVideo) {
          try {
            // Then update the section (and re-set the section currently under edition)
            updateSectionContent({
              sectionId: section.id,
              content: updatedVideo.id,
            });
            notify('Video updated successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to update the video', { type: 'error' });
      },
    }
  );
};
