import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Loading,
  ReferenceField,
  useList,
  WrapperField,
} from 'react-admin';
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChatBubbleOutline, PersonOutline } from '@mui/icons-material';

import useAdBoardEngagementData from './useAdBoardEngagementData';

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <>
      {/* TODO: Add tooltip here */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary' }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </>
  );
};

const ParticipantsList = () => {
  const navigate = useNavigate();
  const { isLoading, isError, participantsData, totalSections } =
    useAdBoardEngagementData();
  const listContext = useList({ data: participantsData });

  if (isLoading) return <Loading />;
  if (isError) navigate('/AdBoards');

  return (
    <>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <WrapperField label='Name'>
            <Stack>
              <ReferenceField source='userId' reference='Users'>
                <FunctionField
                  render={(record) => `${record.firstName} ${record.lastName}`}
                />
              </ReferenceField>
              <FunctionField
                render={(record) =>
                  `Last active: ${new Date(
                    record.progress.updatedAt
                  ).toLocaleDateString()}`
                }
              />
            </Stack>
          </WrapperField>
          <DateField source='progress.startedOn' label='Started on' />
          <FunctionField
            source='progress'
            label='Progress'
            render={(record) => (
              <LinearProgressWithLabel
                value={
                  (100 *
                    record?.progress?.progressDetails?.sectionProgress
                      ?.length || 0) / totalSections
                }
                sx={{ height: 10, borderRadius: 5 }}
              />
            )}
          />
          <DateField source='progress.finishedOn' label='Finished on' />
          <WrapperField label='Actions'>
            <Stack direction='row'>
              <Tooltip title='Messages' placement='top' arrow>
                {/* TODO: add what to do here */}
                <IconButton onClick={() => null}>
                  <ChatBubbleOutline />
                </IconButton>
              </Tooltip>
              <Tooltip title='View engagement' placement='top' arrow>
                {/* TODO: add what to do here */}
                <IconButton onClick={() => null}>
                  <PersonOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          </WrapperField>
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

export default ParticipantsList;
