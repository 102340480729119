import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const Loader = ({
  align,
  size,
  pt,
}: {
  align?: string;
  size?: string;
  pt?: number;
}) => {
  return (
    <Box
      display='flex'
      alignItems={align ?? 'center'}
      justifyContent='center'
      sx={{ pt: pt ?? 20 }}
    >
      <CircularProgress aria-label='progress' size={size ?? '10rem'} />
    </Box>
  );
};
