import React, { useState, useCallback } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from '../../../../languages/i18n.config';
import { useSections } from '../../../../hooks/useSections';
import { useStyles } from './styles';

interface AdBoardAgendaProps {
  adBoardId: string;
}

export const AdBoardAgenda: React.FC<AdBoardAgendaProps> = ({ adBoardId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [openDescriptions, setOpenDescriptions] = useState(new Set<string>());
  const {
    data: sections,
    isLoading: loading,
    isError: error,
  } = useSections(adBoardId);

  // Toggle function for descriptions
  const toggleDescriptions = useCallback((sectionId: string) => {
    setOpenDescriptions((prevOpenDescriptions) => {
      const newOpenDescriptions = new Set(prevOpenDescriptions);
      if (newOpenDescriptions.has(sectionId)) {
        newOpenDescriptions.delete(sectionId);
      } else {
        newOpenDescriptions.add(sectionId);
      }
      return newOpenDescriptions;
    });
  }, []);

  // ToggleIcon used for showing description
  const ToggleIcon = useCallback(
    ({ id }: { id: string }) => (
      <ExpandMoreIcon
        sx={{
          ml: 1,
          color: theme.palette.keyops.blue.dark,
          transform: openDescriptions.has(id)
            ? 'rotate(180deg)'
            : 'rotate(0deg)',
          transition: 'transform 0.3s ease',
        }}
      />
    ),
    [openDescriptions, theme.palette.keyops.blue.dark]
  );

  // SubContent display
  const SubContent = useCallback(
    ({
      id,
      title,
      description,
    }: {
      id: string;
      title: string;
      description: string;
    }) => (
      <Box sx={{ mb: 1 }}>
        <Box sx={styles.clickableBox} onClick={() => toggleDescriptions(id)}>
          <Typography
            sx={{
              ...styles.title,
              fontWeight: 400,
            }}
          >
            {title}
          </Typography>
          <ToggleIcon id={id} />
        </Box>
        {openDescriptions.has(id) && (
          <Box sx={{ mt: 0.5 }}>
            <Typography sx={styles.description}>{description}</Typography>
          </Box>
        )}
      </Box>
    ),
    [
      openDescriptions,
      toggleDescriptions,
      styles.clickableBox,
      styles.title,
      styles.description,
    ]
  );

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error || !sections) return <div>{i18next.t('common.error')}</div>;
  const totalSections = sections.length;
  const totalActivities = sections.reduce((acc, section) => {
    // TODO: parsing through array of content
    // Note: We treat attachments as single activity
    // if (section.type === "attachment") {
    //   return acc + section.content.length;
    // }
    return acc + 1;
  }, 0);

  return (
    <div>
      <Typography
        sx={{
          ...styles.description,
          mb: 1,
        }}
      >
        {totalSections} {totalSections !== 1 ? 'sections' : 'section'} ●{' '}
        {totalActivities} {totalActivities !== 1 ? 'activities' : 'activity'}
      </Typography>
      <Box sx={styles.agendaBox}>
        {sections.map((section) => (
          <div key={section.id}>
            <Box sx={styles.columnStyle}>
              <Box sx={styles.rowStyle}>
                <Box
                  sx={styles.clickableBox}
                  onClick={() => toggleDescriptions(section.id)}
                >
                  <Typography sx={styles.title}>{section.title}</Typography>
                  <ToggleIcon id={section.id} />
                </Box>
                <Typography sx={styles.description}>
                  {/* TODO: parsing through array of content */}
                  {/* Note: We treat attachments as single activity */}
                  {/* {section.type === "attachment" && section.content.length > 1
                      ? String(section.content.length) + " activities"
                      : "1 activity"} */}
                  1 activity
                </Typography>
              </Box>
              {openDescriptions.has(section.id) && (
                <Box sx={{ mt: 1 }}>
                  <Typography sx={styles.description}>
                    {section.description}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ ml: 5, mt: 1, mr: 1 }}>
              {section.type === 'attachment' ||
              section.type === 'survey' ||
              section.type === 'discussion' ? (
                // TODO: parsing through array of content
                // Note: We treat attachments as single activity
                // section.content.map((item, idx) => (
                //   <SubContent
                //     id={item.attachment.id}
                //     title={item.attachment.title}
                //     description={item.attachment.description}
                //   />
                // ))
                <></>
              ) : (
                <SubContent
                  id={section.content.video.id}
                  title={section.content.video.title}
                  description={section.content.video.description}
                />
              )}
            </Box>
          </div>
        ))}
      </Box>
    </div>
  );
};
