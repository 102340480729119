import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  box: {
    ml: 2,
    p: 3,
    minWidth: '30%',
    borderRadius: 2,
    gap: 2,
    alignItems: 'center',
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)`,
    marginBottom: 'auto',
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '32px',
  },
});
