import React, { useEffect, useCallback, useRef, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

import { useVideo } from '../../hooks/useVideo';
import i18next from '../../languages/i18n.config';
import { KeyopsHeader2, KeyopsSubTitle1 } from '../Headers';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';

export const Video = forwardRef<
  ReactPlayer,
  {
    videoId: string;
  }
>(({ videoId }, playerRef) => {
  const { video, loading, error } = useVideo(videoId);

  const playerContainerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const videoTime = parseInt(searchParams.get('videoTime'));

  const context = useAdBoardContext() as HCPAdBoardContextType;
  // If the user is admin, then updateProgress is undefined
  const { updateProgress = () => {} } = context || {};

  useEffect(() => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (videoTime !== undefined && videoTime !== null) {
        player.seekTo(videoTime, 'seconds');
      }
    }
  }, [videoTime, playerRef]);

  const onReady = useCallback(() => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (videoTime) {
        player.seekTo(videoTime, 'seconds');
      }
    }
  }, [videoTime, playerRef]);

  const onPause = useCallback(() => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (updateProgress) {
        updateProgress({ currentTime: player.getCurrentTime() });
      }
    }
  }, [playerRef, updateProgress]);

  const onEnded = useCallback(() => {
    if (playerRef && 'current' in playerRef && playerRef.current) {
      const player = playerRef.current as ReactPlayer;
      if (updateProgress) {
        updateProgress({ currentTime: player.getCurrentTime() });
      }
    }
  }, [playerRef, updateProgress]);

  if (loading) return <div>{i18next.t('common.loading')}</div>;
  if (error) return <div>{i18next.t('common.error')}</div>;

  return (
    <Box>
      <KeyopsHeader2 fontSize={'18px'}>{video?.title}</KeyopsHeader2>
      <KeyopsSubTitle1>{video?.description}</KeyopsSubTitle1>
      <Box
        ref={playerContainerRef}
        display={'block'}
        width={'fit-content'}
        margin={'auto'}
        mt={2}
      >
        <ReactPlayer
          url={video?.link}
          ref={playerRef}
          controls
          onStart={() => console.log('starting video')}
          onPause={onPause}
          onReady={onReady}
          onEnded={onEnded}
        />
      </Box>
    </Box>
  );
});

Video.displayName = 'Video';
