import React from 'react';
import { Typography, useTheme } from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import i18next from '../../../../languages/i18n.config';
import { CallToActionBox } from '../CallToActionBox';

export const ContractPending = () => {
  const { t } = i18next;
  const theme = useTheme();
  return (
    <CallToActionBox
      icon={
        <DriveFileRenameOutlineOutlinedIcon
          sx={{ color: theme.palette.keyops.teal, fontSize: '61px' }}
        />
      }
      title={t('ad_board.landing_page.cta.contract_pending.title')}
    >
      <Typography>
        {t('ad_board.landing_page.cta.contract_pending.copy')}
      </Typography>
    </CallToActionBox>
  );
};
