import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';

import { DiscussionMessageDto } from '@keyops-hcp/dtos';

import i18next from '../../languages/i18n.config';
import { getMinutesAgoFromString } from '../../utils/datetime';

interface DiscussionMessageProps {
  message: DiscussionMessageDto;
}

export const DiscussionMessage = ({
  message,
}: DiscussionMessageProps): JSX.Element => {
  return (
    <Box pb={`8px`}>
      {/* HCP avatar */}
      <Stack pb={`8px`} direction={'row'} spacing={1}>
        <Avatar
          sx={{
            backgroundColor: 'keyops.gray.400',
            color: 'white',
            width: '40px',
            height: '40px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          {message.user.firstName.length > 0
            ? message.user.firstName[0]
            : i18next.t('common.na')}
          {message.user.lastName.length > 0
            ? message.user.lastName[0]
            : i18next.t('common.na')}
        </Avatar>
        {/* TODO: generate a profil pic with initials */}
        <Box>
          <Stack pb={`8px`} direction={'row'} spacing={2}>
            {/* TODO: format according hcpType */}
            <Typography fontWeight={500} fontSize={`16px`} lineHeight={`24px`}>
              {message.user.firstName} {message.user.lastName}
            </Typography>
            {/* TODO: format dates */}
            <Typography
              fontWeight={400}
              fontSize={`14px`}
              lineHeight={`24px`}
              color={'keyops.gray.600'}
            >
              {getMinutesAgoFromString(message.createdAt.toString())}
            </Typography>
          </Stack>
          <Box>
            <Typography>{message.content}</Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
