// External Dependencies
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
} from "react-admin";

const validateName = [required(), minLength(2), maxLength(15)];

const CompanyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} fullWidth />
    </SimpleForm>
  </Create>
);

export default CompanyCreate;
