import React from "react";
import { useParams } from "react-router-dom";
import { Attachment } from "@keyops-hcp/ui-components";

// TODO: rm as it is not used

export const AttachmentView = () => {
  const { id } = useParams();
  if (!id) {
    window.location.href = "/adboards/attachments";
    return;
  }
  return <Attachment attachmentId={id} />;
};
