import { UserDisplayDto } from "@keyops-hcp/dtos/dist/dtos/videoComment.dto";

export const getUserDisplayName = (user: UserDisplayDto): string => {
  if (!user) {
    return "";
  }
  const firstName = user.firstName ?? "";
  const lastName = user.lastName ?? "";
  if (user.hcpType === "physician") {
    return "Dr. " + firstName + " " + lastName;
  }
  return firstName + " " + lastName;
};
