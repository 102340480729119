// External Dependencies
import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  ChipField,
  DateField,
} from "react-admin";

// Internal Dependencies
import CustomBulkDeleteButton from "../../../components/CustomBulkDeleteButton";
import { HcpTypeChoices } from "../../../utils/choices";

const EngagementFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput perPage={1000} source="companyId" reference="Company">
        <SelectInput label="name" source="name" optionText="name" />
      </ReferenceInput>
      <SelectInput source="hcpType" choices={HcpTypeChoices} fullWidth />
    </Filter>
  );
};

const EngagementBulkActionButtons = (props) => (
  <React.Fragment>
    <CustomBulkDeleteButton {...props} />
  </React.Fragment>
);

const EngagementList = (props) => (
  <List
    exporter={false}
    filters={<EngagementFilter />}
    perPage={25}
    sort={{ field: "updatedAt", order: "DESC" }}
    {...props}
  >
    <Datagrid
      rowClick="show"
      bulkActionButtons={<EngagementBulkActionButtons />}
    >
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField label="Company" source="companyId" reference="Company">
        <TextField source="name" />
      </ReferenceField>
      <ChipField source="state" />
      <TextField source="hcpType" />
      <TextField source="payoutValue" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      {/* <TextField source="matchesCount" /> */}
      <EditButton />
    </Datagrid>
  </List>
);

export default EngagementList;
