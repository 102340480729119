import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
// import { clearCredentials } from "./lib";

let apiUri =
  process.env.REACT_APP_GRAPHQL_URL ?? "http://localhost:9000/graphql";

const httpLink = createHttpLink({
  uri: apiUri,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const operationNameLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName || "unknown";
  operation.setContext(({ headers = {} }) => ({
    headers,
    uri: `${apiUri}?${operationName}`,
  }));
  return forward(operation);
});

const errorLink = onError();
// TODO, bring this back
// ({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let err of graphQLErrors) {
//       switch (err.extensions.code) {
//         case "FORBIDDEN":
//           clearCredentials(true);
//           break;
//         default:
//           break;
//       }
//     }
//   }
//   if (networkError) {
//     if (networkError.status === 401) {
//       clearCredentials(true);
//     }
//   }
// }

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, operationNameLink, errorLink, httpLink]),
  cache: new InMemoryCache({}),
});

export default apolloClient;
