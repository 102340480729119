import React from 'react';
import {
  Card,
  CardActionArea,
  Container,
  CardHeader,
  Icon,
  useTheme,
} from '@mui/material';
import { FiCheck, FiChevronRight } from 'react-icons/fi';

import { useAttachment } from '../../hooks/useAttachment';

export const AttachmentPreview = ({
  attachmentId,
  finished,
  onPreviewClick,
}: {
  attachmentId: string;
  finished: boolean;
  onPreviewClick: () => void;
}) => {
  const { palette } = useTheme();
  const { attachment, loading, error } = useAttachment(attachmentId);

  if (loading) {
    return <Container>Loading...</Container>;
  }
  if (error) {
    return <Container>{error}</Container>;
  }

  return (
    <Card variant={'outlined'}>
      <CardActionArea
        data-testid='attachment-preview-action'
        onClick={() => onPreviewClick()}
      >
        <CardHeader
          avatar={
            finished && (
              <FiCheck
                data-testid='check-icon'
                fontSize={24}
                color={palette.keyops.teal}
              />
            )
          }
          action={
            <Icon aria-label='ChevronRight'>
              <FiChevronRight
                data-testid='chevron-right-icon'
                color={palette.keyops.blue.dark}
              />
            </Icon>
          }
          title={attachment?.title}
          subheader={attachment?.description}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheaderTypographyProps={{ variant: 'subtitle2', fontWeight: 400 }}
          sx={{ '.MuiCardHeader-action': { alignSelf: 'center' } }}
        />
      </CardActionArea>
    </Card>
  );
};
