// External Dependencies
import React from "react";

import {
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
  Link,
  Edit,
  SimpleForm,
  Labeled,
  SelectInput,
} from "react-admin";
import { InvitationStateChoices } from "../../../utils/choices";

const InvitationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
      <SelectInput source="state" choices={InvitationStateChoices} required />
      <Labeled label="User">
        <ReferenceField source="userId" reference="Users">
          <TextField source="firstName" />
          {"  "}
          <TextField source="lastName" />
          {"  "}
          <TextField source="hcpType" />
        </ReferenceField>
      </Labeled>

      <Labeled label="Company">
        <ReferenceField
          source="engagementId"
          reference="Engagement"
          link={false}
        >
          <FunctionField
            label="Company"
            render={(record) => {
              const companyId = record.companyId;
              return (
                <Link to={`/Company/${companyId}/show`}>
                  <ReferenceField
                    source="companyId"
                    reference="Company"
                    record={record}
                    basePath="/Company"
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Link>
              );
            }}
          />
        </ReferenceField>
      </Labeled>
      <Labeled label="Engagement">
        <ReferenceField source="engagementId" reference="Engagement">
          <TextField source="title" />
        </ReferenceField>
      </Labeled>
      <Labeled label="Admin">
        <ReferenceField source="createdBy" reference="Admins">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled label="Completed At">
        <DateField label="Completed At" source="completedAt" />
      </Labeled>
    </SimpleForm>
  </Edit>
);

export default InvitationEdit;
