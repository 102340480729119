import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Edit,
  EditProps,
  FormTab,
  TabbedForm,
  useRecordContext,
} from "react-admin";

import DetailsEditTab from "./DetailsEditTab";
import ContentEditTab from "./ContentEditTab";
import { ParticipantTab } from "./ParticipantTab";
import EngagementTab from "./EngagementTab";

const AdBoardEditTitle = () => {
  const record = useRecordContext();
  return <span>AdBoard {record ? `"${record.title}"` : ""}</span>;
};

const ActionBreadcrumbs = () => {
  const record = useRecordContext();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ py: 2 }}
    >
      <Link underline="hover" key="1" href={`/admin#/AdBoards`}>
        Ad Boards
      </Link>
      {record?.company?.name && (
        <Typography key="2">{record.company.name}</Typography>
      )}
      {record?.title && <Typography key="3">{record.title}</Typography>}
    </Breadcrumbs>
  );
};

const AdBoardEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      title={<AdBoardEditTitle />}
      actions={<ActionBreadcrumbs />}
      {...props}
    >
      <TabbedForm toolbar={false}>
        <FormTab label="Details">
          <DetailsEditTab />
        </FormTab>
        <FormTab label="Participants">
          <ParticipantTab />
        </FormTab>
        <FormTab label="Content">
          <ContentEditTab />
        </FormTab>
        <FormTab label="Engagement">
          <EngagementTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AdBoardEdit;
