import React from "react";

import ShowPharmacistProfile from "./ShowPharmacistProfile";
import ShowPhysicianProfile from "./ShowPhysicianProfile";
import ShowNurseProfile from "./ShowNurseProfile";
import {
  ConditionalNurseFields,
  ConditionalPharmacistFields,
  ConditionalPhysicianFields,
} from "./utils";

const ShowHcpProfile = () => {
  return (
    <>
      <ConditionalPhysicianFields>
        <ShowPhysicianProfile />
      </ConditionalPhysicianFields>
      <ConditionalPharmacistFields>
        <ShowPharmacistProfile />
      </ConditionalPharmacistFields>
      <ConditionalNurseFields>
        <ShowNurseProfile />
      </ConditionalNurseFields>
    </>
  );
};

export default ShowHcpProfile;
