import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import ReactPlayer from "react-player";

import { addCommentForVideo } from "../../api/videoComments";
import { useVideoComments } from "../../hooks/useVideoComments";
import { VideoCommentsDisplay } from "../VideoCommentsDisplay";
import MentionsTextArea from "../MentionsTextArea";
import { useMentions } from "../../hooks/useMentions";

interface User {
  id: string;
  name: string;
}
// TODO: this is just a mock array of users. Remove this once we connect this component with ad-boards.
const mockUsers: User[] = [
  { id: "1", name: "alice" },
  { id: "2", name: "alex" },
  { id: "3", name: "akash" },
  { id: "5", name: "bob" },
];

export const VideoComments = forwardRef<
  ReactPlayer,
  {
    videoId: string;
  }
>(({ videoId }, playerRef) => {
  const { videoComments, refetchComments, loading, error } =
    useVideoComments(videoId);
  const {
    inputValue,
    setInputValue,
    textareaRef,
    handleInputValueChange,
    showSuggestions,
    suggestions,
    handleSuggestionClick,
    renderMentionsText,
  } = useMentions(mockUsers);
  const addComment = async () => {
    if (textareaRef.current) {
      const iframe = document.querySelector("iframe");
      if (iframe && playerRef && "current" in playerRef && playerRef.current) {
        try {
          const player = playerRef.current.getInternalPlayer();
          const currentTime = await player.getCurrentTime();
          await addCommentForVideo({
            videoId,
            text: inputValue,
            timestamp: parseInt(`${currentTime}`, 10),
          });
          refetchComments();
          setInputValue(""); // Clear the textarea after adding the comment
        } catch (error) {
          console.error("Error getting current time:", error);
        }
      }
    }
  };
  return (
    <Box>
      <MentionsTextArea
        ref={textareaRef}
        inputValue={inputValue}
        handleInputValueChange={handleInputValueChange}
        showSuggestions={showSuggestions}
        suggestions={suggestions}
        handleSuggestionClick={handleSuggestionClick}
        onSave={addComment}
      />
      <Box mt={2}>
        <VideoCommentsDisplay
          videoComments={videoComments}
          renderMentionsText={renderMentionsText}
          loading={loading}
          error={error}
        />
      </Box>
    </Box>
  );
});

VideoComments.displayName = "VideoComments";
