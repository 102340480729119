import * as React from "react";
import { Create, SimpleForm, NumberInput } from "react-admin";
import Box from "@mui/material/Box";

const Separator = () => <Box pt="1em" />;

const ReferralsCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={{
        birthday: new Date(),
        first_seen: new Date(),
        last_seen: new Date(),
        has_ordered: false,
        latest_purchase: new Date(),
        has_newsletter: false,
        groups: [],
        nb_commands: 0,
        total_spent: 0,
      }}
    >
      <Separator />

      <NumberInput source="payout" multiline fullWidth helperText={false} />
      <NumberInput source="sentBy" multiline fullWidth helperText={false} />
      <NumberInput source="receivedBy" multiline fullWidth helperText={false} />
      <NumberInput
        source="engagementId"
        multiline
        fullWidth
        helperText={false}
      />
    </SimpleForm>
  </Create>
);

export default ReferralsCreate;
