import React, { useState, ChangeEvent } from "react";
import { TextField, Typography, SxProps } from "@mui/material";

interface EditableTextProps {
  value: string;
  label?: string;
  onSave: (updatedValue: { [key: string]: string }) => void;
  field: string;
  sx?: SxProps; // Optional sx prop for custom styles
}

const EditableText: React.FC<EditableTextProps> = ({
  value,
  label,
  onSave,
  field,
  sx,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(value);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave({ [field]: text });
  };

  return isEditing ? (
    <TextField
      value={text}
      onChange={handleTextChange}
      onBlur={handleBlur}
      autoFocus
      variant="outlined"
      size={"small"}
    />
  ) : (
    <Typography
      variant="body1"
      onClick={handleTextClick}
      sx={{ cursor: "text", ...sx }} // Apply sx prop to the Typography
    >
      {!text ? label : text}
    </Typography>
  );
};

export default EditableText;
