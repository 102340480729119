import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { AttachmentDto, AttachmentFormDto } from '@keyops-hcp/dtos';

import { AttachmentForm } from './CreateAttachmentForm';
import { UseMutateFunction } from '@keyops-hcp/ui-components';

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: 1000,
  width: '30vw',
  maxWidth: '450px',
  borderRadius: '8px',
} as const;

interface EditAttachmentModalProps {
  open: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attachment: AttachmentDto;
  updateAttachmentSection: UseMutateFunction<
    AttachmentDto,
    unknown,
    {
      attachmentId: string;
      attachment: AttachmentFormDto;
    },
    unknown
  >;
}

export const EditAttachmentModal: React.FC<EditAttachmentModalProps> = ({
  open,
  setIsModalOpen,
  attachment,
  updateAttachmentSection,
}) => {
  // No file accessible in the attachment. Only the url.
  // TODO: do we want to preview the file content in the admin? If so, add a call to get it from s3
  const [attachmentFormData, setAttachmentFormData] = useState<
    Partial<AttachmentFormDto> | undefined
  >({
    title: attachment.title,
    description: attachment.description,
    readingTime: attachment.readingTime,
  });

  // Populate the form with the file selected during the previous step
  useEffect(() => {
    if (attachment) {
      setAttachmentFormData({
        title: attachment.title,
        description: attachment.description,
        readingTime: attachment.readingTime,
      });
    }
  }, [attachment]);

  return (
    <Modal
      open={open}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2'>
          Document details
          <AttachmentForm
            attachment={attachmentFormData}
            attachmentId={attachment.id}
            setIsModalOpen={setIsModalOpen}
            updateAttachmentSection={updateAttachmentSection}
          />
        </Typography>
      </Box>
    </Modal>
  );
};
