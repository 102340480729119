// External Dependencies
import React from "react";
import {
  TextField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  NumberField,
  DateField,
} from "react-admin";

const RewardsTransactionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="userId" reference="Users">
        <TextField label="id" source="id" />
      </ReferenceField>
      <TextField source="type" />
      <NumberField source="amount" />
      <TextField source="notes" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export default RewardsTransactionShow;
