// External Dependencies
import React from "react";
import {
  AutocompleteInput,
  Button,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useCreate,
} from "react-admin";
import { Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Internal Dependencies

interface NewAdBoardFormFields {
  title: string;
  companyId: string;
}
interface AdBoardCreateProps {
  showCreateDialog: boolean;
  closeCreateDialog: () => void;
}

const validateAdBoardCreate = (values: NewAdBoardFormFields) => {
  const errors: Partial<NewAdBoardFormFields> = {};
  if (!values.title || values.title.trim() === "") {
    errors.title = "Title is required";
  }
  if (!values.companyId || values.companyId.trim() === "") {
    errors.companyId = "Company is required";
  }
  return errors;
};

const AdBoardCreate: React.FC<AdBoardCreateProps> = ({
  showCreateDialog,
  closeCreateDialog,
}) => {
  const [create] = useCreate();

  const saveAdBoard = (data: NewAdBoardFormFields) => {
    create("AdBoards", { data });
    closeCreateDialog();
  };

  return (
    <Dialog
      open={showCreateDialog}
      onClose={closeCreateDialog}
      sx={{ "& .MuiDialog-paper": { width: "30%", maxHeight: 435 } }}
    >
      <DialogTitle
        sx={{ m: 0, fontSize: "1.125rem", fontWeight: 700 }}
        id="customized-dialog-title"
      >
        Create ad board
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeCreateDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Create sx={{ "& .RaCreate-main": { marginTop: 0 } }}>
        <SimpleForm
          validate={validateAdBoardCreate}
          onSubmit={saveAdBoard}
          toolbar={false}
        >
          <TextInput label="Ad board name" source="title" fullWidth required />
          <ReferenceInput
            label="Company"
            source="companyId"
            reference="Company"
          >
            <AutocompleteInput optionText="name" fullWidth isRequired />
          </ReferenceInput>
          <Typography variant="subtitle2" gutterBottom>
            This ad board will be created in draft mode and will not be visible
            to participants until published.
          </Typography>
          <Button
            label="Create ad board"
            type={"submit"}
            variant={"contained"}
            size={"medium"}
            fullWidth
            sx={{ mt: 2, textTransform: "capitalize" }}
          />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

export default AdBoardCreate;
