import React from "react";
import { useSearchParams } from "react-router-dom";
import { Avatar, Container, Link, Stack, Typography } from "@mui/material";

import { KeyopsSubTitle1 } from "../Headers";
import { VideoCommentsDisplayProps } from "./VideoCommentsDisplay.types";
import { getVideoTime, getTimeSince } from "../../utils/datetime";
import { getUserDisplayName } from "../../utils/user";
import i18next from "../../languages/i18n.config";

export const VideoCommentsDisplay = ({
  videoComments,
  renderMentionsText,
  loading,
  error,
}: VideoCommentsDisplayProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const jumpToTime = (timestamp: number) => {
    if (timestamp !== undefined && timestamp !== null) {
      setSearchParams({ videoTime: timestamp.toString() });
    }
  };

  if (loading) return <div>{i18next.t("common.loading")}</div>;
  if (error)
    return (
      <div>
        {i18next.t("common.error")} {error}
      </div>
    );
  return (
    <Container>
      <KeyopsSubTitle1>
        {i18next.t("video.comments.count", { count: videoComments.length })}
      </KeyopsSubTitle1>
      {videoComments.map((videoComment) => (
        //TODO: pull this into component to display individual
        <Container key={videoComment.id} sx={{ margin: 4 }}>
          <Stack direction="row">
            <Avatar
              sx={{
                backgroundColor: "#C9D4E1",
                color: "#000000A3",
                width: "40px",
                height: "40px",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {videoComment.user?.firstName?.length > 0
                ? videoComment.user.firstName[0]
                : ""}
              {videoComment.user?.lastName?.length > 0
                ? videoComment.user.lastName[0]
                : ""}
            </Avatar>
            <Stack>
              <Container>
                <Stack direction="row" spacing={4}>
                  <Typography>
                    {getUserDisplayName(videoComment.user)}
                  </Typography>
                  <Typography>
                    <Link
                      component="button"
                      onClick={() => jumpToTime(videoComment.timestamp)}
                    >
                      {getVideoTime(videoComment.timestamp)}
                    </Link>
                  </Typography>
                  {/*TODO: link to timestamp */}
                  <Typography>
                    {getTimeSince(videoComment.createdAt)}
                  </Typography>
                </Stack>
              </Container>
              <Container>
                <Typography>
                  {renderMentionsText
                    ? renderMentionsText(videoComment.text)
                    : videoComment.text}
                </Typography>
              </Container>
              <Container>
                {/*TODO: link to reply to comment goes here */}
              </Container>
            </Stack>
          </Stack>
        </Container>
      ))}
      {videoComments.length === 0 && (
        <Container>
          <KeyopsSubTitle1>{i18next.t("video.comments.empty")}</KeyopsSubTitle1>
        </Container>
      )}
    </Container>
  );
};
