import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { declineAdBoardInvite } from '../api/adBoardInvites';
import { AdBoardInviteDto } from '@keyops-hcp/dtos';

export const useDeclineAdBoardInviteMutation = (): UseMutationResult<
  AdBoardInviteDto,
  Error
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (adBoardId: string) => declineAdBoardInvite(adBoardId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getOneAdBoardInviteByAdBoardId'],
      });
    },
  });
};
