// External Dependencies
import axios, { Method } from 'axios';
import { keyOpsLSToken } from './constants';
import i18next from '../languages/i18n.config';
import jwtDecode from 'jwt-decode';

// TODO: used many times accross apps, let's put it in a const somewhere
type PhysicianAdminToken = {
  email: string;
  role: 'Admin';
};

//TODO: more elegant way to do this?
//maybe thrown an exception that can be caught by the app and do the redirect
const LOGIN_ROUTE = '/login';

export const axiosCall = async (
  publicRoute: boolean,
  method: Method,
  endpoint: string,
  pathParam?: string,
  queryStringParams?: Record<string, string>,
  requestBody?: any,
  additionalHeaders?: Record<string, string>
  // isOnboarding // without this the app can loop
) => {
  const withCredentials = true;
  const headers: Record<string, string> = {
    ...additionalHeaders,
    'Content-Type': 'application/json',
  };
  // TODO, move this into a separate repo, include all the utils
  if (!publicRoute) {
    //this will exist for admin-ui only
    const accessToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem(keyOpsLSToken);
    if (!accessToken) {
      if (refreshToken) {
        //this branch is for physician-ui only
        try {
          const accessTokenResults = await axios({
            headers,
            //TODO: normalize?
            baseURL: process.env.REACT_APP_API_URL,
            method: 'post',
            url: 'access-token',
            withCredentials,

            data: {
              Keyops__refreshToken: localStorage.getItem(keyOpsLSToken),
            },
          });

          headers['keyops__accesstokens'] = accessTokenResults.data.accessToken;
        } catch (e) {
          //TODO: maybe?
          //localStorage.removeItem(keyOpsLSToken);
          window.location.href = LOGIN_ROUTE;
          return;
        }
      }
    } else {
      const decodedToken = jwtDecode(accessToken);

      // Check if the token contains an admin role
      //  and set the headers accordingly
      if ((decodedToken as PhysicianAdminToken).role === 'Admin') {
        headers['userType'] = 'admin';
      }
      headers['keyops__accesstokens'] = accessToken;
    }
  }

  // 2. Build the URL
  let url = endpoint;
  if (pathParam) {
    url += `/${pathParam}`;
  }

  // 3. Return the call
  let results;

  try {
    results = await axios({
      headers,
      baseURL:
        process.env.REACT_APP_API_URL ?? process.env.REACT_APP_API_APP_URL,
      method,
      url,
      params: queryStringParams,
      data: requestBody,
      // This might need to change for private vs public
      withCredentials,

      //credentials: 'include',
    });
  } catch (e) {
    // TODO: revise how it's done because not convenient to setErrorMessage by going to error.response.data.message
    const errorCode = e.response?.data?.errorCode ?? 'something_wrong';
    if (e.response?.data) {
      e.response.data.message = i18next.t(`apiErrorMessages.${errorCode}`, {
        contextData: e.response?.data?.contextData,
      });
    }
    results = e;
  }
  return results;
};
