import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { KeyopsHeader3 } from '../Headers';

interface DiscussionDrawerHeaderProps {
  title: string;
  toggleDiscussionDrawer: () => void;
  isDisplayingMessages: boolean;

  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const DiscussionDrawerHeader = ({
  title,
  toggleDiscussionDrawer,
  isDisplayingMessages,
  setDisplayedDiscussionId,
}: DiscussionDrawerHeaderProps): JSX.Element => {
  return (
    <Box
      p={isDisplayingMessages ? '16px' : '8px 8px 8px 0'}
      display={'flex'}
      justifyContent={isDisplayingMessages ? 'left' : 'space-between'}
      alignItems={'center'}
    >
      {/* Discussion nav title */}
      {isDisplayingMessages && (
        <IconButton
          size='small'
          onClick={() => {
            setDisplayedDiscussionId(undefined);
          }}
        >
          <FiChevronLeft style={{ fontSize: 20 }} />
        </IconButton>
      )}
      <KeyopsHeader3 pl={`16px`}>{title}</KeyopsHeader3>
      {!isDisplayingMessages && (
        <IconButton size='small' onClick={toggleDiscussionDrawer}>
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
