import { useQuery, UseQueryResult } from 'react-query';
import { AdBoardContractDto } from '@keyops-hcp/dtos';
import { getAdBoardContractById } from '../api/adboards-api';

export const useAdBoardContractById = (
  id: string
): UseQueryResult<AdBoardContractDto, Error> => {
  return useQuery({
    queryKey: ['getAdBoardContractById', id],
    queryFn: () => getAdBoardContractById(id),
  });
};
