import DOMPurify from "dompurify";
import React from "react";
import { HTMLOutputProps } from "./HTMLOutput.types";

const sanitize = (dirty: string) => ({
  __html: DOMPurify.sanitize(dirty),
});

/**
 * Sanitizes using DOMPurify html and outputs wrapped in a div.
 * @param props with html to be sanitized
 * @returns
 */
export const HTMLOutput: React.FC<HTMLOutputProps> = ({ html }) => {
  return <div dangerouslySetInnerHTML={sanitize(html)} />;
};
