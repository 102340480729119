import { NotificationType } from 'react-admin';
import {
  AttachmentDto,
  AttachmentFormDto,
  AttachmentsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';
import { useMutation } from '@keyops-hcp/ui-components';

import { createOneAttachment } from '../api/attachments';
import { useUpdateSectionContent } from './useUpdateSectionContent';

export const useCreateAttachmentSection = (
  section: AttachmentsAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    }
  ) => void,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setNewAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>
) => {
  // To be able to update the section content on success
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    AttachmentDto,
    unknown,
    { attachmentToSave: AttachmentFormDto }
  >(
    async ({ attachmentToSave }): Promise<AttachmentDto> => {
      // First create the Attachment
      setProcessing(true);
      const createdAttachment = await createOneAttachment(attachmentToSave);

      return createdAttachment;
    },
    {
      onSuccess: (createdAttachment) => {
        if (createdAttachment) {
          try {
            // Then update the section
            updateSectionContent({
              sectionId: section.id,
              content: [
                {
                  attachmentId: createdAttachment.id,
                  index: section?.content?.length ?? 0,
                },
              ],
            });
            notify('Document uploaded successfully', { type: 'success' });
          } catch (error) {
            console.error('Failed to update section:', error);
          } finally {
            setProcessing(false);
            setNewAttachmentFile(undefined);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to create the document', { type: 'error' });
        setProcessing(false);
      },
    }
  );
};
