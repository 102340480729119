import React, { MouseEvent, useState } from 'react';
import { useNotifications } from '../../hooks';
import { NotificationsButton } from '../NotificationsButton';
import { NotificationsUI } from '../NotificationsUI';

export const Notifications = () => {
  // notification popover config
  const [notificationAnchor, setNotificationAnchor] =
    useState<HTMLButtonElement | null>(null);
  const toggleNotificationsUI = (event: MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchor(event.currentTarget);
  };
  const handleNotificationsUIClose = () => {
    updateNotificationsReadStatus();
    setNotificationAnchor(null);
  };
  const isNotificationUIOpen = Boolean(notificationAnchor);

  const {
    list: notificationsList,
    unread: areNotificationsUnread,
    updateReadStatus: updateNotificationsReadStatus,
    loading: loadingNotifications,
    error: notificationsError,
  } = useNotifications();

  return (
    <>
      <NotificationsButton
        toggleNotificationsUI={toggleNotificationsUI}
        areNotificationsUnread={areNotificationsUnread}
      />
      <NotificationsUI
        notificationAnchor={notificationAnchor}
        isOpen={isNotificationUIOpen}
        handleClose={handleNotificationsUIClose}
        toggleNotificationsUI={toggleNotificationsUI}
        notificationsList={notificationsList}
        loadingNotifications={loadingNotifications}
        notificationsError={notificationsError}
      />
    </>
  );
};
