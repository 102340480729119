import * as React from "react";
import { List, EditButton, Datagrid, TextField, EmailField } from "react-admin";

const AdminList = (props) => (
  <List exporter={false} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AdminList;
