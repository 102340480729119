import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

import { Loader } from '../Loader';
import { usePostDiscussionMessage } from '../../hooks';
import { theme } from '../../theme';
import i18next from '../../languages/i18n.config';

interface DiscussionMessageFormProps {
  discussionId: string;
}

export const DiscussionMessageForm = ({
  discussionId,
}: DiscussionMessageFormProps): JSX.Element => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: undefined,
    },
  });

  const handlePostMessage: SubmitHandler<{ message: string }> = async (
    messageTosave
  ) => {
    postDiscussionMessage({ ...messageTosave, discussionId });
    reset();
  };

  // Post one message (and invalidate the get all discussions in the mutation itself)
  const {
    mutate: postDiscussionMessage,
    isLoading,
    isError,
  } = usePostDiscussionMessage();

  if (isError) return <>Something went wrong</>;

  return (
    <Box>
      <form onSubmit={handleSubmit(handlePostMessage)}>
        {isLoading ? (
          <Loader />
        ) : (
          <FormGroup>
            {/* TODO:improve the style */}
            <FormControl variant='outlined'>
              <OutlinedInput
                id='discussion-message-message-input'
                aria-describedby='discussion-message-message-input'
                placeholder={i18next.t('forms.helperMessage')}
                multiline
                fullWidth
                rows={5}
                maxRows={10}
                {...register('message')}
                sx={{
                  paddingBottom: `8px`,
                  position: 'relative',
                  borderColor: theme.palette.keyops.gray[400],
                }}
                endAdornment={
                  <Button
                    type='submit'
                    variant='outlined'
                    sx={{
                      position: 'absolute',
                      right: '8px',
                      bottom: '8px',
                      color: theme.palette.keyops.blue.dark,
                      borderColor: theme.palette.keyops.blue.dark,
                    }}
                  >
                    {i18next.t('general.send')}
                  </Button>
                }
              />

              {errors.message && (
                <FormHelperText>{errors.message.message}</FormHelperText>
              )}
            </FormControl>
          </FormGroup>
        )}
      </form>
    </Box>
  );
};
