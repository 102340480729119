import React from 'react';
import { List } from '@mui/material';
import {
  DiscussionAdBoardSectionContentDto,
  DiscussionsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { DiscussionListItem } from './DiscussionListItem';

type DiscussionsList = {
  discussions: DiscussionAdBoardSectionContentDto[];
  section: DiscussionsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
};

export const DiscussionsList = ({
  discussions,
  section,
  setEditSection,
}: DiscussionsList) => {
  return (
    <List>
      {discussions?.length > 0 &&
        discussions.map((discussion) => (
          <DiscussionListItem
            key={discussion.id + `-` + discussion.discussionId} // Needed since discussionAdBoardSection PK is composite, formed by both section.id and discussion.id
            discussion={discussion.discussion}
            section={section}
            setEditSection={setEditSection}
          />
        ))}
    </List>
  );
};
