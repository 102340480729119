import React from "react";
import { BulkDeleteButton } from "react-admin";

const CustomBulkDeleteButton = (props) => {
  return (
    <BulkDeleteButton
      confirmTitle={`Delete all the selected items?`}
      confirmContent={"Are you sure you want to delete these items?"}
      undoable={false}
      {...props}
    />
  );
};

export default CustomBulkDeleteButton;
