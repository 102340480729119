// External Dependencies
// import * as React from 'react';
import React from "react";
import { Edit, BooleanInput, SimpleForm } from "react-admin";

// Internal Dependencies

const ReferralsEdit = (props) => {
  return (
    <Edit title={<div />} aside={<div />} {...props}>
      <SimpleForm>
        {/*<NumberInput source="payout" multiline fullWidth helperText={false} />
      <NumberInput source="sentBy" multiline fullWidth helperText={false} />
      <NumberInput source="receivedBy" multiline fullWidth helperText={false} />
      <NumberInput source="engagementId" multiline fullWidth helperText={false} />*/}
        <BooleanInput source="paid" />
      </SimpleForm>
    </Edit>
  );
};

export default ReferralsEdit;
