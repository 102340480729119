import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import { SectionDto } from '@keyops-hcp/dtos';

import i18next from '../../languages/i18n.config';
import { AdBoardSectionDisplay } from '../AdBoardSectionDisplay';
import { KeyopsHeader2, KeyopsSubTitle1, KeyopsSubTitle2 } from '../Headers';
import { AdBoardCompletedTaskButton } from '../AdBoardCompletedTaskButton';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';

const TitleSection = ({
  sections,
  currentSection,
}: {
  sections: SectionDto[];
  currentSection: SectionDto;
}) => (
  <Stack spacing={1} mb={2}>
    <KeyopsSubTitle2>
      {i18next.t('ad_board.sections_view.section_number', {
        currentSection: currentSection.index + 1,
        totalSections: sections.length,
      })}
    </KeyopsSubTitle2>
    <KeyopsHeader2 fontSize={18}>
      {currentSection.type.charAt(0).toUpperCase() +
        currentSection.type.slice(1)}
      : {currentSection.title}
    </KeyopsHeader2>
    {currentSection.description && (
      <KeyopsSubTitle1>{currentSection.description}</KeyopsSubTitle1>
    )}
  </Stack>
);

export const AdBoardSectionsToggle = ({
  currentSection,
  setCurrentSectionId,
}: {
  currentSection: SectionDto;
  setCurrentSectionId: (id: string) => void;
}) => {
  const [fullScreenData, setFullScreenData] = useState(false);
  const setIsFullScreenData = (isFullScreen: boolean) =>
    setFullScreenData(isFullScreen);

  const {
    isAdmin,
    isLoading,
    isError,
    sectionsData: sections,
    finishedSections = [], // Provide default values to avoid errors
  } = (useAdBoardContext() as HCPAdBoardContextType) || {};

  useEffect(() => {
    if (sections && sections.length > 0 && currentSection) {
      setCurrentSectionId(currentSection.id);
    }
  }, [sections, currentSection]);

  const isCurrentSectionFinished = useMemo(() => {
    if (!currentSection || !finishedSections) return false;
    return !!finishedSections.find(
      (section) => section.sectionId === currentSection.id
    );
  }, [finishedSections, currentSection]);

  if (isLoading) return <div>{i18next.t('common.loading')}</div>;
  if (isError || !currentSection) return <div>{i18next.t('common.error')}</div>;
  if (currentSection && currentSection.type !== 'discussion') {
    return (
      <>
        {!fullScreenData && (
          <TitleSection sections={sections} currentSection={currentSection} />
        )}
        <AdBoardSectionDisplay
          section={currentSection}
          setIsFullScreenData={setIsFullScreenData}
        />
        {!fullScreenData && (isCurrentSectionFinished || isAdmin) && (
          <AdBoardCompletedTaskButton
            onClick={() =>
              currentSection.index !== sections.length - 1
                ? setCurrentSectionId(sections[currentSection.index + 1].id)
                : console.log('Finished')
            }
            buttonContent={
              <>
                {currentSection.index !== sections.length - 1
                  ? i18next.t('ad_board.completed_task_button.next')
                  : i18next.t('ad_board.completed_task_button.finished')}
                <FiArrowRight />
              </>
            }
          />
        )}
      </>
    );
  }
};
