import { useQuery } from "@tanstack/react-query";
import { VideoDto } from "@keyops-hcp/dtos";
import { getOneVideo } from "../api/videos";

export const useVideo = (videoId: string) => {
  const {
    data: video,
    isLoading: loading,
    isError: error,
  } = useQuery<VideoDto>({
    queryKey: ["video", videoId],
    queryFn: () => getOneVideo(videoId),
    enabled: !!videoId,
  });

  return { video, loading, error };
};
