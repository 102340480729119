// External Dependencies
import React from "react";
import { Create, SimpleForm, TextInput, email, SelectInput } from "react-admin";

// Internal Dependencies
import { HcpTypeChoices } from "../../../utils/choices.js";

const ValidateUserCreate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  }
  // validate email input ***
  return errors;
};

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" validate={ValidateUserCreate}>
      <TextInput label="First name *" source="firstName" fullWidth />
      <TextInput label="Last name *" source="lastName" fullWidth />
      <TextInput source="email" validate={email()} fullWidth />
      <SelectInput source="hcpType" choices={HcpTypeChoices} fullWidth />
    </SimpleForm>
  </Create>
);

export default UserCreate;
