import React, { useRef, useState } from 'react';
import { Link } from '@mui/material';

interface User {
  id: string;
  name: string;
}

export const useMentions = (users: User[]) => {
  // This is the text value for the textarea.
  const [text, setText] = useState('');
  // suggestions list of users for mentioning in the textarea.
  // This will only show up when user types @ in the textarea.
  const [suggestions, setSuggestions] = useState<User[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [mentionStartIndex, setMentionStartIndex] = useState<number | null>(
    null
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleInputValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, selectionStart } = event.target;
    setText(value);

    const cursorPosition = selectionStart ?? 0;
    const textBeforeCursor = value.slice(0, cursorPosition);

    const mentionTriggerIndex = textBeforeCursor.lastIndexOf('@');
    if (
      mentionTriggerIndex !== -1 &&
      (mentionTriggerIndex === 0 || value[mentionTriggerIndex - 1] === ' ')
    ) {
      const query = textBeforeCursor.slice(mentionTriggerIndex + 1);
      if (query) {
        const filteredSuggestions = users.filter((user) =>
          user.name.toLowerCase().startsWith(query.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setMentionStartIndex(mentionTriggerIndex);
      } else {
        setShowSuggestions(false);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: User) => {
    if (mentionStartIndex === null || textareaRef.current === null) return;

    const textBeforeCursor = text.slice(0, mentionStartIndex);
    const cursorPosition = textareaRef.current.selectionStart ?? 0;
    const textAfterCursor = text.slice(cursorPosition);
    const newText = `${textBeforeCursor}@${suggestion.name} ${textAfterCursor}`;
    setText(newText);
    setShowSuggestions(false);
    setMentionStartIndex(null);
    textareaRef.current.focus();
  };

  const renderMentionsText = (text: string): (string | JSX.Element)[] => {
    const mentionRegex = /@\w+/g;
    const parts = text.split(mentionRegex);
    const mentions = text.match(mentionRegex) || [];

    return parts.reduce((acc, part, index) => {
      acc.push(part);
      if (mentions[index]) {
        acc.push(
          <Link
            key={index}
            component="button"
            onClick={() => {
              console.info("I'm a button.");
            }}
          >
            {mentions[index]}
          </Link>
        );
      }
      return acc;
    }, []);
  };

  return {
    inputValue: text,
    setInputValue: setText,
    textareaRef,
    handleInputValueChange,
    showSuggestions,
    suggestions,
    handleSuggestionClick,
    renderMentionsText,
  };
};
