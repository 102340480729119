import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { Close, Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';

import { useRecordContext } from 'react-admin';
import { deleteOneAdBoardInvite } from '../../../../api/adboard-invites-api';

export const DeleteAdBoardInviteDialog = ({
  onSave = () => {},
}: {
  onSave?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const invite = useRecordContext<AdBoardInviteDto>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} title='Delete participant'>
        <Delete />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await deleteOneAdBoardInvite(invite.adBoardId, invite.id);
            onSave();
            handleClose();
          },
        }}
      >
        <DialogTitle>Delete participant</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this participant? Deleting them will
            remove their access to this ad board.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' type='button' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
