// External Dependencies
import PersonIcon from "@mui/icons-material/Person";

// Internal Dependencies
import UserList from "./components/List";
import UserShow from "./components/Show";
import UserCreate from "./components/Create";
import UserEdit from "./components/Edit";

const resource = {
  list: UserList,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
  icon: PersonIcon,
};

export default resource;
