import React from "react";

import EditPharmacistProfile from "./EditPharmacistProfile";
import EditPhysicianProfile from "./EditPhysicianProfile";
import {
  ConditionalNurseFields,
  ConditionalPharmacistFields,
  ConditionalPhysicianFields,
} from "./utils";
import EditNurseProfile from "./EditNurseProfile";

const EditHcpProfile = () => {
  return (
    <>
      <ConditionalPhysicianFields>
        <EditPhysicianProfile />
      </ConditionalPhysicianFields>
      <ConditionalPharmacistFields>
        <EditPharmacistProfile />
      </ConditionalPharmacistFields>
      <ConditionalNurseFields>
        <EditNurseProfile />
      </ConditionalNurseFields>
    </>
  );
};

export default EditHcpProfile;
