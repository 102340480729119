import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { Button, Loading, useNotify } from 'react-admin';
import { useAdBoardContractById } from '../../../../custom-hooks/useAdBoardContractById';
import { useUploadContract } from '../../../../custom-hooks/useUploadContract';

export const ContractBox = ({ adBoardId }: { adBoardId: string }) => {
  const notify = useNotify();
  const {
    data: contract,
    isLoading,
    isError,
  } = useAdBoardContractById(adBoardId);
  const { mutate: uploadContract, isLoading: isUploadingContract } =
    useUploadContract(notify);
  const handleUpload = async (file: File) => {
    await uploadContract({ adBoardId, file });
  };
  if (isLoading || isUploadingContract) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (isError) {
    return <Container>Error: {isError}</Container>;
  }
  return (
    <Box>
      <Typography fontWeight={600}>Contract</Typography>
      <Typography pb={2}>
        Each invite includes a contract that participants must sign before they
        can join the ad board. The contract is automatically populated with the
        participant&apos;s details from the table below. Note that the contract
        template must be in DOCX format.
      </Typography>
      <Stack spacing={2} direction='row'>
        <Typography>{contract?.name}</Typography>
        <label htmlFor='upload-contract'>
          <input
            id='upload-contract'
            type='file'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files?.[0]) handleUpload(e.target.files?.[0]);
            }}
          />
          <Button
            variant='outlined'
            label='Upload Contract'
            sx={{ textTransform: 'initial' }}
            component='span'
          />
        </label>
      </Stack>
    </Box>
  );
};
