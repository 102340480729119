import { useQuery } from "@tanstack/react-query";
import { AttachmentDto } from "@keyops-hcp/dtos";
import { getOneAttachment } from "../api/attachments";

export const useAttachment = (attachmentId: string) => {
  const {
    data: attachment,
    isLoading: loading,
    isError: error,
  } = useQuery<AttachmentDto>({
    queryKey: ["attachment", attachmentId],
    queryFn: () => getOneAttachment(attachmentId),
    enabled: !!attachmentId,
  });

  return { attachment, loading, error };
};
