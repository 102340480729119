import React from 'react';
import { Button, ButtonGroup, useTheme } from '@mui/material';
import { FiList, FiUsers } from 'react-icons/fi';

interface SectionNavButtonsProps {
  sectionDrawerOpen: boolean;
  handleSectionDrawerToggle: () => void;
}

export const SectionNavButtons = ({
  sectionDrawerOpen,
  handleSectionDrawerToggle,
}: SectionNavButtonsProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <ButtonGroup
      data-testid={'section-nav-button-group'}
      variant={'outlined'}
      orientation={'vertical'}
      color={'inherit'}
      onClick={handleSectionDrawerToggle}
      sx={{
        mt: 8,
        transition: 'transform 500ms ease-in-out',
        transform: sectionDrawerOpen ? 'scale(0)' : 'scale(1)',
        color: palette.keyops.blue.dark,
      }}
    >
      <Button
        key='list'
        data-testid={'list-button'}
        sx={{
          border: '1px solid var(--gray-200, #E2E8F0)',
          borderLeft: 0,
          borderTopLeftRadius: 0,
          p: 1.2,
          boxShadow: '0px 1px 2px 0px #0000000F',
        }}
      >
        <FiList fontSize={22} />
      </Button>
      <Button
        key='moderators'
        data-testid={'moderators-button'}
        sx={{
          border: '1px solid var(--gray-200, #E2E8F0)',
          borderLeft: 0,
          borderBottomLeftRadius: 0,
          p: 1.2,
          boxShadow: '0px 1px 3px 0px #0000001A',
        }}
      >
        <FiUsers fontSize={22} />
      </Button>
    </ButtonGroup>
  );
};
