import buildGraphQLProvider from "ra-data-graphql-simple";
import apolloClient from "./apolloClient";
import buildQuery from "./buildQuery";
import localDataProvider from "./localDataProvider";

const combinedDataProvider = async () => {
  const graphQLProvider = await buildGraphQLProvider({
    client: apolloClient,
    buildQuery: buildQuery,
  });

  return {
    getList: (resource, params) => {
      if (resource === "AdBoards") {
        return localDataProvider.getList(resource, params);
      }
      return graphQLProvider.getList(resource, params);
    },
    getOne: (resource, params) => {
      if (resource === "AdBoards") {
        return localDataProvider.getOne(resource, params);
      }
      return graphQLProvider.getOne(resource, params);
    },
    getMany: (resource, params) => graphQLProvider.getMany(resource, params),
    getManyReference: (resource, params) =>
      graphQLProvider.getManyReference(resource, params),
    create: (resource, params) => {
      if (resource === "AdBoards") {
        return localDataProvider.create(resource, params);
      }
      return graphQLProvider.create(resource, params);
    },
    update: (resource, params) => {
      if (resource === "AdBoards") {
        return localDataProvider.update(resource, params);
      }
      return graphQLProvider.update(resource, params);
    },
    updateMany: (resource, params) =>
      graphQLProvider.updateMany(resource, params),
    delete: (resource, params) => graphQLProvider.delete(resource, params),
    deleteMany: (resource, params) =>
      {
        if (resource === "AdBoards") {
          return localDataProvider.deleteMany(resource, params);
        }
        return graphQLProvider.deleteMany(resource, params);
      },
  };
};

export default combinedDataProvider;
