import React from 'react';
import { Button, useTheme, Grid } from '@mui/material';
import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { TaskAlt, HighlightOffOutlined } from '@mui/icons-material';
import { CallToActionBox } from '../CallToActionBox';
import {
  useAcceptAdBoardInviteMutation,
  useDeclineAdBoardInviteMutation,
} from '../../../../hooks';

export const AdBoardInviteChoiceBox = ({
  adBoardId,
}: {
  adBoardId: string;
}) => {
  const { t } = i18next;
  const theme = useTheme();
  const styles = useStyles(theme);
  const acceptAdBoardInviteMutation = useAcceptAdBoardInviteMutation();
  const declineAdBoardInviteMutation = useDeclineAdBoardInviteMutation();

  const acceptInvite = () => {
    acceptAdBoardInviteMutation.mutate(adBoardId);
  };
  const declineInvite = () => {
    declineAdBoardInviteMutation.mutate(adBoardId);
  };

  return (
    <CallToActionBox title={t('ad_board.landing_page.cta.invited.title')}>
      <Grid rowSpacing={2} container>
        <Grid
          item
          sm={12}
          md={6}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            variant='text'
            onClick={acceptInvite}
            sx={{
              ...styles.buttonAccept,
            }}
          >
            <div>
              <TaskAlt sx={{ fontSize: '61px' }} />
            </div>
            <div>{t('ad_board.landing_page.cta.invited.accept')}</div>
          </Button>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            variant='text'
            onClick={declineInvite}
            sx={{
              ...styles.buttonDecline,
            }}
          >
            <div>
              <HighlightOffOutlined sx={{ fontSize: '61px' }} />
            </div>
            <div>{t('ad_board.landing_page.cta.invited.decline')}</div>
          </Button>
        </Grid>
      </Grid>
    </CallToActionBox>
  );
};
