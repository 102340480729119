// External Dependencies
import LabelIcon from "@mui/icons-material/Label";

// Internal Dependencies
import AdBoardEdit from "./components/Edit";
import AdBoardList from "./components/List";

const resource = {
  list: AdBoardList,
  edit: AdBoardEdit,
  icon: LabelIcon,
};

export default resource;
