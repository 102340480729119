import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import i18next from "../../languages/i18n.config";
import { useAttachment } from "../../hooks/useAttachment";
import { formatSecondsDuration } from "../../utils/datetime";
import { KeyopsHeader3, KeyopsSubTitle1 } from "../Headers";

export const Attachment = ({
  attachmentId,
  onClose,
}: {
  attachmentId: string;
  onClose?: () => void;
}) => {
  const { attachment, loading, error } = useAttachment(attachmentId);

  if (loading) return <div>{i18next.t("common.loading")}</div>;
  if (error) return <div>{i18next.t("common.error")}</div>;
  return (
    <Stack spacing={1} mt={1}>
      <KeyopsHeader3>{attachment?.title}</KeyopsHeader3>
      <KeyopsSubTitle1>{attachment?.description}</KeyopsSubTitle1>
      {/* Preview if PDF */}
      {attachment.contentType === "application/pdf" && (
        <Box display={"block"} width={"fit-content"} margin={"auto"}>
          <iframe
            data-testid="attachment-preview"
            style={{ width: "70vw", height: "70vh" }}
            src={attachment.link}
          />
        </Box>
      )}

      {attachment?.readingTime && (
        <Box display={"block"}>
          <Typography
            variant="h2"
            fontSize={"14px"}
            fontWeight={600}
            display={"inline"}
          >
            {i18next.t("attachment.readingTime")}:{" "}
          </Typography>
          <Typography
            variant="body1"
            fontSize={"14px"}
            fontWeight={400}
            pt={"8px"}
            display={"inline"}
          >
            {formatSecondsDuration(attachment?.readingTime)}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
