import React, { useEffect, useState } from 'react';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';
import { Container, Stack } from '@mui/material';
import SectionGroupCard from './SectionGroupCard';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { reorderSections } from '../../../../api/adboards-api';

interface SectionGroupsListProps {
  sectionGroups: AdBoardSectionGroupDto[];
  onEditSection: (section: SectionDto) => void;
  onAddSection: (sectionGroup: AdBoardSectionGroupDto) => void;
}

const SectionGroupsList: React.FC<SectionGroupsListProps> = ({
  sectionGroups,
  onEditSection,
  onAddSection,
}) => {
  const [sectionGroupList, setSectionGroupList] = useState(sectionGroups);

  // IMPORTANT: Update sectionGroupList when sectionGroups is updated (happens on refetch)
  useEffect(() => {
    setSectionGroupList(sectionGroups);
  }, [sectionGroups]);
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const items = structuredClone(sectionGroups);

    //We don't care if it's the same source and destination or not, the mechanics are the same
    const sourceSectionGroup = items.find(
      (sectionGroup) => sectionGroup.id === source.droppableId
    );
    const destinationSectionGroup = items.find(
      (sectionGroup) => sectionGroup.id === destination.droppableId
    );

    if (
      !sourceSectionGroup ||
      !destinationSectionGroup ||
      !sourceSectionGroup.sections ||
      !destinationSectionGroup.sections
    ) {
      console.error(
        `Couldn't find section group id ${result.source.droppableId} or ${destination.droppableId}`
      );
      return;
    }

    const [removed] = sourceSectionGroup.sections.splice(
      result.source.index,
      1
    );

    removed.sectionGroupId = destinationSectionGroup.id;

    destinationSectionGroup.sections.splice(destination.index, 0, removed);

    setSectionGroupList(items);
    reorderSections(
      sectionGroups[0].adBoardId,
      items.flatMap((sectionGroup) =>
        sectionGroup.sections
          ? sectionGroup.sections.map((section, index) => {
              return {
                id: section.id,
                sectionGroupId: section.sectionGroupId,
                index,
              };
            })
          : []
      )
    );
  };
  return (
    <Container maxWidth='md' sx={{ mt: 4 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack spacing={2}>
          {sectionGroupList.map((sectionGroup) => (
            <SectionGroupCard
              key={sectionGroup.id}
              sectionGroup={sectionGroup}
              onEditSection={onEditSection}
              onAddSection={onAddSection}
            />
          ))}
        </Stack>
      </DragDropContext>
    </Container>
  );
};

export default SectionGroupsList;
