// External Dependencies
import * as React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
} from "react-admin";

// Internal Dependencies
import CustomBulkDeleteButton from "../../../components/CustomBulkDeleteButton";

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const CompanyBulkActionButtons = (props) => (
  <React.Fragment>
    {/* <ResetViewsButton label="Reset Views" {...props} /> */}
    {/* default bulk delete action */}
    <CustomBulkDeleteButton {...props} />
  </React.Fragment>
);

const CompanyList = (props) => (
  <List
    exporter={false}
    filters={<CompanyFilter />}
    bulkActionButtons={<CompanyBulkActionButtons />}
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CompanyList;
