import React, { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import ParticipantsList from './ParticipantsList';
import Feed from './Feed';

interface CustomTabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  id: string;
  ariaLabel: string;
}
const CustomTabPanel = ({
  children,
  value,
  index,
  id,
  ariaLabel,
}: CustomTabPanelProps) => {
  return (
    <div
      id={`${id}-tabpanel`}
      role='tabpanel'
      aria-labelledby={ariaLabel}
      hidden={value !== index}
    >
      {value === index && <Box py={1.5}>{children}</Box>}
    </div>
  );
};

const EngagementDetailsSection = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth='lg' sx={{ mt: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          aria-label='engagement-details-section-tabs'
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab
            id='participant-tab'
            aria-controls='participant-tabpanel'
            label='Participants'
          />
          <Tab id='feed-tab' aria-controls='feed-tabpanel' label='Feed' />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={tabValue}
        index={0}
        id='participant-tab'
        ariaLabel='participant-tab'
      >
        <ParticipantsList />
      </CustomTabPanel>
      <CustomTabPanel
        value={tabValue}
        index={1}
        id='feed-tab'
        ariaLabel='feed-tab'
      >
        <Feed />
      </CustomTabPanel>
    </Container>
  );
};

export default EngagementDetailsSection;
