import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AdBoardDto } from '@keyops-hcp/dtos';
import { getOneAdBoard } from '../api/adBoards';

export const useAdBoardById = (
  adBoardId: string
): UseQueryResult<AdBoardDto, Error> => {
  return useQuery({
    queryKey: ['getAdBoardById', adBoardId],
    queryFn: () => getOneAdBoard(adBoardId),
    enabled: !!adBoardId,
  });
};
