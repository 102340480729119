// External Dependencies
import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useDataProvider,
} from "react-admin";
import { useLocation } from "react-router-dom";

// Internal Dependencies
import { SpecialtyChoices } from "../../../utils/choices";

// Creation Page
const InvitationCreate = () => {
  const location = useLocation();
  const { engagementId } = location.state || {};
  const [companyId, setCompanyId] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [selectedEngagementId, setSelectedEngagementId] = useState(""); // Store engagements by ID
  const [selectedEngagement, setSelectedEngagement] = useState(undefined); // Store engagements by ID

  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchEngagement = async () => {
      try {
        const { data: selectedEngagement } = await dataProvider.getOne(
          "Engagement",
          { id: selectedEngagementId },
          {
            filter: { companyId: companyId },
          }
        );

        setSelectedEngagement(selectedEngagement);
      } catch (error) {
        console.error("Error fetching engagements:", error);
      }
    };

    if (dataProvider && companyId && selectedEngagementId) {
      fetchEngagement();
    }
  }, [companyId, dataProvider, selectedEngagementId]);

  const handleEngagementChange = (event) => {
    const selectedEngagementId = event.target.value; // Get the selected engagement ID
    setSelectedEngagementId(selectedEngagementId);
  };

  const invitationDefaultValue = () => ({ engagementId });
  const redirect = engagementId ? `/Engagement/${engagementId}/show/4` : "show";

  return (
    <Create>
      <SimpleForm redirect={redirect} initialValues={invitationDefaultValue}>
        {/* Companies dropdown */}
        {!engagementId && (
          <ReferenceInput perPage={1000} source='companyId' reference='Company'>
            <SelectInput
              optionText={(record) => record.name}
              source='name'
              onChange={(e) => setCompanyId(e.target.value)}
              allowEmpty
              fullWidth
            />
          </ReferenceInput>
        )}

        {/* Engagements dropdown */}
        {(companyId || engagementId) && (
          <ReferenceInput
            perPage={1000}
            source='engagementId'
            reference='Engagement'
            filter={{ companyId: companyId || undefined }}
          >
            <SelectInput
              allowEmpty
              fullWidth
              source='title'
              optionText={(record) => record.title}
              onChange={(event) => handleEngagementChange(event)}
            />
          </ReferenceInput>
        )}

        {/* Specialties dropdown, display when the selected engagement needs physician HCP type only */}
        {selectedEngagement && selectedEngagement.hcpType === "physician" && (
          <SelectInput
            fullWidth
            allowEmpty
            label='Filter Users by Specialty'
            source='specialty'
            choices={SpecialtyChoices}
            onChange={(e) => setSpecialty(e.target.value)}
          />
        )}

        {/* Users dropdown, display when the engagement and the specialty (if physician type required) have been selected */}
        {selectedEngagement &&
          (selectedEngagement.hcpType === "physician" ? specialty : true) && (
            <ReferenceInput
              source='userId'
              reference='Users'
              filter={{
                ...(specialty && { specialty }),
                ...(selectedEngagement.hcpType && {
                  hcpType: selectedEngagement.hcpType,
                }),
              }}
              filterToQuery={(searchText) => ({
                q: searchText,
              })}
            >
              <AutocompleteInput
                optionText={(choice) => {
                  return `${choice ? choice.lastName : ""}, ${
                    choice ? choice.firstName : ""
                  }`;
                }}
                matchSuggestion={() => true}
                fullWidth
              />
            </ReferenceInput>
          )}
      </SimpleForm>
    </Create>
  );
};

export default InvitationCreate;
