import { useEditContext } from 'react-admin';
import { useQuery } from '@keyops-hcp/ui-components';

import { getParticipantsByAdBoardId } from '../../../../api/adboards-api';

const useAdBoardEngagementData = () => {
  const { record: adBoardData } = useEditContext();

  const {
    isLoading,
    isError,
    data: participantsData = [],
  } = useQuery({
    queryKey: ['getParticipantsByAdBoardId', adBoardData.id],
    queryFn: () => getParticipantsByAdBoardId(adBoardData.id),
    enabled: !!adBoardData?.id,
  });

  const today = new Date().setHours(0, 0, 0, 0);

  let activeToday = 0;
  let inProgressParticipants = 0;
  let completedParticipants = 0;

  const totalSections = adBoardData?.sections?.length || 0;

  participantsData.forEach((participant) => {
    const updatedAt = participant?.progress?.updatedAt;
    const sectionProgress =
      participant?.progress?.progressDetails?.sectionProgress || [];
    const finishedSections = sectionProgress.filter(
      (section) => section.finished
    ).length;

    // Check if participant was active today
    if (updatedAt && new Date(updatedAt).setHours(0, 0, 0, 0) === today) {
      activeToday++;
    }

    // Count participants based on their progress
    if (finishedSections < totalSections) {
      inProgressParticipants++;
    } else if (finishedSections === totalSections) {
      completedParticipants++;
    }
  });

  const totalParticipants = participantsData.length;
  const completionPercentage = totalParticipants
    ? Math.round((100 * completedParticipants) / totalParticipants)
    : 0; // Avoid division by zero

  return {
    isLoading,
    isError,
    adBoardData,
    totalSections,
    participantsData,
    activeToday,
    totalParticipants,
    inProgressParticipants,
    completedParticipants,
    completionPercentage,
  };
};

export default useAdBoardEngagementData;
