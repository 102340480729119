import { Theme } from "@mui/material";

export const useStyles = (theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontSize: "18px",
    color: theme.palette.keyops.black,
  },
  description: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.keyops.gray[700],
  },
  clickableBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  agendaBox: {
    minWidth: "30%",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)`,
  },
  rowStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  columnStyle: {
    p: 1.5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDF5F9",
  },
});
