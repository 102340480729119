export const stateChoices = [
  // the new state, default state
  { id: "draft", name: "Draft" },
  // Published, in an active state
  { id: "published", name: "Published" },
  // Can no longer be invited to
  { id: "closed", name: "Closed" },
  // Same as closed really just hides it from the ui
  { id: "deleted", name: "Deleted" },
];
