import { t } from 'i18next';
import { SURVEYS_ENDPOINT } from '../utils/api-routes';
import { axiosCall } from '../utils/api';

export const getOneSurveyUniqueLink = async (id: string): Promise<string> => {
  try {
    const results = await axiosCall(
      false,
      'get',
      SURVEYS_ENDPOINT + `/${id}`,
      undefined,
      undefined,
      { id }
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      results?.data?.message || t('apiErrorMessages.no_survey_found')
    );
  } catch (error) {
    console.error('Error fetching data getOneSurvey:', error);
    throw new Error(
      error?.response?.data?.message || t('apiErrorMessages.no_survey_found')
    );
  }
};
