import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AttachmentsAdBoardSectionDto, SectionDto } from '@keyops-hcp/dtos';

import { AttachmentsList } from './AttachmentsList';
import { AttachmentUploadButton } from './AttachmentUploadButton';

export const AttachmentsAccordion = ({
  section,
  setNewAttachmentFile,
  setEditSection,
}: {
  section: AttachmentsAdBoardSectionDto;
  setNewAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}) => {
  const [expanded, setExpanded] = useState<string | false>('attachment');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      variant={'outlined'}
      expanded={expanded === 'attachment'}
      onChange={handleChange('attachment')}
      sx={{ flexGrow: 1 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='attachments-content'
        id='attachments-header'
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography>Documents</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {/* TODO: add a form to update activity's title and description */}
        <AttachmentsList
          attachments={section.content}
          section={section}
          setEditSection={setEditSection}
        />
        {/* TODO: shoould we put the upload button above the list? Because risk of it being hidden */}
        <AttachmentUploadButton setNewAttachmentFile={setNewAttachmentFile} />
      </AccordionDetails>
    </Accordion>
  );
};
