import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import {
  AttachmentDto,
  AttachmentsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { KeyOpsEditIcon } from '../../../../components/Icons/KeyOpsEditIcon';
import { KeyOpsTrashIcon } from '../../../../components/Icons/KeyOpsTrashIcon';
import { useDeleteAttachmentSection } from '../../../../custom-hooks/useDeleteAttachmentSection';
import { EditAttachmentModal } from './EditAttachmentModal';
import { useUpdateAttachmentSection } from '../../../../custom-hooks/useUpdateAttachmentSection';

export const AttachmentListItem = ({
  attachment,
  section,
  setEditSection,
}: {
  attachment: AttachmentDto;
  section: AttachmentsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}) => {
  const notify = useNotify();

  // Delete attachmentSection handling
  const { mutate: deleteAttachmentSection } = useDeleteAttachmentSection(
    setEditSection,
    notify
  );
  const handleDeleteClick = (sectionId: string, attachmentId: string) => {
    // Trigger the mutation when the delete button is clicked
    deleteAttachmentSection({ sectionId, attachmentId });
  };

  // Edit attachmentSection handling
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: updateAttachmentSection } = useUpdateAttachmentSection(
    section,
    setEditSection,
    notify
  );

  return (
    <>
      <Card key={attachment.id} variant='outlined' sx={{ marginTop: '8px' }}>
        <CardContent>
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            pt={`8px`}
          >
            <Box>
              <Typography
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'12px'}
                pb={`8px`}
              >
                {attachment.title}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'12px'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {attachment.description}
              </Typography>
            </Box>
            <Stack direction={'row'} spacing={1}>
              <KeyOpsEditIcon onClick={() => setIsModalOpen(true)} />
              <KeyOpsTrashIcon
                onClick={() => handleDeleteClick(section.id, attachment.id)}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <EditAttachmentModal
        attachment={attachment}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        updateAttachmentSection={updateAttachmentSection}
      />
    </>
  );
};
