import React from 'react';
import { Card } from '@mui/material';
import { AttachmentUploadButton } from './AttachmentUploadButton';

export const AttachmentUploadCard = ({
  setNewAttachmentFile,
}: {
  setNewAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}) => {
  return (
    <Card
      variant={'outlined'}
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
      }}
    >
      <AttachmentUploadButton setNewAttachmentFile={setNewAttachmentFile} />
    </Card>
  );
};
