import { Theme } from "@mui/material";

export const useStyles = (theme: Theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
  },
  subTitle: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
  },
  date: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "34.74px",
  },
  description: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  contentContainer: {
    borderRadius: 2,
    mt: 5,
    display: "flex",
    flexDirection: "row",
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)`,
  },
  toggleButtonGroupBox: {
    width: "250px",
    backgroundColor: "#F9FCFD",
    p: 3,
  },
  toggleButtonGroup: {
    gap: 5,
    position: "relative",
    padding: 1.5,
    borderRadius: "8px",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "18%",
      top: "50%",
      height: "70%",
      width: "1.5px",
      backgroundColor: theme.palette.keyops.gray[400],
      transform: "translate(-50%, -50%)",
    },
    "& .MuiToggleButtonGroup-grouped": {
      color: theme.palette.keyops.gray[400],
      border: "none",
      borderRadius: "50%",
      marginBottom: 5,
      "&:last-of-type": {
        marginBottom: 0,
      },
      "&:hover": {
        borderRadius: "50%",
      },
      "&.Mui-selected": {
        color: `${theme.palette.keyops.blue.dark} !important`,
        backgroundColor: "transparent",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        color: "inherit",
        border: "none",
        outline: "none",
        boxShadow: "none",
      },
    },
  },
  toggleButton: {
    display: "flex",
    alignItems: "center",
  },
  toggleButtonLabel: (selected: boolean) => ({
    marginLeft: 2,
    fontWeight: selected ? 500 : 400,
    fontSize: "20px",
    lineHeight: "32px",
  }),
  content: {
    width: "100%",
    p: 3,
  },
});
