import React from 'react';
import { List } from '@mui/material';
import {
  AttachmentAdBoardSectionContentDto,
  AttachmentsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { AttachmentListItem } from './AttachmentListItem';

type AttachmentsList = {
  attachments: AttachmentAdBoardSectionContentDto[];
  section: AttachmentsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
};

export const AttachmentsList = ({
  attachments,
  section,
  setEditSection,
}: AttachmentsList) => {
  return (
    <List>
      {attachments?.length > 0 &&
        attachments.map((attachment) => (
          <AttachmentListItem
            key={attachment.id + `-` + attachment.attachmentId} // Needed since AttachmentAdBoardSection PK is composite, formed by both section.id and attachment.id
            attachment={attachment.attachment}
            section={section}
            setEditSection={setEditSection}
          />
        ))}
    </List>
  );
};
