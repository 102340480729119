// External Dependencies
import React, {
  useState,
  useEffect,
  Children,
  cloneElement,
  isValidElement,
} from "react";
import { gql } from "@apollo/client";
import { Labeled, InputHelperText } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import { shallowEqual } from "react-redux";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { useInput, useTranslate } from "ra-core";
import axios from "axios";

// Internal Dependencies
import { FileUploadPreview } from "./file_upload_preview";
import apolloClient from "../utils/apolloClient";

const DropZone = styled("div")(({ theme }) => ({
  background: theme.palette.background.default,
  cursor: "pointer",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.getContrastText(theme.palette.background.default),
}));

const Preview = styled("div")({
  display: "inline-block",
});

const Root = styled("div")(() => ({
  width: "100%",
}));

const uploadFile = async (file, signedUrl) => {
  var options = {
    headers: {
      "Content-Type": file.type,
    },
  };

  return axios.put(signedUrl, file, options);
};

const getSignedUrl = async (filename) => {
  const GET_SIGNED_URL = gql`
    query getSignedUrl($key: String) {
      getSignedUrl(key: $key)
    }
  `;
  const key = `${Date.now()}_${filename}`;

  const { data } = await apolloClient.query({
    query: GET_SIGNED_URL,
    variables: { key },
  });
  if (data.getSignedUrl !== null) {
    return data.getSignedUrl;
  }
};

export const FileUploadInput = (props) => {
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const {
    accept,
    children,
    format,
    helperText,
    label,
    labelMultiple = "ra.input.file.upload_several",
    labelSingle = "ra.input.file.upload_single",
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    validate,
    handleFileUpload,
    handleFileDelete,
    handleFileMove,
    ...rest
  } = props;

  const translate = useTranslate();

  const removeFile = async (file, index) => {
    if (file?.id) {
      const DELETE_IMAGE = gql`
        mutation deleteUploadedFile($image: String, $id: String) {
          deleteUploadedFile(image: $image, id: $id)
        }
      `;
      const { data } = await apolloClient.mutate({
        mutation: DELETE_IMAGE,
        variables: { image: file.link, id: file.id },
      });

      if (data.deleteUploadedFile !== null) {
        handleFileDelete(index);
        return data.deleteUploadedFile;
      }
    } else {
      handleFileDelete(index);
    }
  };

  // turn a browser dropped file structure into expected structure
  const transformFile = (file) => {
    if (!(file instanceof File)) {
      return file;
    }

    const { source, title } = Children.only(children).props;

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      [source]: preview,
    };

    if (title) {
      transformedFile[title] = file.name || "";
    }

    return transformedFile;
  };

  const transformFiles = (files) => {
    if (!files) {
      return multiple ? [] : null;
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return transformFile(files);
  };

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: "file",
    validate,
    ...rest,
  });
  const { touched, error } = meta;
  const files = value ? (Array.isArray(value) ? value : [value]) : [];

  useEffect(() => {
    if (!filesLoaded && files.length > 0) {
      files.forEach(async (file) => {
        handleFileUpload(file.link, file.id);
      });
      setFilesLoaded(true);
    }
  }, [files]);

  const onDrop = (newFiles, rejectedFiles, event) => {
    setUploadLoading(true);
    let uploadCounter = 0;
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];

    newFiles.forEach(async (file) => {
      const urlReq = await getSignedUrl(file.name);
      uploadFile(file, urlReq.url)
        .then((res) => {
          if (res.status === 200) {
            handleFileUpload(res.config.url);
            uploadCounter++;
            if (uploadCounter === newFiles.length) {
              setUploadLoading(false);
            }
          }
        })
        .catch((err) => console.log(err));
    });
    if (multiple) {
      onChange(updatedFiles);
    } else {
      onChange(updatedFiles[0]);
    }

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event);
    }
  };

  const onRemove = (file, index) => () => {
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file)
      );
      onChange(filteredFiles);
    } else {
      onChange(null);
    }
    removeFile(file, index);
    if (options.onRemove) {
      options.onRemove(file);
    }
  };

  const onMove = (file) => (offset) => {
    const index = files.indexOf(file);
    const newIndex = index + offset;

    if (newIndex > -1 && newIndex < files.length) {
      let copiedFiles = [...files];
      const removedElement = copiedFiles.splice(index, 1)[0];
      copiedFiles.splice(newIndex, 0, removedElement);
      handleFileMove(index, newIndex);
      onChange(copiedFiles);
    }
  };
  const childrenElement = isValidElement(Children.only(children))
    ? Children.only(children)
    : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  return (
    <Labeled
      id={id}
      label={label}
      className={Root}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
    >
      <>
        <DropZone data-testid="dropzone" {...getRootProps()}>
          {uploadLoading && <CircularProgress />}
          <input
            id={id}
            {...getInputProps({
              ...inputProps,
              ...inputPropsOptions,
            })}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </DropZone>
        <FormHelperText>
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        </FormHelperText>
        {children && (
          <div className="previews">
            {files.map((file, index) => (
              <FileUploadPreview
                key={index}
                file={file}
                onMove={onMove(file)}
                onRemove={onRemove(file, index)}
                className={Preview}
              >
                {cloneElement(childrenElement, {
                  record: file,
                  className: Preview,
                })}
              </FileUploadPreview>
            ))}
          </div>
        )}
      </>
    </Labeled>
  );
};
