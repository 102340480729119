// External Dependencies
import * as React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from "react-admin";
// Internal Dependencies
import { transactionTypesChoices } from "../data";

const AddFilterDropdown = (props) => (
  <Filter {...props}>
    <ReferenceInput source="userId" reference="Users" label="User Name">
      <AutocompleteInput
        source="id"
        optionText={(choice) => {
          if (!choice) return ""; // Handles null or undefined choice
          const firstName = choice.firstName || "";
          const lastName = choice.lastName || "";
          return `${firstName} ${lastName}`.trim();
        }}
      />
    </ReferenceInput>
    <SelectInput
      source="type"
      reference="RewardsTransactions"
      label="Reward Type"
      choices={transactionTypesChoices}
    />
  </Filter>
);

const RewardsTransactionList = (props) => (
  <List
    aside={<div />}
    filters={<AddFilterDropdown />}
    sort={{ field: "updatedAt", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show">
      <ReferenceField label="User" source="userId" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <TextField source="type" />
      <NumberField source="amount" />
      <TextField source="notes" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
export default RewardsTransactionList;
