import React from "react";
import { Box, Typography, useTheme, Grid, Stack } from "@mui/material";
import { useStyles } from "./styles";
import { BaseElementProps } from "../../../../components/types";

export const CallToActionBox = ({
  children,
  title,
  icon,
  ...props
}: BaseElementProps & { title: string; icon?: React.ReactNode }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.box} {...props}>
      <Grid container>
        {icon && (
          <Grid item xs={3}>
            {icon}
          </Grid>
        )}
        <Grid item xs={icon ? 9 : 12}>
          <Stack spacing={2}>
            <Typography sx={styles.title}>{title}</Typography>
            {children}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
