import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DiscussionDto } from '@keyops-hcp/dtos';
import { getAdBoardGeneralDiscussion } from '../api/adBoardDiscussions';

export const useOneDiscussionByAdBoardId = (
  adBoardId: string
): UseQueryResult<DiscussionDto, Error> => {
  return useQuery({
    queryKey: ['getAdBoardGeneralDiscussion', adBoardId],
    queryFn: () => getAdBoardGeneralDiscussion(adBoardId),
    enabled: !!adBoardId,
    refetchInterval: 6000, // TODO: increase this number depending on eTag
  });
};
