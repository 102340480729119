import React from 'react';
import { Typography, useTheme } from '@mui/material';
import i18next from '../../../../languages/i18n.config';
import { Trans } from 'react-i18next';
import { CallToActionBox } from '../CallToActionBox';
import { HighlightOffOutlined } from '@mui/icons-material/';

export const AdBoardDeclinedBox = () => {
  const theme = useTheme();
  const { t } = i18next;
  return (
    <CallToActionBox
      icon={
        <HighlightOffOutlined
          sx={{ fontSize: '61px', color: theme.palette.keyops.gray[500] }}
        />
      }
      title={t('ad_board.landing_page.cta.declined.title')}
    >
      <Typography>
        <Trans
          t={t}
          i18nKey='components:ad_board.landing_page.cta.declined.copy'
          components={{
            supportLink: <a href='mailto:physiciansupport@keyops.io' />,
          }}
        ></Trans>
      </Typography>
    </CallToActionBox>
  );
};
