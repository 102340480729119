import { RewardsTiersTypes } from "./reward-tiers.type";
export const EventTypes = [
  "SURVEY_INVITE",
  "SURVEY_INVITE_REMINDER",
  "SILVER_REWARD_TIER_ACHIEVED",
  "GOLD_REWARD_TIER_ACHIEVED",
  "PLATINUM_REWARD_TIER_ACHIEVED",
  "ADBOARD_INVITE_SENT",
] as const;
type EventTypeTuple = typeof EventTypes;
export type EventType = EventTypeTuple[number];

export const mapTierToEventType: Partial<Record<RewardsTiersTypes, EventType>> =
  {
    silver: "SILVER_REWARD_TIER_ACHIEVED",
    gold: "GOLD_REWARD_TIER_ACHIEVED",
    platinum: "PLATINUM_REWARD_TIER_ACHIEVED",
  };
