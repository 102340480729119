import { queryClient, useMutation } from '@keyops-hcp/ui-components';
import { deleteOneAdBoardSection } from '../api/adboard-sections-api';

export const useDeleteAdBoardSection = (notify) => {
  return useMutation<unknown, unknown, string>(
    async (sectionId) => {
      const updatedSection = await deleteOneAdBoardSection(sectionId);
      return updatedSection;
    },
    {
      // Options for the mutation
      onSuccess: async (updatedSection) => {
        if (updatedSection) {
          await queryClient.invalidateQueries({
            queryKey: ['getAdBoardById'],
          });
          // Refetch all sections
          await queryClient.invalidateQueries({
            queryKey: ['getAdBoardSections'],
          });
          notify('Section successfully deleted', { type: 'success' });
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to delete the section', { type: 'error' });
      },
    }
  );
};
