import React, { useEffect, useState } from 'react';
import { SectionDto } from '@keyops-hcp/dtos';
import { Container, Stack } from '@mui/material';
import SectionDeleteDialog from './SectionDeleteDialog';
import SectionCard from './SectionCard';
import { Draggable, DroppableProvided } from '@hello-pangea/dnd';
import { useDeleteAdBoardSection } from '../../../../custom-hooks/useDeleteAdBoardSection';
import { useNotify } from 'react-admin';

interface SectionsListProps {
  sections: SectionDto[];
  provided: DroppableProvided;
  onEditSection: (data: SectionDto) => void;
}

const SectionsList: React.FC<SectionsListProps> = ({
  sections,
  provided,
  onEditSection,
}) => {
  const notify = useNotify();
  const { mutate: deleteAdBoardSection } = useDeleteAdBoardSection(notify);
  const [sectionsList, setSectionsList] = useState(sections ?? []);

  const onDeleteSection = async (section: SectionDto) => {
    deleteAdBoardSection(section.id);
  };

  // IMPORTANT: Update sectionsList when sections is updated (happens on refetch)
  useEffect(() => {
    setSectionsList(sections);
  }, [sections]);

  const [showSectionDeleteDialog, setShowSectionDeleteDialog] = useState<
    SectionDto | false
  >(false);
  const openSectionDeleteDialog = (section: SectionDto) =>
    setShowSectionDeleteDialog(section);
  const closeSectionDeleteDialog = () => setShowSectionDeleteDialog(false);
  return (
    <Container maxWidth='md' sx={{ my: 4 }}>
      <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={2}>
        {sectionsList &&
          sections.map((section, index) => (
            <Draggable key={section.id} draggableId={section.id} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <SectionCard
                    section={section}
                    onEditSection={onEditSection}
                    onDeleteSection={onDeleteSection}
                    openSectionDeleteDialog={openSectionDeleteDialog}
                    dragHandleProps={provided.dragHandleProps}
                  />
                </div>
              )}
            </Draggable>
          ))}
        {provided.placeholder}
      </Stack>
      <SectionDeleteDialog
        open={showSectionDeleteDialog}
        handleClose={closeSectionDeleteDialog}
        onDeleteSection={onDeleteSection}
      />
    </Container>
  );
};

export default SectionsList;
