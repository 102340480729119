// External Dependencies
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Datagrid,
  TextField,
  EditButton,
  Show,
  TopToolbar,
  ReferenceField,
  RichTextField,
  BooleanField,
  ImageField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Button,
  SingleFieldList,
  ChipField,
  Pagination,
  NumberField,
  Confirm,
  DateField,
  useRecordContext,
} from "react-admin";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PublishIcon from "@mui/icons-material/Publish";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Internal Dependencies
import {
  publishEngagementMutation,
  closeEngagementMutation,
} from "../../../utils/graphql_lib";
import { EngagementFilesPreviewField } from "../../../components/engagement_files_preview_field";
import ShowInviteBreakout from "./ShowInviteBreakout";

// TODO: investigate on how this is used
const PostShowActions = ({ basePath, data }) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleAction = async () => {
    if (data?.state === "draft") {
      await publishEngagementMutation(data?.id);
      navigate("/Engagement");
    } else if (data?.state === "published") {
      await closeEngagementMutation(data?.id);
      navigate("/Engagement");
    }
  };
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      {data?.state === "draft" && (
        <>
          <Confirm
            title='Publish Engagement'
            content='Are you sure you want to publish this engagement?'
            confirm='Yes'
            confirmColor='primary'
            cancel='Cancel'
            onConfirm={handleAction}
            onClose={() => setOpen(false)}
            isOpen={open}
          />
          <Button
            basePath={basePath}
            to={"/admin"}
            record={data}
            label={"Publish"}
            onClick={() => setOpen(true)}
          >
            <PublishIcon />
          </Button>
        </>
      )}
      {data?.state === "published" && (
        <>
          <Confirm
            title='Mark Engagement as Ended'
            content='Are you sure you want to end this engagement?'
            confirm='Yes'
            confirmColor='primary'
            cancel='Cancel'
            onConfirm={handleAction}
            onClose={() => setOpen(false)}
            isOpen={open}
          />
          <Button
            basePath={basePath}
            to={"/admin"}
            record={data}
            aria-label={"Close"}
            label={"Mark as ended"}
            onClick={() => setOpen(true)}
          >
            <HighlightOffIcon />
          </Button>
        </>
      )}
    </TopToolbar>
  );
};

const AddNewInvitationButton = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: "/Invitation/create",
        state: { engagementId: record.id },
      }}
      label='Add an invitation'
    >
      <ChatBubbleIcon />
    </Button>
  );
};

const FilteredInviteListButton = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: "/Invitation",
        search: `filter=${JSON.stringify({
          engagementId: record.id,
        })}`,
      }}
    >
      See full invite list
    </Button>
  );
};

const EngagementShow = (props) => {
  return (
    <Show actions={<PostShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label='Info'>
          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Main details
          </h4>
          <TextField source='title' />
          <RichTextField source='summary' />
          <TextField source='state' />
          <ReferenceField
            label='Company'
            source='companyId'
            reference='Company'
          >
            <TextField source='name' />
          </ReferenceField>
          <BooleanField
            source='isBlinded'
            TrueIcon={VisibilityOffIcon}
            FalseIcon={VisibilityIcon}
          />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Survey Sparrow details
          </h4>
          <TextField source='link' />
          <TextField source='surveySparrowId' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Eligibility
          </h4>
          <DateField source='endsAt' />
          <TextField source='hcpType' />
          <TextField source='allowedSpecialties' />
          <BooleanField source='hasOtherEligibilityCriteria' />
          <RichTextField source='otherEligibilityCriteria' />

          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Effort & Payout
          </h4>
          <TextField source='estimatedTime' />
          <NumberField source='payoutValue' />
          <NumberField source='rewardValue' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Referrals
          </h4>
          <NumberField source='referralPayout' />
          <BooleanField source='referralEnabled' />
          <NumberField source='referralExpiration' />
          <NumberField source='referralRewardValue' />
          <h4
            style={{
              marginBottom: 0,
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            Results and Media
          </h4>
          <TextField source='resultsLink' />
          <RichTextField source='mediaDescription' />
          <TextField source='attachmentLink' />
        </Tab>
        <Tab label='Files'>
          <EngagementFilesPreviewField source={"EngagementFiles"} />
          <ReferenceManyField
            label='Documents'
            reference='EngagementFile'
            target='engagementId'
            sort={{ field: "fileOrder", order: "ASC" }}
          >
            <SingleFieldList>
              <ImageField source='link' />
            </SingleFieldList>
          </ReferenceManyField>
        </Tab>
        <Tab label='Invitations'>
          <FilteredInviteListButton />
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={50}
            reference='Invitation'
            target='engagementId'
            addLabel={false}
            filter={{ type: "invitation" }}
          >
            <Datagrid>
              {/* first name */}
              <ReferenceField
                label='first name'
                source='userId'
                reference='Users'
              >
                <TextField source='firstName' />
              </ReferenceField>
              {/* last name */}
              <ReferenceField
                label='last name'
                source='userId'
                reference='Users'
              >
                <TextField source='lastName' />
              </ReferenceField>
              {/* email */}
              <ReferenceField label='Email' source='userId' reference='Users'>
                <TextField source='email' />
              </ReferenceField>
              {/* Engagement title */}
              <ReferenceField
                label='Engagement'
                source='engagementId'
                reference='Engagement'
              >
                <TextField source='title' />
              </ReferenceField>
              {/* Engagement Payout Value */}
              <ReferenceField
                label='Payout Value'
                source='engagementId'
                reference='Engagement'
              >
                <TextField source='payoutValue' />
              </ReferenceField>
              {/* Company Name */}
              <ReferenceField
                label='Company'
                source='companyId'
                reference='Company'
              >
                <TextField source='name' />
              </ReferenceField>
              {/* Invitation State */}
              <ChipField source='state' />
              {/* Updated At field */}
              <DateField source='updatedAt' showTime />
            </Datagrid>
          </ReferenceManyField>
          <AddNewInvitationButton />
        </Tab>
        <Tab label='Invitation Breakout'>
          <ShowInviteBreakout />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default EngagementShow;
