import React, { useCallback, useState } from 'react';
import { Popover } from '@mui/material';

import { NotificationsRenderer } from '../NotificationsRenderer';
import { DisplayNotification } from '../../utils/notifications-utils';

interface NotificationsPopoverProps {
  notificationAnchor: HTMLButtonElement | null;
  isOpen: boolean;
  handleClose: () => void;
  loadingNotifications: boolean;
  notificationsError: string | null;
  notificationsList: DisplayNotification[];
}

export const NotificationsPopover = ({
  notificationAnchor,
  isOpen,
  handleClose,
  loadingNotifications,
  notificationsError,
  notificationsList,
}: NotificationsPopoverProps) => {
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const toggleShowAllNotifications = useCallback((open: boolean) => {
    setShowAllNotifications(open);
  }, []);

  const handleClosePopover = useCallback(() => {
    handleClose();
    toggleShowAllNotifications(false);
  }, [handleClose]);

  return (
    <Popover
      id={'notifications-popover'}
      data-testid={'notifications-popover'}
      open={isOpen}
      anchorEl={notificationAnchor}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={2}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 2.5,
            boxShadow:
              '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);',
          },
        },
      }}
    >
      <NotificationsRenderer
        handleClose={handleClose}
        notificationsList={notificationsList}
        loadingNotifications={loadingNotifications}
        notificationsError={notificationsError}
        showAllNotifications={showAllNotifications}
        toggleShowAllNotifications={toggleShowAllNotifications}
      />
    </Popover>
  );
};
