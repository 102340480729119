// External Dependencies
import React from 'react';

import {
  TextField,
  ReferenceField,
  Show,
  FunctionField,
  SimpleShowLayout,
  DateField,
  Link,
  TopToolbar,
  EditButton,
} from 'react-admin';

const InvitationShowActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const InvitationShow = (props) => (
  <Show actions={<InvitationShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label='User' source='userId' reference='Users'>
        <TextField source='firstName' />
      </ReferenceField>

      <ReferenceField
        label='Company'
        source='engagementId'
        reference='Engagement'
        link={false}
      >
        <FunctionField
          label='Company'
          render={(record) => {
            const companyId = record.companyId;
            return (
              <Link to={`/Company/${companyId}/show`}>
                <ReferenceField
                  source='companyId'
                  reference='Company'
                  record={record}
                  basePath='/Company'
                  link={false}
                >
                  <TextField source='name' />
                </ReferenceField>
              </Link>
            );
          }}
        />
      </ReferenceField>
      <ReferenceField
        label='Engagement'
        source='engagementId'
        reference='Engagement'
      >
        <TextField source='title' />
      </ReferenceField>
      <TextField source='state' />
      <ReferenceField label='Admin' source='createdBy' reference='Admins'>
        <TextField source='name' />
      </ReferenceField>
      <DateField label='Completed At' source='completedAt' />
      <TextField source='surveyUniqueLink' />
    </SimpleShowLayout>
  </Show>
);

export default InvitationShow;
