import { gql } from "@apollo/client";
import apolloClient from "./apolloClient";

export const VERIFY_REFRESH_TOKEN = gql`
  query verifyRefreshToken($token: String) {
    verifyRefreshToken(token: $token) {
      token
    }
  }
`;

export const EARNINGS = gql`
  query earningsPage {
    earningsPage {
      total
      paid
      count
      engagements
    }
  }
`;

export const GET_PROFILE = gql`
  query profilePage {
    profilePage {
      id
      firstName
      lastName
      email
      procedureSetting
      specialty
      provinceOfPractice
      practiceSetting
    }
  }
`;

export const GET_PROFILE_FULL = gql`
  query profilePage {
    profilePage {
      id
      email
      firstName
      lastName
      enabled
      createdAt
      emptyPassword
      state
      yearOfBirth
      gender
      specialty
      procedureSetting
      practiceSetting
      involvementTrials
      involvementCommittee
      workEmail
      smsNotifications
      practiceLicenseYear
      licenseNumber
      ethnicity
      termsOfService
      mobileNumber
      subSpecialty
      provinceOfPractice
      cityOfPractice
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile(
    $id: ID
    $yearOfBirth: String
    $gender: String
    $specialty: [String]
    $provinceOfPractice: String
    $procedureSetting: String
    $practiceSetting: String
    $workEmail: String
    $smsNotifications: Boolean
    $practiceLicenseYear: Int
    $licenseNumber: Int
    $firstName: String
    $lastName: String
    $country: String
    $ethnicity: [String]
    $email: String
    $termsOfService: Boolean
    $mobileNumber: Int
    $subSpecialty: [String]
    $provinceOfPractice: [String]
    $cityOfPractice: String
  ) {
    updateUserProfile(
      id: $id
      yearOfBirth: $yearOfBirth
      gender: $gender
      specialty: $specialty
      procedureSetting: $procedureSetting
      practiceSetting: $practiceSetting
      workEmail: $workEmail
      smsNotifications: $smsNotifications
      practiceLicenseYear: $practiceLicenseYear
      licenseNumber: $licenseNumber
      firstName: $firstName
      lastName: $lastName
      country: $country
      ethnicity: $ethnicity
      email: $email
      termsOfService: $termsOfService
      mobileNumber: $mobileNumber
      subSpecialty: $subSpecialty
      provinceOfPractice: $provinceOfPractice
      cityOfPractice: $cityOfPractice
    ) {
      id
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback($text: String, $email: String) {
    submitFeedback(text: $text, email: $email) {
      text
      email
    }
  }
`;
export const CREATE_PASSWORD = gql`
  mutation createPassword($password: String, $token: Int) {
    createPassword(password: $password, token: $token) {
      password
      token
    }
  }
`;

export const ADMIN_RESET_USER_PASSWORD = gql`
  mutation adminResetUserPassword($email: String, $password: String) {
    adminResetUserPassword(email: $email, password: $password) {
      email
      password
    }
  }
`;

export const EMAIL_VERIFICATION = gql`
  mutation emailVerification(
    $uuid: String
    $status: Boolean
    $message: String
  ) {
    emailVerification(uuid: $uuid, status: $status, message: $message) {
      uuid
      status
      message
    }
  }
`;

// TODO: is it used?
export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const PUBLISH_ENGAGEMENT = gql`
  mutation publishEngagement($id: ID!) {
    publishEngagement(id: $id) {
      id
    }
  }
`;

export const CLOSE_ENGAGEMENT = gql`
  mutation closeEngagement($id: ID!) {
    closeEngagement(id: $id) {
      id
    }
  }
`;

export const GET_ENGAGEMENT_FILE = gql`
  mutation EngagementFile($id: ID!) {
    EngagementFile(id: $id) {
      id
      link
      fileOrder
    }
  }
`;

export const GET_ENGAGEMENTS = gql`
  query GetMyEngagements($type: String) {
    GetMyEngagements(type: $type) {
      token
      state
      engagement {
        title
        payoutValue
        state
        type
        summary
        company {
          name
        }
        createdAt
        isBlinded
        estimatedTime
        publishedAt
        endsAt
        resultsLink
      }
    }
  }
`;

export const CREATE_PHYSICIANS_BULK = gql`
  mutation createPhysiciansBulk($csvData: String) {
    createPhysiciansBulk(csvData: $csvData) {
      csvData
    }
  }
`;
export const CREATE_PHARMACISTS_BULK = gql`
  mutation createPharmacistsBulk($csvData: String) {
    createPharmacistsBulk(csvData: $csvData) {
      csvData
    }
  }
`;
export const CREATE_NURSES_BULK = gql`
  mutation createNursesBulk($csvData: String) {
    createNursesBulk(csvData: $csvData) {
      csvData
    }
  }
`;
export const CREATE_REWARDS_TRANSACTIONS_BULK = gql`
  mutation createRewardsTransactionsBulk($csvData: String) {
    createRewardsTransactionsBulk(csvData: $csvData) {
      csvData
    }
  }
`;
export const CREATE_OUT_OF_NETWORK_USERS = gql`
  mutation createOutOfNetworkUsersBulk($csvData: String) {
    createOutOfNetworkUsersBulk(csvData: $csvData) {
      csvData
    }
  }
`;

export const CREATE_INVITATION_BULK = gql`
  mutation createInvitationBulk($csvData: String) {
    createInvitationBulk(csvData: $csvData) {
      csvData
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID, $userId: ID, $amount: Int, $state: String) {
    updatePayment(id: $id, userId: $userId, amount: $amount, state: $state) {
      id
    }
  }
`;

export const UPDATE_PAYMENT_BULK = gql`
  mutation updatePaymentBulk($id: [ID], $state: String) {
    updatePaymentBulk(id: $id, state: $state) {
      id
    }
  }
`;

export const SEND_REMINDER_EMAIL = gql`
  mutation sendReminderEmail($ids: [ID], $emailId: String) {
    sendReminderEmail(ids: $ids, emailId: $emailId) {
      id
    }
  }
`;

// use a similar function for bulk approval
export const onClickBulkUpdatePayments = async (id, state) => {
  return await apolloClient
    .mutate({
      mutation: UPDATE_PAYMENT_BULK,
      variables: { id: id, state: state },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject();
    });
};

export const sendReminderEmail = async (ids, emailId) => {
  return await apolloClient
    .mutate({
      mutation: SEND_REMINDER_EMAIL,
      variables: { ids, emailId },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

export const getEngagementFile = (id) => {
  return apolloClient
    .query({
      query: GET_ENGAGEMENT_FILE,
      variables: { id: parseInt(id) },
    })
    .then(({ data }) => {
      const { EngagementFile } = data;
      return EngagementFile;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error

      return Promise.reject();
    });
};

// TODO: how is it used?
export const publishEngagementMutation = (id) => {
  if (!id) return;
  return apolloClient
    .mutate({
      mutation: PUBLISH_ENGAGEMENT,
      variables: { id },
    })
    .then(({ data }) => {
      const { publishEngagement } = data;
      return publishEngagement;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const closeEngagementMutation = (id) => {
  if (!id) return;
  return apolloClient
    .mutate({
      mutation: CLOSE_ENGAGEMENT,
      variables: { id },
    })
    .then(({ data }) => {
      const { closeEngagement } = data;
      return closeEngagement;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const createPhysiciansBulkMutation = (csvData) => {
  if (!csvData) return;
  return apolloClient
    .mutate({
      mutation: CREATE_PHYSICIANS_BULK,
      variables: { csvData },
    })
    .then(({ data }) => {
      return data.createPhysiciansBulk.csvData;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const createPharmacistsBulkMutation = (csvData) => {
  if (!csvData) return;
  return apolloClient
    .mutate({
      mutation: CREATE_PHARMACISTS_BULK,
      variables: { csvData },
    })
    .then(({ data }) => {
      return data.createPharmacistsBulk.csvData;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const createNursesBulkMutation = (csvData) => {
  if (!csvData) return;
  return apolloClient
    .mutate({
      mutation: CREATE_NURSES_BULK,
      variables: { csvData },
    })
    .then(({ data }) => {
      return data.createNursesBulk.csvData;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const createRewardsTransactionsBulkMutation = (csvData) => {
  if (!csvData) return;
  return apolloClient
    .mutate({
      mutation: CREATE_REWARDS_TRANSACTIONS_BULK,
      variables: { csvData },
    })
    .then(({ data }) => {
      return data.createRewardsTransactionsBulk.csvData;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};

export const createInvitationBulkMutation = (csvData) => {
  if (!csvData) return;
  return apolloClient
    .mutate({
      mutation: CREATE_INVITATION_BULK,
      variables: { csvData },
    })
    .then(({ data }) => {
      return data.createInvitationBulk.csvData;
    })
    .catch((error) => {
      // TODO: should we use the error?
      console.error(error); // Fix TS error
      return Promise.reject();
    });
};
