import { VideoCommentDto } from "@keyops-hcp/dtos";
import { axiosCall } from "../utils/api";
import { VIDEO_COMMENTS_ENDPOINT } from "../utils/api-routes";
import i18n from "../languages/i18n.config";

export const getCommentsForVideo = async (
  videoId: string
): Promise<VideoCommentDto[]> => {
  try {
    const results = await axiosCall(
      false,
      "get",
      VIDEO_COMMENTS_ENDPOINT,
      undefined,
      { videoId }
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      results?.data?.message || i18n.t("apiErrorMessages.no_video_found")
    );
  } catch (error) {
    console.error("Error fetching data getOneVideo:", error);
    throw new Error(
      error?.response?.data?.message ||
        i18n.t("apiErrorMessages.no_video_found")
    );
  }
};

export const addCommentForVideo = async ({
  videoId,
  text,
  timestamp,
}: {
  videoId: string;
  text: string;
  timestamp: number;
}) => {
  try {
    const results = await axiosCall(
      false,
      "post",
      VIDEO_COMMENTS_ENDPOINT,
      undefined,
      undefined,
      { videoId, text, timestamp }
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      results?.data?.message || i18n.t("apiErrorMessages.something_wrong")
    );
  } catch (error) {
    console.error("Error adding comment:", error);
    throw new Error(
      error?.response?.data?.message ||
        i18n.t("apiErrorMessages.something_wrong")
    );
  }
};
