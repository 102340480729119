import axios from 'axios';
import { DiscussionDto } from '@keyops-hcp/dtos';

import { getAPIUrl } from '../utils/env';

const apiUrl = getAPIUrl();

// Specific to admin capabilities, do not put in ui-components
export const createOneDiscussion = async (
  discussionToCreate: Partial<DiscussionDto>
): Promise<DiscussionDto> => {
  try {
    const response = await axios.post(
      `${apiUrl}/discussions`,
      discussionToCreate,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating one discussion', error);
    throw error;
  }
};
