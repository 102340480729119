import React from 'react';
import { Box, Typography } from '@mui/material';

import { DiscussionDto } from '@keyops-hcp/dtos';

import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { DiscussionChatHeader } from '../DiscussionChatHeader';
import i18next from '../../languages/i18n.config';

interface DiscussionChatProps {
  discussion: DiscussionDto;
}

export const DiscussionChat = ({
  discussion,
}: DiscussionChatProps): JSX.Element => {
  return (
    <Box display='flex' flexDirection='column' height='80vh' p={`8px 58px`}>
      {/* Only display the header for section's discussions */}
      {discussion.topic !==
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') && (
        <DiscussionChatHeader discussion={discussion} />
      )}

      <DiscussionChatMessages discussion={discussion} />

      {/* Display a helper message for general discussion*/}
      {discussion.topic ===
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') && (
        <Typography
          textAlign='center'
          fontSize={`16px`}
          color={'keyops.gray.600'}
          pb='8px'
        >
          {i18next.t(
            'ad_board.discussionsDrawer.generalDiscussion.helperMessage'
          )}
        </Typography>
      )}
      <DiscussionMessageForm discussionId={discussion.id} />
      {/* TODO: add "Next topic button" once multi topics in section handled */}
    </Box>
  );
};
