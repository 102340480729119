import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { getOneAdBoardInviteByAdBoardId } from '../api/adBoardInvites';

export const useAdBoardInviteByAdBoardId = (
  adBoardId: string
): UseQueryResult<AdBoardInviteDto, Error> => {
  return useQuery({
    queryKey: ['getOneAdBoardInviteByAdBoardId', adBoardId],
    queryFn: () => getOneAdBoardInviteByAdBoardId(adBoardId),
    enabled: !!adBoardId,
  });
};
