import { AdBoardDto } from '@keyops-hcp/dtos';
import { t } from 'i18next';

import { axiosCall } from '../utils/api';
import { AD_BOARDS_ENDPOINT } from '../utils/api-routes';

export const getOneAdBoard = async (id: string): Promise<AdBoardDto> => {
  try {
    const response = await axiosCall(
      false,
      'get',
      AD_BOARDS_ENDPOINT + `/${id}`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      // TODO: add error code for adboard
      response?.data?.message || t('apiErrorMessages.no_ad_board_found')
    );
  } catch (error) {
    console.error('Error fetching data getOneAdBoard:', error);
    throw new Error(
      error?.response?.data?.message || t('apiErrorMessages.no_ad_board_found')
    );
  }
};

export const getParticipantDetails = async (adBoardId: string) => {
  try {
    const response = await axiosCall(
      false,
      'get',
      AD_BOARDS_ENDPOINT + `/${adBoardId}/user-participant`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(response?.data?.message);
  } catch (error) {
    console.error('Error fetching data getParticipantDetails:', error);
    throw new Error(error?.response?.data?.message);
  }
};
