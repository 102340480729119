import React from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { AdBoardLandingPageProps } from './AdBoardLandingPage.types';
import { formatDateWithSuffix } from '../../../utils/datetime';
import { HTMLOutput } from '../../HTMLOutput';
import { AdBoardInviteChoiceBox } from './AdBoardInviteChoiceBox';
import i18next from '../../../languages/i18n.config';
import { AdBoardAgenda } from './AdBoardAgenda';
import { useStyles } from './styles';
import { useAdBoardInviteByAdBoardId } from '../../../hooks';
import { AdBoardOpenBox } from './AdBoardOpenBox';
import { AdBoardDeclinedBox } from './AdBoardDeclined';
import { ContractPending } from './ContractPending';
import { AdBoardUpcomingBox } from './AdBoardUpcomingBox';

export const AdBoardLandingPage: React.FC<AdBoardLandingPageProps> = ({
  adBoardId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [view, setView] = React.useState('objectives');
  const {
    data: adBoardInvite,
    isLoading,
    error,
  } = useAdBoardInviteByAdBoardId(adBoardId);

  if (error) return <div>{i18next.t('common.error')}</div>;
  if (isLoading || !adBoardInvite || !adBoardInvite.adBoard) {
    return <div>{i18next.t('common.loading')}</div>;
  }

  const adBoard = adBoardInvite.adBoard;

  const sections = [
    {
      value: 'objectives',
      label: i18next.t('ad_board.landing_page.objectives'),
    },
    { value: 'agenda', label: i18next.t('ad_board.landing_page.agenda') },
    {
      value: 'moderators',
      label: i18next.t('ad_board.landing_page.moderators'),
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  //TODO: need to factor in adboard state at some point
  const ctaBox = (function () {
    switch (adBoardInvite.state) {
      case 'sent':
        return <AdBoardInviteChoiceBox adBoardId={adBoard.id} />;
      case 'accepted':
        return <ContractPending />;
      case 'contract-signed': {
        if (adBoard.state === 'live') {
          return (
            <AdBoardOpenBox endDate={adBoard.endDate} adBoardId={adBoard.id} />
          );
        }
        return <AdBoardUpcomingBox startDate={adBoard.startDate} />;
      }
      case 'declined':
        return <AdBoardDeclinedBox />;
      default:
        return <></>;
    }
  })();

  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.detailsContainer}>
          <Typography sx={styles.subTitle}>
            {i18next.t('ad_board.landing_page.company_name', {
              name: adBoard.company?.name,
            })}
          </Typography>
          <Typography sx={styles.title}>{adBoard.title}</Typography>
          <Typography sx={styles.date}>
            {adBoard.startDate !== null &&
              i18next.t('ad_board.landing_page.starts', {
                date: formatDateWithSuffix(adBoard.startDate),
              })}
            {adBoard.startDate !== null && adBoard.endDate !== null && ' | '}
            {adBoard.endDate !== null &&
              i18next.t('ad_board.landing_page.closes', {
                date: formatDateWithSuffix(adBoard.endDate),
              })}
          </Typography>
          <Typography sx={styles.description}>{adBoard.description}</Typography>
        </Box>
        {ctaBox}
      </Box>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.toggleButtonGroupBox}>
          <ToggleButtonGroup
            orientation='vertical'
            value={view}
            sx={styles.toggleButtonGroup}
            exclusive
            onChange={handleChange}
          >
            {sections.map((section) => (
              <Box key={section.value} sx={styles.toggleButton}>
                <ToggleButton
                  value={section.value}
                  disabled={view === section.value}
                  aria-label={section.label}
                >
                  <CircleIcon />
                </ToggleButton>
                <Typography
                  sx={styles.toggleButtonLabel(view === section.value)}
                >
                  {section.label}
                </Typography>
              </Box>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box sx={styles.content}>
          {view === 'objectives' && (
            <Typography>
              <HTMLOutput html={adBoard.objectives} />
            </Typography>
          )}
          {view === 'agenda' && <AdBoardAgenda adBoardId={adBoardId} />}
          {view === 'moderators' && (
            <Typography>
              <HTMLOutput html={adBoard.moderators} />
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
