// External Dependencies
import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  NumberField,
  SelectInput,
  AutocompleteInput,
  downloadCSV,
  DateField,
  DateTimeInput,
  BooleanField,
  BulkDeleteButton,
  useListContext,
  useNotify,
  SelectArrayInput,
} from "react-admin";

import { Button, Box, Typography } from "@mui/material";

import TextFieldMUI from "@mui/material/TextField";
import jsonExport from "jsonexport/dist";
import SendIcon from "@mui/icons-material/Send";

// Internal Dependencies
import { sendReminderEmail } from "../../../utils/graphql_lib.js";
import {
  InvitationStateChoices,
  SpecialtyChoices,
  StateAndProvinceChoices,
} from "../../../utils/choices.js";

// Populates the Add Filter Button
const AddFilterDropdown = (props) => (
  <Filter {...props}>
    <ReferenceInput
      perPage={1000}
      filterToQuery={(searchText) => ({
        name: [searchText],
      })}
      source="engagementId"
      reference="Engagement"
    >
      <AutocompleteInput label="title" source="title" optionText="title" />
    </ReferenceInput>

    <ReferenceInput source="companyId" reference="Company" label="Company">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <SelectArrayInput
      source="physicianSpecialty"
      label="Physician Specialty"
      choices={SpecialtyChoices}
    />

    <SelectArrayInput
      source="userProvince"
      label="Users State/Province"
      choices={StateAndProvinceChoices}
    />

    <ReferenceInput source="userId" reference="Users" label="Users Email">
      <AutocompleteInput source="email" optionText="email" />
    </ReferenceInput>

    <SelectInput
      source="state"
      reference="Invitation"
      label="Invitation State"
      choices={InvitationStateChoices}
    />

    <DateTimeInput
      source="starts_at"
      reference="Invitation"
      label="Start Date"
    />

    <DateTimeInput source="ends_at" reference="Invitation" label="End Date" />
  </Filter>
);

// Export functionality
const exporter = async (records, fetchRelatedRecords) => {
  const fetchUsers = (id) => {
    return users[id] ? users[id] : { firstName: "", lastName: "", email: "" };
  };

  const fetchEngagement = (id) => {
    return engagements[id] ? engagements[id] : { name: "" };
  };

  const users = await fetchRelatedRecords(records, "userId", "Users");
  const engagements = await fetchRelatedRecords(
    records,
    "engagementId",
    "Engagement"
  );
  const companyIds = Object.values(engagements).map(
    (engagement) => engagement.companyId
  );
  const uniqueCompanyIds = [...new Set(companyIds)]; // ensure there are no duplicate IDs
  const companies = await fetchRelatedRecords(
    uniqueCompanyIds.map((id) => ({ companyId: id })),
    "companyId",
    "Company"
  );

  const postsForExport = records.map((post) => {
    const companyIdForThisPost = engagements[post.engagementId]?.companyId;
    return {
      firstName: fetchUsers(post.userId).firstName,
      lastName: fetchUsers(post.userId).lastName,
      email: fetchUsers(post.userId).email,
      languagePreference: fetchUsers(post.userId).languagePreference,
      companyName: companies[companyIdForThisPost]?.name || "",
      engagementName: fetchEngagement(post.engagementId).title,
      payoutValue: fetchEngagement(post.engagementId).payoutValue,
      invitationState: post.state,
      created_at: post.createdAt,
      updated_at: post.updatedAt,
      completed_at: post.completedAt,
    };
  });

  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, "posts"); // download as 'posts.csv` file
  });
};

const BulkApprovalButton = () => {
  const { selectedIds, refetch } = useListContext();
  const notify = useNotify();

  const sendReminderEmails = async () => {
    let reminderTemplateId;

    try {
      reminderTemplateId = document.querySelector(
        "#reminder-email-template-id"
      ).value;
      let successMessage = "Success! Email(s) sent to Sendgrid";

      await sendReminderEmail(selectedIds, reminderTemplateId);

      refetch();
      notify(successMessage, {
        type: "success",
      });
      // TODO: add a global error handling
    } catch (error) {
      let errorMessage = error?.message;
      if (error?.message.includes(`The template_id must be a valid GUID`)) {
        errorMessage = `Error: wrong template-id. Please check the value you provided: ${
          reminderTemplateId === "" ? "an empty value" : reminderTemplateId
        }`;
      }

      notify(errorMessage, {
        type: "error",
      });
    }
  };

  return (
    <Button
      variant="contained"
      label="bulk approve"
      onClick={() => {
        sendReminderEmails();
      }}
    >
      <SendIcon />
      Send Reminder Email
    </Button>
  );
};

const BulkActionButtons = (props) => (
  <React.Fragment>
    <Box sx={{ width: "670px", marginRight: "10px" }}>
      <Typography>
        This button will send an email to remind all selected users that they
        have been invited.
      </Typography>
      <Typography>
        If they have already completed this engagement, the email will not be
        sent.
      </Typography>
    </Box>
    <Box sx={{ marginTop: "-9px" }}>
      <TextFieldMUI
        id="reminder-email-template-id"
        label="Reminder Email Override"
      />
    </Box>
    <BulkApprovalButton />
    <BulkDeleteButton
      confirmTitle={`Delete all the selected items?`}
      confirmContent={"Are you sure you want to delete these items?"}
      undoable={false}
      {...props}
    />
  </React.Fragment>
);

const InvitationList = (props) => (
  <List
    filter={{ type: "invitation" }}
    exporter={exporter}
    filters={<AddFilterDropdown />}
    perPage={25}
    sort={{ field: "updatedAt", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
      {/* Invitation Id */}
      <TextField source="id" />
      {/* Users Name */}
      <ReferenceField label="User" source="userId" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      {/* Users Email */}
      <ReferenceField label="Email" source="userId" reference="Users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Users State" source="userId" reference="Users">
        <TextField source="state" />
      </ReferenceField>
      <ReferenceField label="Users Province" source="userId" reference="Users">
        <TextField source="provinceOfPractice" />
      </ReferenceField>
      <ReferenceField label="Users Specialty" source="userId" reference="Users">
        <TextField source="specialty" />
      </ReferenceField>
      {/* Users WorkEmail Verified */}
      <ReferenceField
        label="Work Email Verified"
        source="userId"
        reference="Users"
      >
        <BooleanField source="workEmailVerified" />
      </ReferenceField>
      {/* State title */}
      <ReferenceField
        label="Users language preference"
        source="userId"
        reference="Users"
      >
        <TextField source="languagePreference" />
      </ReferenceField>
      <TextField source="state" />
      {/* Engagement title */}
      <ReferenceField
        label="Engagement"
        source="engagementId"
        reference="Engagement"
      >
        <TextField source="title" />
      </ReferenceField>
      {/* Engagement Payout Value */}
      <ReferenceField
        label="Payout Value"
        source="engagementId"
        reference="Engagement"
      >
        <TextField source="payoutValue" />
      </ReferenceField>

      <ReferenceField
        label="Company"
        source="engagementId"
        reference="Engagement"
        link={false}
      >
        <FunctionField
          label="Company"
          render={(record) => {
            const companyId = record.companyId;
            return (
              <ReferenceField
                source="companyId"
                reference="Company"
                record={record}
                basePath="/Company"
                link={`/Company/${companyId}/show`}
              >
                <TextField source="name" />
              </ReferenceField>
            );
          }}
        />
      </ReferenceField>

      <ReferenceField
        label="Referral Payout"
        source="engagementId"
        reference="Engagement"
      >
        <NumberField source="referralPayout" />
      </ReferenceField>
      {/* Updated At field */}
      <DateField source="updatedAt" showTime />
      <DateField source="completedAt" showTime />
      <TextField source="surveyUniqueLink" />
    </Datagrid>
  </List>
);

export default InvitationList;
