import React from "react";
import { KeyOpsThemeProvider } from "@keyops-hcp/ui-components";

import SummarySection from "./engagement/SummarySection";
import EngagementDetailsSection from "./engagement/EngagementDetailsSection";

const EngagementTab = () => {
  return (
    <KeyOpsThemeProvider>
      <SummarySection />
      <EngagementDetailsSection />
    </KeyOpsThemeProvider>
  );
};

export default EngagementTab;
