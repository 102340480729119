import React from 'react';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';
import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { getSignedContractUrlByInviteId } from '../../../../api/adboard-invites-api';

export const DownloadSignedContract = () => {
  const record = useRecordContext<AdBoardInviteDto>();
  const handleDownload = async () => {
    const linkWrapper = await getSignedContractUrlByInviteId(
      record.adBoardId,
      record.id
    );
    if (linkWrapper) {
      window.open(linkWrapper.signedUrl, '_blank', 'noreferrer');
    }
  };
  if (record.state !== 'contract-signed') {
    return <></>;
  }
  return (
    <IconButton onClick={handleDownload} title='Download Signed Contract'>
      <Download />
    </IconButton>
  );
};
