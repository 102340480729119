import { Box, Card, Container, Typography } from '@mui/material';
import React from 'react';
import { AddEditSectionGroup } from './AddEditSectionGroup';

const EmptyContentCard = () => (
  <Container maxWidth='md' sx={{ mt: 4 }}>
    <Card
      variant='outlined'
      sx={{
        py: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box textAlign='center'>
        <Typography variant='body1'>Create your content</Typography>
        <Typography color='#4A5568'>
          Start by adding your first section.
        </Typography>
      </Box>
      <AddEditSectionGroup />
    </Card>
  </Container>
);

export default EmptyContentCard;
