import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SensorsRounded } from '@mui/icons-material/';

import i18next from '../../../../languages/i18n.config';
import { CallToActionBox } from '../CallToActionBox';

export const AdBoardOpenBox = ({
  endDate,
  adBoardId,
}: {
  endDate: Date;
  adBoardId: string;
}) => {
  const { t } = i18next;
  const theme = useTheme();
  const navigate = useNavigate();

  const endDateDisplay = endDate
    ? new Date(endDate).toLocaleDateString(i18next.language, {
        month: 'short',
        day: 'numeric',
      })
    : '';

  const joinAdBoard = () => {
    navigate(`/adboard/${adBoardId}/sections`);
  };
  return (
    <CallToActionBox
      icon={
        <SensorsRounded
          sx={{ color: theme.palette.keyops.teal, fontSize: '61px' }}
        />
      }
      title={t('ad_board.landing_page.cta.open.title', {
        endDate: endDateDisplay,
      })}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Button variant='contained' onClick={joinAdBoard}>
          {t('ad_board.landing_page.cta.open.joinNow')}
        </Button>
      </Box>
    </CallToActionBox>
  );
};
