import { useMutation } from '@tanstack/react-query';
import { DiscussionMessageDto } from '@keyops-hcp/dtos';

import { createOneDiscussionMessage } from '../api/discussionMessages';
import { queryClient } from '../wrappers';

/**
 *
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const usePostDiscussionMessage = () => {
  return useMutation<
    DiscussionMessageDto,
    unknown,
    { message: string; discussionId: string }
  >(
    async ({ message, discussionId }): Promise<DiscussionMessageDto> => {
      const createdMessage = await createOneDiscussionMessage(
        message,
        discussionId
      );

      return createdMessage;
    },
    {
      onSuccess: () => {
        // Refetch the discussions query
        queryClient.invalidateQueries({
          queryKey: ['getAdBoardCurrentDiscussions'],
        });
      },
      onError: (error) => {
        // console.error(error);
        // notify('Failed to create the document', { type: 'error' });
      },
    }
  );
};
