import { useState, useEffect } from 'react';
import { VideoCommentDto } from '@keyops-hcp/dtos';

import { getCommentsForVideo } from '../api/videoComments';

export const useVideoComments = (videoId: string) => {
  const [videoComments, setVideoComments] = useState<VideoCommentDto[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // TODO: consider using tanstack-query here, which provide cleaner solution for refetching the data
  const [refetchCommentsFlag, setRefetchCommentsFlag] = useState<boolean>(true);
  const refetchComments = () => setRefetchCommentsFlag((prev) => !prev);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const data = await getCommentsForVideo(videoId);
        setVideoComments(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [videoId, refetchCommentsFlag]);

  return { videoComments, refetchComments, loading, error };
};
