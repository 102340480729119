// External Dependencies
import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  DateTimeInput,
  BooleanField,
} from "react-admin";
import { EventTypeChoices } from "../../../utils/choices";

// Internal Dependencies

// Populates the Add Filter Button
const AddFilterDropdown = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="recipientUserId"
      reference="Users"
      label="User Name"
    >
      <AutocompleteInput
        source="id"
        optionText={(choice) => {
          if (!choice) return ""; // Handles null or undefined choice
          const firstName = choice.firstName || "";
          const lastName = choice.lastName || "";
          return `${firstName} ${lastName}`.trim();
        }}
      />
    </ReferenceInput>
    <SelectInput source="eventType" choices={EventTypeChoices} />
    <DateTimeInput source="starts_at" label="Start Date" />
    <DateTimeInput source="ends_at" label="End Date" />
  </Filter>
);

const dateOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const NotificationsList = (props) => {
  return (
    <List
      sort={{ field: "updatedAt", order: "DESC" }}
      filters={<AddFilterDropdown />}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          label="Recipient User"
          source="recipientUserId"
          reference="Users"
        >
          <FunctionField
            label="Name"
            render={(record) =>
              `${record.firstName} ${record.lastName} (${record.id})`
            }
          />
        </ReferenceField>
        <TextField source="eventType" />
        <BooleanField source="read" />
        <DateField
          label="Created At"
          source="createdAt"
          showTime={true}
          options={dateOptions}
        />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};
export default NotificationsList;
