// External Dependencies
import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";

// Internal Dependencies

const FeatureSwitchesList = (props) => (
  <List sort={{ field: "updatedAt", order: "DESC" }} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="enabled" />
    </Datagrid>
  </List>
);
export default FeatureSwitchesList;
