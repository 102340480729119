import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ImageField,
} from "react-admin";

const EngagementFilesList = (props) => (
  <List exporter={false} {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        label="Engagement"
        source="engagementId"
        reference="Engagement"
      >
        <TextField source="title" />
      </ReferenceField>
      <ImageField source="link" />
    </Datagrid>
  </List>
);

export default EngagementFilesList;
