import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { AttachmentDto } from "@keyops-hcp/dtos";

import { CreateAttachmentForm } from "./CreateAttachmentForm";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "20px",
  backgroundColor: "white",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  zIndex: 1000,
  width: "300px",
  borderRadius: "8px",
} as const;

export const CreateAttachmentModal = ({
  open,
  setIsModalOpen,
  setErrorMessage,
  setSuccessMessage,
  setIsToastOpen,
  setAllAttachments,
}) => {
  const [attachment, setAttachment] = useState<AttachmentDto | undefined>();

  return (
    <Modal
      open={open}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Upload an attachment
          <CreateAttachmentForm
            attachment={attachment}
            setAttachment={setAttachment}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setIsModalOpen={setIsModalOpen}
            setIsToastOpen={setIsToastOpen}
            setAllAttachments={setAllAttachments}
          />
        </Typography>
      </Box>
    </Modal>
  );
};
