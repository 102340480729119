import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface MenuOption {
  component: React.ReactNode;
}

interface EditSectionMenuProps {
  menuOptions: MenuOption[];
}

export const EditSectionMenu: React.FC<EditSectionMenuProps> = ({
  menuOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='more-options'
        aria-controls={open ? "more-options-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup='true'
        onClick={handleMenuButtonClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='more-options-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuOptions.map((option, i) => (
          <MenuItem key={i}>{option.component}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
