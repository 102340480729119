import {
  AdBoardInviteCreationDto,
  AdBoardInviteDto,
  AdBoardInviteSignedContractDto,
} from '@keyops-hcp/dtos';
import { AD_BOARDS } from '../utils/api-endpoints.const';
import axios from 'axios';

export const getInvitesByAdBoardId = async (
  adBoardId: string
): Promise<AdBoardInviteDto[]> => {
  try {
    const response = await axios.get(`${AD_BOARDS}/${adBoardId}/invites`, {
      headers: {
        keyops__accesstokens: localStorage.getItem('token'),
        userType: 'admin',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getInvitesByAdBoardId:', error);
    throw error;
  }
};

export const createOneAdBoardInvite = async (
  data: Partial<AdBoardInviteCreationDto>
): Promise<AdBoardInviteDto[]> => {
  try {
    const response = await axios.post(
      `${AD_BOARDS}/${data.adBoardId}/invites`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Invite:', error);
    throw error;
  }
};

export const updateOneAdBoardInvite = async (
  data: Partial<AdBoardInviteDto>
): Promise<AdBoardInviteDto> => {
  try {
    const response = await axios.patch(
      `${AD_BOARDS}/${data.adBoardId}/invites/${data.id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error updating AdBoard Invite:', error);
    throw error;
  }
};
export const deleteOneAdBoardInvite = async (
  adBoardId: string,
  id: string
): Promise<void> => {
  try {
    const response = await axios.delete(
      `${AD_BOARDS}/${adBoardId}/invites/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error deleting AdBoard Invite:', error);
    throw error;
  }
};

export const sendAdBoardInvites = async (
  adBoardId: string,
  data: string[]
): Promise<AdBoardInviteDto[]> => {
  try {
    const response = await axios.post(
      `${AD_BOARDS}/${adBoardId}/invites/send`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating new AdBoard Invite:', error);
    throw error;
  }
};

export const getSignedContractUrlByInviteId = async (
  adBoardId: string,
  inviteId: string
): Promise<AdBoardInviteSignedContractDto> => {
  try {
    const response = await axios.get(
      `${AD_BOARDS}/${adBoardId}/invites/${inviteId}/signed-contract`,
      {
        headers: {
          keyops__accesstokens: localStorage.getItem('token'),
          userType: 'admin',
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data getInvitesByAdBoardId:', error);
    throw error;
  }
};
