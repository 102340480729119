import React from 'react';

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  TextField,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { DiscussionDto } from '@keyops-hcp/dtos';
import { UseMutateFunction } from '@keyops-hcp/ui-components';
import { Loading } from 'react-admin';

type DiscussionFormType = {
  discussion?: Partial<DiscussionDto>;
  createDiscussionSection: UseMutateFunction<
    DiscussionDto,
    unknown,
    {
      discussionToSave: Partial<DiscussionDto>;
    },
    unknown
  >;
  isLoading: boolean;
};

// Use to create and edit an Attachment
export const DiscussionForm = ({
  discussion,
  createDiscussionSection,
  isLoading,
}: DiscussionFormType) => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Partial<DiscussionDto>>({
    defaultValues: {
      topic: discussion?.topic,
    },
  });

  // Handle creating and editing the attachment
  const handleSaveDiscussion: SubmitHandler<Partial<DiscussionDto>> = async (
    discussionToSave
  ) => {
    try {
      // If there is no discussionId, then it's a creation
      if (!discussion?.id) {
        createDiscussionSection({ discussionToSave });
        reset();
      }
      // Otherwise, it's an update
      // TODO: how do we want to update this?
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <FormGroup>
          <FormControl margin='none'>
            {/* Topics */}
            {/* TODO: add infobulle */}
            {/* TODO: add trash icon if topic filled */}
            <TextField
              id='discussion-topic-input'
              aria-describedby='discussion-topic-input'
              variant='outlined'
              fullWidth
              {...register('topic', { required: true })}
              //   sx={{ paddingBottom: `8px` }}
            />
            {errors.topic && (
              <FormHelperText>{errors.topic.message}</FormHelperText>
            )}
          </FormControl>

          <Button
            type='submit'
            variant='contained'
            size='small'
            sx={{
              marginTop: '8px',
              width: 'fit-content',
              alignSelf: 'flex-end',
            }}
            onClick={handleSubmit(handleSaveDiscussion)}
          >
            Save
          </Button>
        </FormGroup>
      )}
    </>
  );
};
