import React from "react";

import {
  Button,
  Link,
  Loading,
  useGetList,
  useRecordContext,
} from "react-admin";
import { styled } from "@mui/material/styles";
import { Check, EditNoteOutlined, FiberNewOutlined } from "@mui/icons-material";
import { Switch, FormControlLabel } from "@mui/material";
import { Fragment, useState } from "react";
import { States } from "@keyops-hcp/constants";

// styles
const Table = styled("table")({
  border: "1px solid #ccc",
  borderCollapse: "collapse",
});
const Cell = styled("td")({
  border: "1px solid #ccc",
  borderCollapse: "collapse",
  padding: 4,
  textAlign: "center",
});
const CompletedCell = styled("td")({
  border: "1px solid #ccc",
  borderRight: "1px solid #555",
  borderCollapse: "collapse",
  padding: 4,
  textAlign: "center",
  backgroundColor: "#cfd8",
});
const Header = styled("th")({
  border: "1px solid #ccc",
  borderRight: "1px solid #555",
  borderCollapse: "collapse",
  padding: 4,
});
const SubHeader = styled("th")({
  border: "1px solid #ccc",
  borderCollapse: "collapse",
  padding: 4,
});
const CompletedSubHeader = styled("th")({
  border: "1px solid #ccc",
  borderRight: "1px solid #555",
  borderCollapse: "collapse",
  padding: 4,
  backgroundColor: "#cfd8",
});
const RegionHeader = styled("th")({
  border: "1px solid #ccc",
  borderRight: "1px solid #555",
  borderCollapse: "collapse",
  padding: 4,
  textWrap: "nowrap",
});

//utility stuff
const naturalCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

const TOTAL = "Total";
const EMPTY_DISPLAY = "[unspecified]";

/**
 * Returns the region for the province/state provided
 *
 * All of the US is amalgamated into the US region, and any unknown provinces/states
 * are grouped into a region called "[unspecified]"
 * @param {*} provinceOrState
 * @returns
 */
const getRegionName = (provinceOrState) => {
  if (States.includes(provinceOrState)) {
    return "US";
  }
  switch (provinceOrState) {
    case "BC":
    case "AB":
    case "MB":
    case "SK":
      return "CA - West";
    case "ON":
      return "CA - Ontario";
    case "QC":
      return "CA - Quebec";
    case "NL":
    case "NB":
    case "PE":
    case "NS":
      return "CA - Atlantic";
    case "NU":
    case "NT":
    case "YK":
      return "CA - Territories";
    default:
      return EMPTY_DISPLAY;
  }
};

const getProvinceFilterValue = (value) => {
  switch (value) {
    case "CA - West":
      return ["BC", "AB", "MB", "SK"];
    case "CA - Atlantic":
      return ["NL", "NB", "PE", "NS"];
    case "CA - Territories":
      return ["NU", "NT", "YK"];
    case "CA - Ontario":
      return ["ON"];
    case "CA - Quebec":
      return ["QC"];
    case "US":
      return States;
    default:
      return [value];
  }
};

const computeIfAbsent = (key, map, emptyValueSupplier) => {
  const valueFromMap = map.get(key);
  //below is a check for "not nullish".  We want to treat values of `false` and `0` as "found", so this check is nuanced
  if (valueFromMap != null) {
    return valueFromMap;
  }
  const newValue = emptyValueSupplier(key);
  map.set(key, newValue);
  return newValue;
};

/**
 * Expects a map of objects where the value has a name field.
 * This is what will be used for sorting.
 * @param {*} map
 */
const getAsArraySortedByName = (map) => {
  return Array.from(map.values()).sort((a, b) =>
    naturalCollator.compare(a.name, b.name)
  );
};

const sumCounts = (counts) => {
  return counts.reduce(
    (sums, count) => {
      sums.new += count.new;
      sums.inProgress += count.inProgress;
      sums.completed += count.completed;
      return sums;
    },
    { new: 0, inProgress: 0, completed: 0 }
  );
};

class ProvincePracticeSelectorCounts {
  constructor(province, practiceSelector) {
    this.province = province;
    this.practiceSelector = practiceSelector;
    this.new = 0;
    this.inProgress = 0;
    this.completed = 0;
    this.province.provincePracticeSelectorCounts.set(practiceSelector, this);
    this.practiceSelector.provincePracticeSelectorCounts.push(this);
  }

  increment(state) {
    switch (state) {
      case "new":
        this.new++;
        break;
      case "in_progress":
        this.inProgress++;
        break;
      case "completed":
        this.completed++;
        break;
    }
  }
}

class Province {
  constructor(name, region) {
    this.name = name;
    this.region = region;
    this.provincePracticeSelectorCounts = new Map();
    this.region.provinces.set(name, this);
  }

  getCountsForPracticeSelector(practiceSelector) {
    return computeIfAbsent(
      practiceSelector,
      this.provincePracticeSelectorCounts,
      (practiceSelector) =>
        new ProvincePracticeSelectorCounts(this, practiceSelector)
    );
  }

  getTotalCounts() {
    return sumCounts(this.provincePracticeSelectorCounts.values());
  }
}

class PracticeSelector {
  constructor(name) {
    this.name = name;
    this.provincePracticeSelectorCounts = [];
  }

  getTotalCounts() {
    return sumCounts(this.provincePracticeSelectorCounts);
  }
}

class Region {
  constructor(name) {
    this.name = name;
    this.provinces = new Map();
  }

  getProvince(name) {
    return computeIfAbsent(
      name,
      this.provinces,
      (name) => new Province(name, this)
    );
  }

  getProvincesAsSortedArray() {
    return getAsArraySortedByName(this.provinces);
  }

  getCountsForPracticeSelector(practiceSelector) {
    return sumCounts(
      this.provinces
        .values()
        .map((province) =>
          province.getCountsForPracticeSelector(practiceSelector)
        )
    );
  }

  getTotalCounts() {
    return sumCounts(
      this.provinces.values().map((province) => province.getTotalCounts())
    );
  }
}

const ShowInviteBreakout = () => {
  const engagement = useRecordContext();
  const regions = new Map();
  const practiceSelectors = new Map();
  //fetch invites
  const {
    data: invites,
    isLoading,
    error,
  } = useGetList("Invitation", {
    pagination: { page: 1, perPage: 9999 },
    filter: { engagementId: engagement.id },
  });

  //get user ids
  const userIds = invites
    ? invites.reduce((userIds, invitation) => {
        userIds.add(invitation.userId);
        return userIds;
      }, new Set())
    : [];

  //fetch users
  const {
    data: users,
    isLoading: isUserLoading,
    error: userError,
  } = useGetList("Users", {
    pagination: { page: 1, perPage: 9999 },
    filter: { ids: Array.from(userIds) },
    //we make this query dependent on prev, this is how this stuff gets chained together
    enabled: userIds.size > 0,
  });

  const [isCompletedOnly, setCompletedOnly] = useState(true);
  if (isLoading || isUserLoading) {
    return <Loading />;
  }
  if (error || userError) {
    return <p>ERROR {error}</p>;
  }

  //populate from invites
  invites.forEach((invite) => {
    const user = users.find((user) => user.id === invite.userId);
    if (!user) {
      return;
    }
    //new impl
    //first we need to ensure everything is initialized
    const regionName = getRegionName(user.provinceOfPractice);
    //get the region
    const region = computeIfAbsent(
      regionName,
      regions,
      (name) => new Region(name)
    );

    const provinceName = user.provinceOfPractice ?? EMPTY_DISPLAY;
    //get the province
    const province = region.getProvince(provinceName);

    const practiceSelectorName =
      (user.hcpType === "physician"
        ? user.specialty && user.specialty.length > 0
          ? user.specialty.join(", ")
          : EMPTY_DISPLAY
        : user.practiceSetting) ?? EMPTY_DISPLAY;

    //get the practice selector
    const practiceSelector = computeIfAbsent(
      practiceSelectorName,
      practiceSelectors,
      (practiceSelectorName) => new PracticeSelector(practiceSelectorName)
    );

    //get the "leaf node"
    const provincePracticeSelectorCounts =
      province.getCountsForPracticeSelector(practiceSelector);

    //increment it (it will "bubble up")
    provincePracticeSelectorCounts.increment(invite.state);
  });

  const sortedRegions = getAsArraySortedByName(regions);
  const sortedPracticeSelectors = getAsArraySortedByName(practiceSelectors);

  const completedOnlyHandler = () => {
    setCompletedOnly(!isCompletedOnly);
  };
  return (
    <>
      <h1>{engagement.title}</h1>
      <FormControlLabel
        control={
          <Switch checked={isCompletedOnly} onChange={completedOnlyHandler} />
        }
        label="Completed only"
      />
      <Table>
        <thead>
          <tr>
            <Header />
            {sortedPracticeSelectors.map((selector) => (
              <Header key={selector.name} colSpan={isCompletedOnly ? 1 : 3}>
                {selector.name}
              </Header>
            ))}
            <Header colSpan={isCompletedOnly ? 1 : 3}>{TOTAL}</Header>
          </tr>
          <tr>
            <Header />
            {sortedPracticeSelectors.map((selector) => (
              <Fragment key={selector.name + "SubHeader"}>
                {!isCompletedOnly && (
                  <>
                    <SubHeader>
                      <FiberNewOutlined />
                    </SubHeader>
                    <SubHeader>
                      <EditNoteOutlined />
                    </SubHeader>
                  </>
                )}
                <CompletedSubHeader>
                  <Check />
                </CompletedSubHeader>
              </Fragment>
            ))}
            {!isCompletedOnly && (
              <>
                <SubHeader>
                  <FiberNewOutlined />
                </SubHeader>
                <SubHeader>
                  <EditNoteOutlined />
                </SubHeader>
              </>
            )}
            <CompletedSubHeader>
              <Check />
            </CompletedSubHeader>
          </tr>
        </thead>
        <tbody>
          {sortedRegions.map((region) => {
            return (
              <RegionRowGroup
                region={region}
                practiceSelectors={sortedPracticeSelectors}
                isCompletedOnly={isCompletedOnly}
                key={region.name + "region"}
              />
            );
          })}
        </tbody>
        <tfoot style={{ borderTop: "2px solid #000" }}>
          <BreakoutRow
            locality={{
              name: TOTAL,
              getCountsForPracticeSelector: (practiceSelector) => {
                return practiceSelector.getTotalCounts();
              },
              getTotalCounts: () => {
                return sumCounts(
                  sortedPracticeSelectors.map((practiceSelector) =>
                    practiceSelector.getTotalCounts()
                  )
                );
              },
            }}
            practiceSelectors={sortedPracticeSelectors}
            isCompletedOnly={isCompletedOnly}
            key="total"
            type="total"
          ></BreakoutRow>
        </tfoot>
      </Table>
    </>
  );
};

/**
 * Renders a region and its provinces/states
 * @param {*} param0
 * @returns
 */
const RegionRowGroup = ({ region, practiceSelectors, isCompletedOnly }) => {
  const provinces = region.getProvincesAsSortedArray();
  return (
    <>
      {(region.name === EMPTY_DISPLAY ||
        getProvinceFilterValue(region.name).length > 1) &&
        provinces.map((province) => {
          return (
            <BreakoutRow
              locality={province}
              practiceSelectors={practiceSelectors}
              isCompletedOnly={isCompletedOnly}
              type="province"
              key={province.name}
            />
          );
        })}
      <BreakoutRow
        locality={region}
        practiceSelectors={practiceSelectors}
        isCompletedOnly={isCompletedOnly}
        type="region"
        key={region.name}
      />
    </>
  );
};

/**
 * Renders a row of the breakout table, for a locality (either region or province/state)
 * @param {*} param0
 * @returns
 */
const BreakoutRow = ({
  locality,
  practiceSelectors,
  type,
  isCompletedOnly,
}) => {
  return (
    <tr
      key={locality.name + "row"}
      style={{
        ...(type === "region" && { backgroundColor: "#ddd" }),
        ...(type === "total" && { backgroundColor: "#ccc" }),
      }}
    >
      <RegionHeader>{locality.name}</RegionHeader>
      {practiceSelectors.map((practiceSelector) => {
        const count = locality.getCountsForPracticeSelector(practiceSelector);
        return (
          <CountCells
            count={count}
            localityName={locality.name}
            practiceSelectorName={practiceSelector.name}
            isCompletedOnly={isCompletedOnly}
            key={locality.name + practiceSelector.name}
          />
        );
      })}
      <CountCells
        count={locality.getTotalCounts()}
        localityName={locality.name}
        practiceSelectorName={TOTAL}
        isCompletedOnly={isCompletedOnly}
        key={locality.name + TOTAL}
      />
    </tr>
  );
};

/**
 * Renders 3 of cells, one for each count in the count param
 *
 * @param {*} props
 * @returns
 */
const CountCells = ({
  count,
  localityName,
  practiceSelectorName,
  isCompletedOnly,
}) => {
  return (
    <>
      {!isCompletedOnly && (
        <>
          <Cell title={localityName + " | " + practiceSelectorName + " | New"}>
            <LinkedCount
              count={count.new}
              localityName={localityName}
              practiceSelectorName={practiceSelectorName}
              state="new"
              isSum={count.isSum}
            />
          </Cell>
          <Cell
            title={
              localityName + " | " + practiceSelectorName + " | In Progress"
            }
          >
            <LinkedCount
              count={count.inProgress}
              localityName={localityName}
              practiceSelectorName={practiceSelectorName}
              state="inProgress"
              isSum={count.isSum}
            />
          </Cell>
        </>
      )}
      <CompletedCell
        title={localityName + " | " + practiceSelectorName + " | Completed"}
      >
        <LinkedCount
          count={count.completed}
          localityName={localityName}
          practiceSelectorName={practiceSelectorName}
          state="completed"
          isSum={count.isSum}
        />
      </CompletedCell>
    </>
  );
};

/**
 * Displays a count and links to a filtered view of invitations
 *
 * @param {*} props
 * @returns
 */
const LinkedCount = ({
  count,
  localityName,
  practiceSelectorName,
  state,
  isSum,
}) => {
  const engagement = useRecordContext();
  const linkFilter = {
    engagementId: engagement.id,
    ...(!localityName.includes(TOTAL) &&
      !localityName.includes(EMPTY_DISPLAY) && {
        userProvince: getProvinceFilterValue(localityName),
      }),
    ...(practiceSelectorName &&
      !practiceSelectorName.includes(EMPTY_DISPLAY) &&
      practiceSelectorName !== TOTAL && {
        physicianSpecialty: practiceSelectorName.split(", "),
      }),
    state: state === "inProgress" ? "in_progress" : state,
  };

  return (
    <Button
      component={Link}
      to={{
        pathname: "/Invitation",
        search: `filter=${JSON.stringify(linkFilter)}`,
      }}
      sx={{
        ...(isSum && { fontWeight: 800 }),
        color: "#000",
        display: "block",
        minHeight: 19,
      }}
    >
      {count ?? " "}
    </Button>
  );
};

export default ShowInviteBreakout;
