import React from 'react';
import { Loading } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Stack, useTheme } from '@mui/material';
import { KeyopsHeader2, KeyopsSubTitle2 } from '@keyops-hcp/ui-components';

import useAdBoardEngagementData from './useAdBoardEngagementData';

const SummarySection = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    totalParticipants,
    activeToday,
    inProgressParticipants,
    completedParticipants,
    completionPercentage,
  } = useAdBoardEngagementData();

  // Navigate if there's an error
  if (isError) {
    navigate('/AdBoards');
    return null;
  }

  return (
    <Container maxWidth='sm' sx={{ mt: 2 }}>
      <Box
        sx={{
          border: `1px solid ${palette.keyops.gray[200]}`,
          width: '100%',
          minHeight: '50px',
          borderRadius: 1,
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2.5,
          py: 1.5,
        }}
      >
        <Stack gap={1}>
          <KeyopsSubTitle2>Participants</KeyopsSubTitle2>
          <KeyopsHeader2>
            {isLoading ? <Loading /> : totalParticipants ?? '-'}
          </KeyopsHeader2>
        </Stack>
        <Stack gap={1}>
          <KeyopsSubTitle2>Active today</KeyopsSubTitle2>
          <KeyopsHeader2>
            {isLoading ? <Loading /> : activeToday ?? '-'}
          </KeyopsHeader2>
        </Stack>
        <Stack gap={1}>
          <KeyopsSubTitle2>In progress</KeyopsSubTitle2>
          <KeyopsHeader2>
            {isLoading ? <Loading /> : inProgressParticipants ?? '-'}
          </KeyopsHeader2>
        </Stack>
        <Stack gap={1}>
          <KeyopsSubTitle2>Completed</KeyopsSubTitle2>
          <KeyopsHeader2>
            {isLoading ? <Loading /> : completedParticipants ?? '-'}
          </KeyopsHeader2>
        </Stack>
        <Stack gap={1}>
          <KeyopsSubTitle2>Completion rate</KeyopsSubTitle2>
          <KeyopsHeader2>
            {isLoading ? <Loading /> : `${completionPercentage ?? '-'}%`}
          </KeyopsHeader2>
        </Stack>
      </Box>
    </Container>
  );
};

export default SummarySection;
