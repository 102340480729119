import { useQuery } from '@tanstack/react-query';
import { getOneSurveyUniqueLink } from '../api/surveys';

export const useSurveyUniqueLink = (
  surveyId: string,
  enabled: boolean = true
) => {
  const { data, isLoading, isError } = useQuery<string>({
    queryKey: ['survey', surveyId],
    queryFn: () => getOneSurveyUniqueLink(surveyId),
    enabled: !!surveyId && enabled, // Ensure the query is only enabled when the surveyId is valid and enabled is true
  });

  return { data, isLoading, isError };
};
