import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Snackbar } from "@mui/material";
import { AttachmentDto } from "@keyops-hcp/dtos";

import { getAllAttachments } from "../../../api/attachments";

type AttachmentsList = {
  attachments: AttachmentDto[];
  setAllAttachments: React.Dispatch<React.SetStateAction<AttachmentDto[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

// TODO: rm as it is not used
export const AttachmentsList = ({
  attachments,
  setAllAttachments,
  isLoading,
  setIsLoading,
}: AttachmentsList) => {
  // Error handling
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleGetAllAttachments = async () => {
    try {
      setIsLoading(true);

      const results = await getAllAttachments();

      if (results) {
        setAllAttachments(results);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error fetching the attachments`);
      setIsToastOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllAttachments();
  }, []);

  return (
    <Box>
      <div>All Attachments</div>
      {!isLoading ? (
        <ul>
          {attachments.length > 0 &&
            attachments.map((attachment) => (
              <li key={attachment.id}>
                <Link to={attachment.id}>{attachment.title}</Link>
              </li>
            ))}
        </ul>
      ) : (
        <div>Loading data...</div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isToastOpen}
        autoHideDuration={5000}
        message={errorMessage}
        onClose={() => setIsToastOpen(false)}
      />
    </Box>
  );
};
