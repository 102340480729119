import { SectionDto } from '@keyops-hcp/dtos';

export const getCurrentSection = (
  sectionId: string,
  sections: SectionDto[]
) => {
  if (!sections) return undefined;
  //if it's "first" or undefined, we just jump to first section
  if ((!sectionId || sectionId === 'first') && sections.length > 0) {
    return sections[0];
  }

  //if it's "current" we go to the latest that the user has unlocked
  if (sectionId === 'current') {
    const currentForUser = 1; //TODO: base on user progress
    return sections[currentForUser];
  }

  //if it's a straight number, and within the range of sections, we treat it as a section index
  const sectionIndex = parseInt(sectionId);
  if (!isNaN(sectionIndex) && sectionIndex < sections.length) {
    return sections[sectionIndex];
  }

  //otherwise, we try to use it as a section id
  //if we still can't find it, return the first
  return sections.find((section) => section.id === sectionId) ?? sections[0];
};
