import { VideoDto } from "@keyops-hcp/dtos";
import { t } from "i18next";

import { VIDEOS_ENDPOINT } from "../utils/api-routes";
import { axiosCall } from "../utils/api";

export const getOneVideo = async (id: string): Promise<VideoDto> => {
  try {
    const results = await axiosCall(
      false,
      "get",
      VIDEOS_ENDPOINT + `/${id}`,
      undefined,
      undefined,
      { id }
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      results?.data?.message || t("apiErrorMessages.no_video_found")
    );
  } catch (error) {
    console.error("Error fetching data getOneVideo:", error);
    throw new Error(
      error?.response?.data?.message || t("apiErrorMessages.no_video_found")
    );
  }
};
