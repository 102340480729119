import { useMutation } from '@keyops-hcp/ui-components';
import { updateOneAttachment } from '../api/attachments';
import {
  AttachmentDto,
  AttachmentFormDto,
  AttachmentsAdBoardSectionDto,
  SectionDto,
} from '@keyops-hcp/dtos';
import { useUpdateSectionContent } from './useUpdateSectionContent';
import { NotificationType } from 'react-admin';

export const useUpdateAttachmentSection = (
  section: AttachmentsAdBoardSectionDto,
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>,
  notify: (
    message: string | React.ReactNode,
    options?: NotificationOptions & {
      type?: NotificationType;
    }
  ) => void
) => {
  const { mutate: updateSectionContent } =
    useUpdateSectionContent(setEditSection);

  return useMutation<
    AttachmentDto,
    unknown,
    { attachmentId: string; attachment: AttachmentFormDto }
  >(
    async ({ attachmentId, attachment }): Promise<AttachmentDto> => {
      // First update the Attachment
      const updatedAttachment = await updateOneAttachment(
        attachmentId,
        attachment
      );

      return updatedAttachment;
    },
    {
      onSuccess: (updatedAttachment) => {
        if (updatedAttachment) {
          // Then update the section (and re-set the section currently under edition)
          // TODO: update the index when we add the feature to reorder the documents
          updateSectionContent({
            sectionId: section.id,
            content: [
              {
                attachmentId: updatedAttachment.id,
                index:
                  section?.content.find(
                    (attachment) =>
                      attachment.attachmentId === updatedAttachment.id
                  )?.index ?? 0,
              },
            ],
          });
          notify('Document updated successfully', { type: 'success' });
        }
      },
      onError: (error) => {
        console.error(error);
        notify('Failed to update the document', { type: 'error' });
      },
    }
  );
};
