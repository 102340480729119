import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Button } from 'react-admin';

export const AttachmentUploadButton = ({
  setNewAttachmentFile,
}: {
  setNewAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}) => {
  return (
    <Stack direction={'column'} alignItems={'center'} spacing={1} pt={`8px`}>
      <label htmlFor='upload-attachment'>
        <input
          id='upload-attachment'
          type='file'
          accept='
            application/pdf,
            application/zip,
            application/x-zip-compressed,
            multipart/x-zip,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-powerpoint,
            application/vnd.openxmlformats-officedocument.presentationml.presentation
          '
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setNewAttachmentFile(file);

              // Clear the input value to allow re-upload of the same file
              e.target.value = '';
            }
          }}
        />
        <Button
          variant='outlined'
          label={'Upload file'}
          sx={{ textTransform: 'initial' }}
          component='span'
        />
      </label>
      <Typography fontWeight={400} fontSize={'14px'} color={`#2D3748`}>
        {' '}
        Upload .pdf, office docs, or zip files.
      </Typography>
    </Stack>
  );
};
