// External Dependencies
import { createTheme } from "@mui/material/styles";

const RalewayWoff2 = "./fonts/Quicksand-Regular.ttf";

declare module "@mui/material/styles" {
  interface Palette {
    keyops: {
      background: string;
      blue: {
        main: string;
        main90: string;
        500: string;
        light: string;
        lighter: string;
        medium: string;
        dark: string;
        dark50: string;
        dark90: string;
      };
      teal: string;
      black: {
        main: string;
        alpha600: string;
        alpha300: string;
      };
      white: {
        main: string;
        alpha800: string;
        alpha900: string;
      };
      gray: {
        main: string;
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
      };
    };
  }

  interface PaletteOptions {
    keyops?: {
      background?: string;
      blue?: {
        main?: string;
        main90?: string;
        500?: string;
        light?: string;
        lighter?: string;
        medium?: string;
        dark?: string;
        dark50: string;
        dark90?: string;
      };
      teal?: string;
      black?: {
        main?: string;
        alpha600?: string;
        alpha300?: string;
      };
      white?: {
        main?: string;
        alpha800?: string;
        alpha900?: string;
      };
      gray?: {
        main?: string;
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
      };
    };
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Quicksand'), local('Quicksand'), url(${RalewayWoff2}) format('ttf');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
    },
  },
  palette: {
    secondary: {
      main: "#39BDAF",
    },
    background: {
      default: "#39BDAF",
    },
    keyops: {
      background: "#F3F9FC",
      blue: {
        main: "#3376D1",
        main90: "#3376D180",
        500: "#3182CE",
        lighter: "#7C9AC0",
        light: "#EBF8FF",
        medium: "#90CDF4",
        dark: "#2C5282",
        dark90: "#2C5282E5",
        dark50: "#2C528280",
      },
      teal: "#39BDAF",
      black: { main: "#1A242B", alpha600: "#0000007A", alpha300: "#00000029" },
      white: { main: "#FFFFFF", alpha800: "#ffffffcc", alpha900: "#FFFFFFEB" },
      gray: {
        main: "#B3B3B3",
        50: "#F7FAFC",
        100: "#EDF2F7",
        200: "#E2E8F0",
        300: "#CBD5E0",
        400: "#A0AEC0",
        500: "#718096",
        600: "#4A5568",
        700: "#2D3748",
      },
    },
  },
});
