import React from 'react';
import { Box, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { DiscussionDrawerContent } from '../DiscussionDrawerContent';

interface DiscussionsDrawerProps {
  displayedDiscussionId: string | undefined;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  discussionDrawerOpen: boolean;
  toggleDiscussionDrawer: () => void;
  optionalAdBoardId?: string; // Can be passed by the admin preview
  optionalCurrentSectionId?: string; // Can be passed by the admin preview
}

export const DiscussionsDrawer = ({
  displayedDiscussionId,
  setDisplayedDiscussionId,
  discussionDrawerOpen,
  toggleDiscussionDrawer,
  optionalAdBoardId,
  optionalCurrentSectionId,
}: DiscussionsDrawerProps): JSX.Element => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // Set width depending on drawer state
  // When the drawer is opened and display the list of discussions: 33vw
  // When the user selects a discussion to display:70vw
  const drawerWidth = displayedDiscussionId ? '70%' : '33%';
  return (
    <Box
      display={!isMobile ? 'flex' : 'none'}
      flexDirection='column'
      alignItems='flex-end'
      height={'100%'}
      width={discussionDrawerOpen ? drawerWidth : '0'}
    >
      <Collapse
        in={discussionDrawerOpen}
        timeout={300}
        orientation='horizontal'
        sx={{
          height: '100%',
          border: '1px solid var(--gray-200, #E2E8F0)',
          borderTop: 0,
        }}
        unmountOnExit
      >
        <Box
          height='100%'
          width={`100%`}
          bgcolor='background.paper'
          overflow='auto'
          sx={{
            transition: 'transform 0.3s ease-in-out', // Smooth transition
            transform: discussionDrawerOpen
              ? 'translateX(0)'
              : 'translateX(100%)', // Slide effect
          }}
        >
          <DiscussionDrawerContent
            displayedDiscussionId={displayedDiscussionId}
            setDisplayedDiscussionId={setDisplayedDiscussionId}
            toggleDiscussionDrawer={toggleDiscussionDrawer}
            optionalAdBoardId={optionalAdBoardId}
            optionalCurrentSectionId={optionalCurrentSectionId}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
