import { AttachmentDto } from "@keyops-hcp/dtos";
import { t } from "i18next";

import { axiosCall } from "../utils/api";
import { ATTACHMENTS_ENDPOINT } from "../utils/api-routes";

export const getOneAttachment = async (id: string): Promise<AttachmentDto> => {
  try {
    const results = await axiosCall(
      false,
      "get",
      ATTACHMENTS_ENDPOINT + `/${id}`,
      undefined,
      undefined
    );

    if (results.status === 200) {
      return results.data.data;
    }
    throw new Error(
      results?.data?.message || t("apiErrorMessages.no_attachment_found")
    );
  } catch (error) {
    console.error("Error fetching data getOneAttachment:", error);
    throw new Error(
      error?.response?.data?.message ||
        t("apiErrorMessages.no_attachment_found")
    );
  }
};
