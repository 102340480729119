import { useQuery } from "@tanstack/react-query";
import { SectionDto } from "@keyops-hcp/dtos";
import { getAdBoardSections } from "../api/sections";

export const useSections = (adBoardId: string) => {
  return useQuery<SectionDto[]>({
    queryKey: ["getAdBoardSections", adBoardId],
    queryFn: () => getAdBoardSections(adBoardId),
    enabled: !!adBoardId,
  });
};
