import React from 'react';
import { Stack } from '@mui/material';

export const Survey = ({ link }: { link: string }) => {
  return (
    <Stack spacing={1} mt={1}>
      <iframe
        title={'survey'}
        style={{
          border: 'none',
          width: '100%',
          height: '100%',
          transform: 'scale(1)',
          transformOrigin: '0 0', // Scale from the top-left corner
          position: 'absolute', // Absolutely position the iframe
          top: '0',
          left: '0',
        }}
        src={link}
      />
    </Stack>
  );
};
