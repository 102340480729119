import { DiscussionMessageDto } from '@keyops-hcp/dtos';
import { axiosCall } from '../utils/api';
import { DISCUSSIONS_ENDPOINT } from '../utils/api-routes';

export const createOneDiscussionMessage = async (
  content: string,
  discussionId: string
): Promise<DiscussionMessageDto> => {
  try {
    const response = await axiosCall(
      false,
      'post',
      `${DISCUSSIONS_ENDPOINT}/${discussionId}/messages`,
      undefined,
      undefined,
      { content }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating one attachment', error);
    throw error;
  }
};
