import React from 'react';
import { Box } from '@mui/material';
import { DiscussionsAdBoardSectionDto, SectionDto } from '@keyops-hcp/dtos';
import { DiscussionsAccordion } from './DiscussionsAccordion';

interface DiscussionsEditSectionProps {
  discussionSection: DiscussionsAdBoardSectionDto;
  setEditSection: React.Dispatch<React.SetStateAction<SectionDto>>;
}

export const DiscussionsEditSection: React.FC<DiscussionsEditSectionProps> = ({
  discussionSection,
  setEditSection,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <DiscussionsAccordion
        section={discussionSection as DiscussionsAdBoardSectionDto}
        setEditSection={setEditSection}
      />
    </Box>
  );
};
