import React, { createContext, ReactNode, useContext } from 'react';

import {
  HCPAdBoardContextType as HCPAdBoardContextType,
  useIsAdminHook,
  useProgressTracker,
} from '../hooks';
import {
  AdminAdBoardContextType,
  useAdminAdBoard,
} from '../hooks/useAdminAdBoard';

export const HCPAdBoardContextDefaultValues: HCPAdBoardContextType = {
  isAdmin: false,
  isLoading: true,
  isError: false,
};

const AdBoardContext = createContext<HCPAdBoardContextType>(undefined);

export const AdminAdBoardContextDefaultValues: AdminAdBoardContextType = {
  isAdmin: true,
  isLoading: true,
  isError: false,
};

const AdminAdBoardContext = createContext<AdminAdBoardContextType>(undefined);

export const useAdBoardContext = ():
  | HCPAdBoardContextType
  | AdminAdBoardContextType => {
  const isAdmin = useIsAdminHook();
  const context = useContext(isAdmin ? AdminAdBoardContext : AdBoardContext);

  return context;
};

/**
 * Used only to wrap physician-ui
 */
export const HCPAdBoardDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const data = useProgressTracker();

  return (
    <AdBoardContext.Provider value={data}>{children}</AdBoardContext.Provider>
  );
};

/**
 * Used only to wrap admin-ui
 */
export const AdminAdBoardDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const data = useAdminAdBoard();

  return (
    <AdminAdBoardContext.Provider value={data}>
      {children}
    </AdminAdBoardContext.Provider>
  );
};
