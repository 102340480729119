// External Dependencies
import React from "react";
import { Button, TableRow, Typography } from "@mui/material";

// Internal Dependencies

const Results = ({ data }) => {
  if (data.length === 0) {
    return <div />;
  }

  const resultsLines = data.map((rowData, index) => {
    let errorMessage = undefined;

    if (rowData.error) {
      errorMessage = rowData.error;
    } else if (rowData.status === "error") {
      errorMessage = rowData.reason;
    }

    return (
      <TableRow
        key={index}
        display='block'
        height='30px'
        sx={{
          "& th": {
            textAlign: "left",
          },
          "& tr th:first-of-type": {
            width: "200px",
          },
        }}
      >
        <td
          style={{
            color:
              rowData.status === "success" || rowData.results ? "green" : "red",
          }}
        >
          {rowData.status === "success" || rowData.results
            ? "Success"
            : "Error"}
        </td>
        <td>{errorMessage ?? rowData.text ?? rowData?.data?.message ?? ""}</td>
      </TableRow>
    );
  });
  return (
    <table width='100%'>
      {/*<tr>
			<th>Result</th>
			<th>Email</th>
			<th>Error Message</th>
		</tr>*/}
      {resultsLines}
    </table>
  );
};

const FileUploader = ({ title, subTitle, handleChange, resultsData }) => {
  const loadFile = (event) => {
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = async () => {
      handleChange(reader.result);
      // setResults(JSON.parse(results));
    };
  };

  return (
    <>
      <Typography>{title}</Typography>
      <Typography>{subTitle}</Typography>
      <input
        accept='.csv'
        style={{ display: "none" }}
        id='raised-button-file'
        multiple
        type='file'
        onChange={loadFile}
      />
      <label htmlFor='raised-button-file'>
        <Button variant='raised' component='span'>
          Upload
        </Button>
      </label>

      <Results data={resultsData} />
    </>
  );
};

export default FileUploader;
