import { useState, useEffect, useMemo } from 'react';
import jwtDecode from 'jwt-decode';

export type PhysicianAdminToken = {
  email: string;
  role: 'Admin';
};

export const useIsAdminHook = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const token = localStorage.getItem('token');

  // Use useMemo to cache the decoded token
  const decodedToken = useMemo(() => {
    if (token) {
      try {
        return jwtDecode(token) as PhysicianAdminToken;
      } catch (error) {
        console.error('Invalid token:', error);
        return null;
      }
    }
    return null;
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      setIsAdmin(decodedToken.role === 'Admin');
    } else {
      setIsAdmin(false);
    }
  }, [decodedToken]); // Dependency on decodedToken

  return isAdmin;
};
