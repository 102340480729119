import React from 'react';
import { FiTrash2 } from 'react-icons/fi';

// TODO: add the color in a theme
export const KeyOpsTrashIcon = ({
  size = `20px`,
  onClick = undefined,
}: {
  size?: string;
  onClick?: () => void;
}) => {
  return (
    <FiTrash2
      color='#3376D1'
      size={size}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    />
  );
};
