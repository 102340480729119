import { Theme } from "@mui/material";

const buttonCommon = {
  display: "block",
  px: 4,
};

export const useStyles = (theme: Theme) => ({
  buttonAccept: {
    ...buttonCommon,
    color: theme.palette.keyops.blue.dark,
  },
  buttonDecline: {
    ...buttonCommon,
    color: theme.palette.keyops.gray[500],
  },
});
