import React, { useEffect, useMemo, useRef } from 'react';
import {
  AdBoardSurveySectionProgressCombined,
  SurveyAdBoardSectionDto,
} from '@keyops-hcp/dtos';
import { Box } from '@mui/material';
import i18next from '../../languages/i18n.config';
import { Survey } from '../Survey';
import { useSurveyUniqueLink } from '../../hooks/useSurveyUniqueLink';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';

export const SurveyWrapper = () => {
  const {
    isAdmin,
    isLoading: contextLoading,
    isError: contextError,
    userId,
    currentSectionDetails,
    currentSectionProgress,
    updateProgress,
  } = (useAdBoardContext() as HCPAdBoardContextType) || {}; // Necessary to avoid bug if admin

  // Ensure hooks are always called before returning
  const progressLink = useMemo(() => {
    return (currentSectionProgress as AdBoardSurveySectionProgressCombined)
      ?.uniqueLink;
  }, [currentSectionProgress]);

  const { data: uniqueLink } = useSurveyUniqueLink(
    (currentSectionDetails as SurveyAdBoardSectionDto)?.content?.surveyId,
    !progressLink // Conditionally enable fetch based on progressLink
  );

  // Use `useRef` to store a stable reference to the current link
  const linkRef = useRef<string | undefined>(progressLink || uniqueLink);

  // Update the ref whenever the `link` changes
  useEffect(() => {
    linkRef.current = progressLink || uniqueLink;
  }, [progressLink, uniqueLink]);

  // Conditionally update progress if we fetched a new uniqueLink
  useEffect(() => {
    if (!progressLink && uniqueLink) {
      updateProgress({ uniqueLink });
    }
  }, [uniqueLink, progressLink, updateProgress]);

  // Add event listener for survey completion message
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data?.type === 'surveyCompleted' && linkRef.current) {
        updateProgress({ uniqueLink: linkRef.current });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [updateProgress]);

  // Handle loading and error states after hooks have been called
  if (contextLoading) return <div>{i18next.t('common.loading')}</div>;
  if (contextError) return <div>{i18next.t('common.error')}</div>;
  if (!currentSectionDetails || currentSectionDetails.type !== 'survey')
    return <div>{i18next.t('common.error')}</div>;

  // Use the link from progress or fallback to the fetched link
  const link = progressLink || uniqueLink;

  // Render the Survey component
  if (isAdmin) return <Survey link='' />; //TODO just show a message
  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <Survey link={`${link}?Contact_HcpId=${userId}`} />
    </Box>
  );
};
