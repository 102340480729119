import { gql } from "@apollo/client";
import { saveCreds, clearCredentials } from "./lib";
import apolloClient from "./apolloClient";

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    // TODO this mutation should be in the library
    return apolloClient
      .mutate({
        mutation: gql`
          mutation LoginAdmin($email: String!, $password: String!) {
            LoginAdmin(email: $email, password: $password) {
              token
              role
              email
              name
              expiresAt
            }
          }
        `,
        variables: { email: username, password: password },
      })
      .then(({ data }) => {
        const { LoginAdmin } = data;
        saveCreds(LoginAdmin);
        return Promise.resolve();
      })
      .catch((error) => {
        // TODO: should we use the error?
        console.error(error); // Fix TS error

        clearCredentials();
        return Promise.reject();
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    clearCredentials();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      clearCredentials();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: (...rest) => {
    console.log(rest); // Fix TS error
    return localStorage.getItem("token") &&
      localStorage.getItem("role") === "Admin"
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: (...rest) => {
    console.log(rest); // Fix TS error

    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      id: "user",
      fullName: localStorage.getItem("name"),
      avatar: "",
    });
  },
};

export default authProvider;
