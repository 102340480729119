import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  PasswordInput,
  required,
  minLength,
  maxLength,
  email,
  Labeled,
  TextField,
} from "react-admin";

const validateString = (min = 2, max = 90) => [
  required(),
  minLength(min),
  maxLength(max),
];
const validatePassword = (min = 6, max = 30, pRequired = true) =>
  pRequired
    ? [required(), minLength(min), maxLength(max)]
    : [minLength(min), maxLength(max)];

const AdminName = ({ record }) => {
  return <span>Admin {record ? `"${record.name}"` : ""}</span>;
};

const AdminEdit = (props) => (
  <Edit title={<AdminName />} {...props}>
    <SimpleForm>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
      <TextInput source="name" validate={validateString()} fullWidth />
      <PasswordInput
        source="password"
        validate={validatePassword(6, 30, false)}
        inputProps={{ autocomplete: "new-password" }}
        fullWidth
      />
      <TextInput source="email" validate={email()} fullWidth />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
