// External Dependencies
import React from "react";
import { Box, Typography, Card, CardContent, CardHeader } from "@mui/material";

// Internal Dependencies

const releaseMessage = `Release Time: 9:09pm June 5`;
// const releaseMessage = ? `Release Time: {process.env.REACT_APP_RELEASE_TIME}` : ''
const Dashboard = (props) => {
  console.log(props);
  return (
    <Card position={"relative"}>
      <CardHeader title="Welcome to the KeyOps Admin: Below is a directory of utility pages" />
      <CardContent>
        <ul>
          <li>
            {" "}
            <a
              href="/admin#/utils"
              color="#000"
              textDecoration="none"
              display="inline-block"
            >
              <Typography>Bulk Functions</Typography>
            </a>
          </li>
          <li>
            {" "}
            <a
              href="/admin#/password-reset"
              color="#000"
              textDecoration="none"
              display="inline-block"
            >
              <Typography>Password Reset</Typography>
            </a>
          </li>
        </ul>
        <Box position={"absolute"} right="10px" bottom="0px">
          {" "}
          <Typography>{releaseMessage}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
