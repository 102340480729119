import React from 'react';
import { Divider } from '@mui/material';
import { useParams } from 'react-router';

import {
  AllCurrentDiscussionsDto,
  DiscussionAdBoardSectionContentDto,
} from '@keyops-hcp/dtos';

import { Loader } from '../Loader';
import { DiscussionDrawerHeader } from '../DiscussionDrawerHeader';
import { DiscussionChat } from '../DiscussionChat';
import { DiscussionPreview } from '../DiscussionPreview';
import { useDiscussionsByAdBoardId } from '../../hooks';
import i18next from '../../languages/i18n.config';

interface DiscussionDrawerContentProps {
  displayedDiscussionId: string | undefined;
  toggleDiscussionDrawer: () => void;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  optionalAdBoardId?: string; // Can be passed by the admin preview
  optionalCurrentSectionId?: string; // Can be passed by the admin preview
}

/**
 * Used to determine the conversation to display in allCurrentDiscussions, given a selected id
 * @param displayedDiscussionId
 * @param allCurrentDiscussions
 * @returns the discussion to display in the Discussion Drawer
 */
const findDiscussionToDisplay = (
  displayedDiscussionId: string,
  allCurrentDiscussions: AllCurrentDiscussionsDto
) => {
  // Check if general discussion
  if (allCurrentDiscussions.generalDiscussion.id === displayedDiscussionId)
    return allCurrentDiscussions.generalDiscussion;

  const discussionToDisplay =
    allCurrentDiscussions.currentSectionDiscussions.find(
      (currentSectionDiscussion) =>
        currentSectionDiscussion?.discussion?.id === displayedDiscussionId
    )?.discussion;
  if (discussionToDisplay) return discussionToDisplay;
  console.error(`Discussion to display not found`);
};

export const DiscussionDrawerContent = ({
  displayedDiscussionId,
  toggleDiscussionDrawer,
  setDisplayedDiscussionId,
  optionalAdBoardId,
  optionalCurrentSectionId,
}: DiscussionDrawerContentProps): JSX.Element => {
  const generalDiscussionTitle = i18next.t(
    'ad_board.discussionsDrawer.generalDiscussion.title'
  );
  // If admin, passed as props to the component.
  //  If HCP, grab them in the url
  let adBoardId = optionalAdBoardId;
  let currentSectionId = optionalCurrentSectionId;
  if (!adBoardId && !currentSectionId) {
    adBoardId = useParams().adBoardId;
    currentSectionId = useParams().sectionId;
  }

  // Fetch all current discussions (combining the Ad Board general discussion and the current section discussions, if any)
  const {
    data: allCurrentDiscussions,
    isLoading,
    isError,
  } = useDiscussionsByAdBoardId(adBoardId, currentSectionId);

  if (isLoading) return <Loader />;
  if (isError) return <>Something went wrong</>;
  if (!allCurrentDiscussions) return <>No data</>;

  // If a discussion has been selected, display the chat in the Discussion drawer
  if (displayedDiscussionId) {
    const displayedDiscussion = findDiscussionToDisplay(
      displayedDiscussionId,
      allCurrentDiscussions
    );

    return (
      <>
        <DiscussionDrawerHeader
          isDisplayingMessages={true}
          title={
            displayedDiscussion?.topic === generalDiscussionTitle
              ? generalDiscussionTitle
              : allCurrentDiscussions.currentSectionTitle
          }
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
        />
        <Divider />
        <DiscussionChat discussion={displayedDiscussion} />
      </>
    );
  }

  // If not discussion selected, display a preview of the available discussions on the current section
  return (
    <>
      <DiscussionDrawerHeader
        isDisplayingMessages={false}
        title={i18next.t('ad_board.discussionsDrawer.title')}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />
      <Divider />
      {/* General Discussion */}
      <DiscussionPreview
        discussion={allCurrentDiscussions.generalDiscussion}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />

      {/* All current section discussions, if any */}
      {allCurrentDiscussions.currentSectionDiscussions.length > 0 &&
        allCurrentDiscussions.currentSectionDiscussions.map(
          (sectionsDiscussion: DiscussionAdBoardSectionContentDto) => (
            <DiscussionPreview
              key={sectionsDiscussion.discussionId}
              discussion={sectionsDiscussion.discussion}
              index={allCurrentDiscussions.currentSectionIndex}
              sectionTitle={allCurrentDiscussions.currentSectionTitle}
              setDisplayedDiscussionId={setDisplayedDiscussionId}
            />
          )
        )}
    </>
  );
};
