import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DiscussionDto } from '@keyops-hcp/dtos';

import { KeyopsHeader3 } from '../Headers';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';
import i18next from '../../languages/i18n.config';

interface DiscussionChatHeaderProps {
  discussion: DiscussionDto;
}

export const DiscussionChatHeader = ({
  discussion,
}: DiscussionChatHeaderProps): JSX.Element => {
  // If the user is admin, then currentSectionDetails is undefined
  const { currentSectionDetails, numberOfSections } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};

  return (
    <Box display={'block'} pt={1}>
      {currentSectionDetails && (
        <Typography
          pb={1}
          fontWeight={400}
          fontSize={'14px'}
          lineHeight={'16px'}
        >
          {i18next.t('ad_board.sections_view.section_number', {
            currentSection: currentSectionDetails?.index + 1,
            totalSections: numberOfSections,
          })}
        </Typography>
      )}
      <KeyopsHeader3 pb={1} fontWeight={400}>
        {discussion.topic}
      </KeyopsHeader3>

      {/* TODO: add the activity description when there*/}

      <AdBoardSectionTaskInfo pb='8px' mb='16px'>
        {i18next.t('ad_board.discussionsDrawer.objectives')}
      </AdBoardSectionTaskInfo>

      <Divider />
    </Box>
  );
};
