// External Dependencies
import * as React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  BooleanField,
  FunctionField,
  EditButton,
  downloadCSV,
  useRedirect,
  AutocompleteInput,
  useRecordContext,
  useListContext,
  Button,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { onClickBulkUpdatePayments } from "../../../utils/graphql_lib";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Export functionality
const exporter = async (records, fetchRelatedRecords) => {
  const users = await fetchRelatedRecords(records, "userId", "user");

  const fetchuser = (id) => {
    return users[id] ?? { firstName: "", lastName: "", email: "" };
  };

  const postsForExport = records.map((post) => {
    const user = fetchuser(post.userId);
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      type: post.origin?.type,
      originId: post.origin?.id,
      engagementId: post.origin?.engagementId,
      amount: post.amount / 100,
      paymentState: post.state,
      failureNote: post.failureNote,
      approvedBy: post.approvedById,
      created_at: post.createdAt,
    };
  });

  jsonExport(postsForExport, {}, (csv) => {
    downloadCSV(csv, "payments"); // download as 'payments.csv` file
  });
};

// Internal Dependencies
const AddFilterDropdown = (props) => (
  <Filter {...props}>
    <ReferenceInput source="userId" reference="Users" label="User Email">
      <AutocompleteInput source="userId" optionText="email" />
    </ReferenceInput>

    <ReferenceInput
      perPage={1000}
      filterToQuery={(searchText) => ({
        name: [searchText],
      })}
      source="engagementId"
      reference="Engagement"
    >
      <AutocompleteInput label="title" source="title" optionText="title" />
    </ReferenceInput>

    <SelectInput
      source="state"
      label="Payment State"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "pending_approved", name: "Pending Approved" },
        { id: "sent", name: "Sent" },
        { id: "complete", name: "Complete" },
        { id: "failed", name: "Failed" },
        { id: "failed_to_send", name: "Failed to Send" },
        { id: "cancelled", name: "Cancelled" },
      ]}
    />

    <SelectInput
      source="provider"
      label="Provider"
      choices={[
        { id: "paypal", name: "Paypal" },
        { id: "vopay", name: "Vopay" },
      ]}
    />

    <SelectInput
      source="type"
      label="Type"
      choices={[
        { id: "invitation", name: "Invitation" },
        { id: "referral", name: "Referral" },
      ]}
    />

    <DateTimeInput source="starts_at" label="Start Date" />

    <DateTimeInput source="ends_at" label="End Date" />
  </Filter>
);

const AmountTextField = ({ source }) => {
  const record = useRecordContext();
  return <span>{record && record[source] / 100}</span>;
};

const OriginIdField = () => {
  const record = useRecordContext();
  return (
    <ReferenceField
      label="Origin Id"
      source="origin.id"
      reference={
        record.origin?.type === "invitation" ? "Invitation" : "Referrals"
      }
      link="show"
    >
      <TextField label="Origin Id" source="id" />
    </ReferenceField>
  );
};

const EditField = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  return record &&
    ["pending", "pending_approved", "failed_to_send", "cancelled"].includes(
      record.state
    ) ? (
    <EditButton onClick={() => redirect(`/Payment/${record.id}`)} />
  ) : null;
};

const BulkApprovalButton = () => {
  const { selectedIds, refetch } = useListContext();
  const onClick = async () => {
    await onClickBulkUpdatePayments(selectedIds, "pending_approved");
    refetch();
  };
  return (
    <Tooltip title="convert pending to pending approved">
      <Button
        variant="contained"
        label="bulk approve"
        onClick={() => onClick()}
      >
        <CheckCircleIcon />
      </Button>
    </Tooltip>
  );
};

const BulkCancelButton = () => {
  const { selectedIds, refetch } = useListContext();
  const onClick = async () => {
    await onClickBulkUpdatePayments(selectedIds, "cancelled");
    refetch();
  };

  return (
    <Tooltip title="convert pending to cancelled">
      <Button
        variant="contained"
        label="bulk cancel"
        onClick={() => onClick()}
        sx={{
          marginLeft: 10,
          background: "#f41100",
          "&:hover": {
            background: "#f44336",
          },
        }}
      >
        <CancelIcon />
      </Button>
    </Tooltip>
  );
};

const PaymentList = (props) => (
  <List
    exporter={exporter}
    filters={<AddFilterDropdown />}
    bulkActionButtons={
      <>
        <BulkApprovalButton /> <BulkCancelButton />
      </>
    }
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <ReferenceField label="Name" source="userId" reference="Users">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        label="Work Email Verfied"
        source="userId"
        reference="Users"
      >
        <BooleanField source="workEmailVerified" />
      </ReferenceField>
      <TextField source="origin.type" label="Type" />
      <OriginIdField />
      <ReferenceField
        label="Engagement Id"
        source="origin.engagementId"
        reference="Engagement"
      >
        <TextField source="title" />
      </ReferenceField>
      <AmountTextField source="amount" />
      <TextField source="provider" />
      <TextField source="state" />
      <ReferenceField
        label="Approved By"
        source="approvedById"
        reference="Admins"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <EditField />
    </Datagrid>
  </List>
);
export default PaymentList;
