import i18next from 'i18next';
import { NotificationDto } from '@keyops-hcp/dtos';

import { axiosCall } from '../utils/api';
import {
  ALL_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_READ_STATUS,
} from '../utils/api-routes';

export const getAllNotifications = async (): Promise<NotificationDto[]> => {
  try {
    const results = await axiosCall(
      false,
      'get',
      ALL_NOTIFICATIONS,
      undefined,
      {}
    );
    if (results.status === 200) {
      return results.data.data || [];
    }
    throw new Error(
      results.data?.message || i18next.t('api.notifications.errorFetching')
    );
  } catch (error) {
    throw new Error(
      error?.message || i18next.t('api.notifications.errorFetching')
    );
  }
};

export const updateReadStatusCall = async (
  unreadNotificationsIds: string[]
): Promise<void> => {
  try {
    const results = await axiosCall(
      false,
      'patch',
      UPDATE_NOTIFICATIONS_READ_STATUS,
      undefined,
      undefined,
      { ids: unreadNotificationsIds }
    );
    if (results?.status !== 200) {
      throw new Error(
        results?.data.message ||
          i18next.t('api.notifications.errorUpdatingReadStatus')
      );
    }
  } catch (error) {
    throw new Error(
      error?.message || i18next.t('api.notifications.errorUpdatingReadStatus')
    );
  }
};
