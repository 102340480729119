// External Dependencies
import React from "react";

import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  required,
  maxLength,
  minLength,
  SelectInput,
  UrlField,
  ReferenceInput,
  Labeled,
  TextField,
} from "react-admin";
import { EngagementInfo } from "./Create";

const validateName = [required(), minLength(2), maxLength(255)];

const OnboardingLinkEdit = (props) => {
  return (
    <Edit title={<div />} {...props}>
      <SimpleForm>
        <Labeled label="Id">
          <TextField source="id" />
        </Labeled>
        <TextInput source="name" validate={validateName} fullWidth />
        <BooleanInput source="enabled" defaultValue={true} />
        <UrlField source="link" target="_new" />
        <EngagementInfo />
        <ReferenceInput
          label="Engagement"
          source="engagementId"
          reference="Engagement"
          allowEmpty
          fullWidth
        >
          <SelectInput source="title" optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default OnboardingLinkEdit;
