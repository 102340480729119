import { AdBoardInviteDto } from "@keyops-hcp/dtos";
import { t } from "i18next";

import { axiosCall } from "../utils/api";
import { AD_BOARDS_ENDPOINT } from "../utils/api-routes";

export const acceptAdBoardInvite = async (
  adBoardId: string
): Promise<AdBoardInviteDto> => {
  try {
    const response = await axiosCall(
      false,
      "post",
      `${AD_BOARDS_ENDPOINT}/${adBoardId}/accept-invite`,
      undefined
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      // TODO: add error code for adboard
      response?.data?.message || t("apiErrorMessages.accept_adboard_invite")
    );
  } catch (error) {
    console.error("Error accepting adboard invite:", error);
    throw new Error(
      error?.response?.data?.message ||
        t("apiErrorMessages.accept_adboard_invite")
    );
  }
};

export const declineAdBoardInvite = async (
  adBoardId: string
): Promise<AdBoardInviteDto> => {
  try {
    const response = await axiosCall(
      false,
      "post",
      `${AD_BOARDS_ENDPOINT}/${adBoardId}/decline-invite`,
      undefined
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      // TODO: add error code for adboard
      response?.data?.message || t("apiErrorMessages.decline_adboard_invite")
    );
  } catch (error) {
    console.error("Error declining adboard invite:", error);
    throw new Error(
      error?.response?.data?.message ||
        t("apiErrorMessages.decline_adboard_invite")
    );
  }
};

export const getOneAdBoardInviteByAdBoardId = async (
  id: string
): Promise<AdBoardInviteDto> => {
  try {
    const response = await axiosCall(
      false,
      "get",
      AD_BOARDS_ENDPOINT + `/${id}/invite`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      // TODO: add error code for adboard
      response?.data?.message || t("apiErrorMessages.no_ad_board_found")
    );
  } catch (error) {
    console.error("Error fetching data getOneAdBoard:", error);
    throw new Error(
      error?.response?.data?.message || t("apiErrorMessages.no_ad_board_found")
    );
  }
};
