import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { AttachmentDto, AttachmentFormDto } from '@keyops-hcp/dtos';
import { UseMutateFunction } from '@keyops-hcp/ui-components';

import { AttachmentForm } from './CreateAttachmentForm';

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: 1000,
  width: '30vw',
  maxWidth: '450px',
  borderRadius: '8px',
} as const;

interface CreateAttachmentModalProps {
  open: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attachmentFile: undefined | File;
  handleModalClosing: () => void;
  createAttachmentSection: UseMutateFunction<
    AttachmentDto,
    unknown,
    {
      attachmentToSave: AttachmentFormDto;
    },
    unknown
  >;
}

export const CreateAttachmentModal: React.FC<CreateAttachmentModalProps> = ({
  open,
  setIsModalOpen,
  attachmentFile,
  handleModalClosing,
  createAttachmentSection,
}) => {
  const [attachmentFormData, setAttachmentFormData] = useState<
    AttachmentFormDto | undefined
  >(undefined);

  // Populate the form with the file selected during the previous step
  useEffect(() => {
    if (attachmentFile) {
      setAttachmentFormData({
        file: attachmentFile,
      } as AttachmentFormDto); // For now, no title or reading time set
    }
  }, [attachmentFile]);

  return (
    <Modal
      open={open}
      onClose={() => handleModalClosing()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2'>
          Document details
          <AttachmentForm
            attachment={attachmentFormData}
            setIsModalOpen={setIsModalOpen}
            createAttachmentSection={createAttachmentSection}
          />
        </Typography>
      </Box>
    </Modal>
  );
};
