import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';

import { Video } from '../Video';
import { VideoComments } from '../VideoComments';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';

export const VideoWrapper = ({ videoId }: { videoId: string }) => {
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <Stack spacing={2}>
      <AdBoardSectionTaskInfo>
        {i18next.t('video.infoText')}
      </AdBoardSectionTaskInfo>
      <Video videoId={videoId} ref={playerRef} />
      <VideoComments videoId={videoId} ref={playerRef} />
    </Stack>
  );
};
