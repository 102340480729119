import React from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { KeyOpsThemeProvider } from '../../wrappers/KeyOpsThemeProvider';
import { AdminAdBoardDataProvider } from '../../context';

interface AdminPreviewModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const AdminPreviewModal: React.FC<AdminPreviewModalProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    // Wrap ui components with KeyOpsThemeProvider to override the react admin default theme
    <KeyOpsThemeProvider>
      {/* To access the global adboard context */}
      <AdminAdBoardDataProvider>
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90vw',
              height: '90vh',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              overflow: 'scroll',
            }}
          >
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
            {children}
          </Box>
        </Modal>
      </AdminAdBoardDataProvider>
    </KeyOpsThemeProvider>
  );
};
