import React, { useState } from "react";
import { Container, Snackbar, SnackbarContent, Stack } from "@mui/material";
import { AttachmentDto } from "@keyops-hcp/dtos";

import { AttachmentsList } from "./AttachmentsList";
import { CreateAttachmentModal } from "../../../components/CreateAttachmentModal";
import { KeyOpsButton } from "../../../components/Button";

export const Attachments = () => {
  const [attachments, setAllAttachments] = useState<AttachmentDto[]>([]);

  // Modal handling
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Error and state handling
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [successMessage, setSuccessMessage] = useState<string>();
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Container>
      <div>Attachments</div>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <AttachmentsList
          attachments={attachments}
          setAllAttachments={setAllAttachments}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <KeyOpsButton onClick={() => setIsModalOpen(true)}>
          Create an attachment
        </KeyOpsButton>

        {isModalOpen && (
          <CreateAttachmentModal
            open={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setIsToastOpen={setIsToastOpen}
            setAllAttachments={setAllAttachments}
          />
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isToastOpen}
        autoHideDuration={5000}
        onClose={() => setIsToastOpen(false)}
      >
        <SnackbarContent
          message={errorMessage ?? successMessage ?? ""}
          color='white'
          sx={{
            backgroundColor: successMessage ? `green` : `red`,
          }}
        />
      </Snackbar>
    </Container>
  );
};
