import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import {
  AdBoardSectionsToggle,
  AdBoardTopBar,
  DiscussionsDrawer,
  getCurrentSection,
  SectionsDrawer,
} from '../../components';
import { SectionDto } from '@keyops-hcp/dtos';
import { useAdBoardContext } from '../../context';
import { HCPAdBoardContextType } from '../../hooks';

interface AdminPreviewContentProps {
  adBoardId: string;
}

export const AdminPreviewContent: React.FC<AdminPreviewContentProps> = ({
  adBoardId,
}) => {
  // To be able to navigate in admin preview
  const { sectionsData: sections } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};
  const [outsideDrawerOpen, setOutsideDrawerOpen] = useState(false);
  const toggleOutsideDrawer = (newOpen: boolean) => () => {
    setOutsideDrawerOpen(newOpen);
  };

  // Discussions board nav handling
  const [discussionDrawerOpen, setDiscussionDrawerOpen] = useState(false);
  const toggleDiscussionDrawer = () => {
    setDiscussionDrawerOpen((prevOpen) => !prevOpen);
  };

  // Discussion messages handling
  const [displayedDiscussionId, setDisplayedDiscussionId] = useState<
    string | undefined
  >(undefined);

  const [currentSectionId, setCurrentSectionId] = useState<
    string | undefined
  >();

  const [currentSection, setCurrentSection] = useState<SectionDto | undefined>(
    undefined
  );

  useEffect(() => {
    const section = getCurrentSection(currentSectionId, sections);
    setCurrentSection(section);
  }, [currentSectionId]);

  useEffect(() => {
    setCurrentSectionId(
      sections && sections.length > 0 ? sections[0].id : undefined
    );
  }, [sections]);

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <AdBoardTopBar
        toggleOutsideDrawer={toggleOutsideDrawer}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        isAdmin={true}
      />
      <Box
        data-testid='main-wrapper'
        flexGrow={1}
        display={'flex'}
        overflow={'hidden'}
        sx={{ backgroundColor: '#ffffff' }}
        marginTop={'30px'}
      >
        <SectionsDrawer
          setDiscussionDrawerOpen={setDiscussionDrawerOpen} // needed for discussion section
          setDisplayedDiscussionId={setDisplayedDiscussionId} // needed for discussion section
          setCurrentSectionId={setCurrentSectionId}
        />
        <Box component='main' flexGrow={1} overflow='auto' px={10} py={4}>
          <AdBoardSectionsToggle
            currentSection={currentSection}
            setCurrentSectionId={setCurrentSectionId}
          />
        </Box>
        {/* To display all adboard discussions */}
        <DiscussionsDrawer
          displayedDiscussionId={displayedDiscussionId}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
          discussionDrawerOpen={discussionDrawerOpen}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          optionalAdBoardId={adBoardId}
          optionalCurrentSectionId={currentSection?.id}
        />
      </Box>
    </Box>
  );
};
