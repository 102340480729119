import React from 'react';
import { FiEdit } from 'react-icons/fi';

// TODO: add the color in a theme
export const KeyOpsEditIcon = ({
  size = `20px`,
  onClick = undefined,
}: {
  size?: string;
  onClick?: () => void;
}) => {
  return <FiEdit color='#3376D1' size={size} onClick={onClick} />;
};
