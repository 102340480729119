// External Dependencies
import * as React from "react";
import {
  TextField,
  EditButton,
  Show,
  SimpleShowLayout,
  TopToolbar,
} from "react-admin";

const PostShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CompanyShow = (props) => (
  <Show actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default CompanyShow;
