import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import {
  AdBoardAttachmentSectionProgressDto,
  AdBoardDto,
  AdBoardParticipantsDto,
  AdBoardProgressDto,
  AdBoardSectionProgressDto,
  AdBoardSurveySectionProgressDto,
  AdBoardVideoSectionProgressDto,
  SectionDto,
  AdBoardSectionGroupDto,
} from '@keyops-hcp/dtos';

import { updateSectionProgress } from '../api/sections';
import { getOneAdBoard, getParticipantDetails } from '../api/adBoards';

export interface HCPAdBoardContextType {
  isAdmin: boolean;
  isLoading: boolean;
  isError: boolean;
  adBoardData?: AdBoardDto;
  sectionsData?: SectionDto[];
  sectionGroupsData?: AdBoardSectionGroupDto[];
  userId?: number;
  adBoardProgress?: AdBoardProgressDto;
  currentSectionDetails?: SectionDto;
  currentSectionProgress?: AdBoardSectionProgressDto;
  finishedSections?: AdBoardSectionProgressDto[];
  numberOfSections?: number;
  updateProgress?: (
    data:
      | AdBoardVideoSectionProgressDto
      | AdBoardAttachmentSectionProgressDto
      | AdBoardSurveySectionProgressDto
  ) => void;
  refetchAllData?: () => void;
}

export const useProgressTracker = () => {
  const isAdmin = false;
  const { adBoardId, sectionId } = useParams();
  const queryClient = useQueryClient();

  // Use useQueries to fetch adBoard, sections, and participant data simultaneously
  const [adBoardQuery, participantQuery] = useQueries({
    queries: [
      {
        queryKey: ['adBoard', adBoardId],
        queryFn: () => getOneAdBoard(adBoardId),
      },
      {
        queryKey: ['participants', adBoardId],
        queryFn: () => getParticipantDetails(adBoardId),
        onSuccess: (data: AdBoardParticipantsDto) => {
          const progress = data?.progress?.progressDetails?.sectionProgress;
          if (!progress || progress.length <= 0) {
            updateProgress(undefined);
          }
        },
      },
    ],
  });

  const isLoading = adBoardQuery.isLoading || participantQuery.isLoading;
  const isError = adBoardQuery.isError || participantQuery.isError;

  const adBoardData = adBoardQuery?.data;
  const sectionsData = adBoardData?.sections;
  const sectionGroupsData = adBoardData?.sectionGroups;
  const participantData = participantQuery?.data;
  const participantId = participantData?.id;

  // Memoize progress-related calculations
  const progress = useMemo(
    () => participantData?.progress?.progressDetails?.sectionProgress,
    [participantData]
  );

  const finishedSections = useMemo(
    () =>
      progress?.filter(
        (progressEntry: AdBoardSectionProgressDto) => progressEntry.finished
      ) || [],
    [progress]
  );

  // TODO: Use latestSection to determine currentSectionDetails & currentSectionProgress (participantData?.progress?.latestSection)
  const currentSectionDetails = useMemo(
    () => sectionsData?.find((section: SectionDto) => section.id === sectionId),
    [sectionsData, sectionId]
  );

  const currentSectionProgress = useMemo(
    () =>
      progress?.find(
        (progressEntry: AdBoardSectionProgressDto) =>
          progressEntry.sectionId === sectionId
      ),
    [sectionId, progress, participantData?.progress?.latestSection]
  );

  // Memoize the refetchAllData function
  const refetchAllData = useCallback(() => {
    queryClient.invalidateQueries(['adBoard', adBoardId]);
    queryClient.invalidateQueries(['participants', adBoardId]);
  }, [queryClient, adBoardId]);

  const { mutate: updateProgress } = useMutation(
    async (
      data:
        | AdBoardVideoSectionProgressDto
        | AdBoardAttachmentSectionProgressDto
        | AdBoardSurveySectionProgressDto
    ) => {
      if (!participantId || !sectionId) return;
      return await updateSectionProgress(sectionId, participantId, data);
    },
    {
      // On success, invalidate the relevant queries to refetch data
      onSuccess: () => refetchAllData(),
      // Handle errors
      onError: (error: any) => {
        console.error('Error updating progress:', error);
      },
    }
  );

  const numberOfSections = sectionsData?.length;

  return useMemo(
    () => ({
      isAdmin,
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      adBoardProgress: participantData?.progress,
      userId: participantData?.userId,
      currentSectionDetails,
      currentSectionProgress,
      finishedSections,
      numberOfSections,
      updateProgress,
      refetchAllData,
    }),
    [
      isAdmin,
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      participantData,
      currentSectionDetails,
      currentSectionProgress,
      finishedSections,
      numberOfSections,
      updateProgress,
      refetchAllData,
    ]
  );
};
