// External Dependencies
import StoreIcon from "@mui/icons-material/Store";

// Internal Dependencies
import CompanyList from "./components/List";
import CompanyShow from "./components/Show";
import CompanyCreate from "./components/Create";
import CompanyEdit from "./components/Edit";

const resource = {
  list: CompanyList,
  show: CompanyShow,
  create: CompanyCreate,
  edit: CompanyEdit,
  icon: StoreIcon,
};

export default resource;
