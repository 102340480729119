import React from 'react';
import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNotify, useRecordContext } from 'react-admin';

export const CopyPropertyButton = ({
  property,
  iconSize,
  ...props
}: {
  property: string;
  iconSize?: number;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [x: string]: any;
}) => {
  const record = useRecordContext();
  const notify = useNotify();

  const copyProperty = (event: React.MouseEvent) => {
    event.stopPropagation();
    const value = property
      .split('.')
      .reduce((p, c) => (p && p[c]) || null, record);
    if (!value) {
      notify(`No property ${property} found`);
    }
    navigator.clipboard.writeText(value.toString());
    notify(`Copied ${property} "${value.toString()}" to clipboard`);
  };

  return (
    <IconButton title={`Copy ${property}`} onClick={copyProperty} {...props}>
      <ContentCopy sx={{ fontSize: iconSize }} />
    </IconButton>
  );
};
